import BigNumber from "bignumber.js";
import {
  TAskOrder,
  TAskOrderResponseItem,
  TBidOrder,
  TBidOrderResponseItem,
} from "../../types";

import { payableTokensConfig } from "../../../../config/config";
import { AvailableChains } from "../../../../shared/utils";

export const mapBidOrdersListData = (
  list: TBidOrderResponseItem[],
  chainId: number
): TBidOrder[] =>
  list.map((item) => ({
    ...item,
    fullPrice: new BigNumber(item.fullPrice.toString())
      .dividedBy(10 ** payableTokensConfig[chainId][item.payToken].decimals)
      .toFixed(),
    id: item.id.toString(),
    pricePerToken: new BigNumber(item.pricePerToken.toString())
      .dividedBy(10 ** payableTokensConfig[chainId][item.payToken].decimals)
      .toFixed(),
    quantity: new BigNumber(item.quantity.toString()).dividedBy(1e18).toFixed(),
  }));

export const mapAskOrdersListData = (
  list: TAskOrderResponseItem[],
  chainId: number
): TAskOrder[] =>
  list.map((item) => ({
    ...item,
    fullPrice: new BigNumber(item.fullPrice.toString())
      .dividedBy(10 ** payableTokensConfig[chainId][item.payToken].decimals)
      .toFixed(),
    id: item.id.toString(),
    pricePerToken: new BigNumber(item.pricePerToken.toString())
      .dividedBy(10 ** payableTokensConfig[chainId][item.payToken].decimals)
      .toFixed(),
    quantity: new BigNumber(item.quantity.toString()).dividedBy(1e18).toFixed(),
  }));

export const getDividedByChainId = (chainId: number | undefined) => {
  if (
    chainId === AvailableChains.BinanceTestNet ||
    chainId === AvailableChains.BinanceMainnet
  ) {
    return 1e18;
  }
  return 1e6;
};
