import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useCountdown } from "../../../../shared/hooks/useCountdown";
import {
  basicTone0,
  basicTone100,
  mainLinearGradient,
} from "../../../../shared/styles/constants";
import TimerBackground from "../../../../assets/TimerBackground.png";
import { getMinutesDiff } from "./utils";

type TimerProps = {
  startDate: string;
  endDate: string;
};

export const Timer = ({ startDate, endDate }: TimerProps) => {
  const [days, hours, minutes] = useCountdown(endDate);
  const totalMinutesDiff = getMinutesDiff(startDate, endDate);
  const [progressPercentage, setProgressPercentage] = useState<number>(0);

  useEffect(() => {
    const passedMinutes = getMinutesDiff(startDate, new Date().toString());
    let progress = (passedMinutes / totalMinutesDiff) * 100;
    if (progress > 100) progress = 100;
    setProgressPercentage(progress);
  }, [startDate, endDate, totalMinutesDiff]);

  const timeLeft: JSX.Element = (
    <>
      <span style={{ color: basicTone0 }}>{days}</span>
      <span style={{ color: basicTone100 }}>d </span>
      <span style={{ color: basicTone0 }}>{hours}</span>
      <span style={{ color: basicTone100 }}>hr </span>
      <span style={{ color: basicTone0 }}>{minutes}</span>
      <span style={{ color: basicTone100 }}>min </span>
      <span style={{ color: basicTone0 }}>Left</span>
    </>
  );

  const timeUp: JSX.Element = (
    <>
      <span style={{ color: basicTone0 }}>0</span>
      <span style={{ color: basicTone100 }}>d </span>
      <span style={{ color: basicTone0 }}>0</span>
      <span style={{ color: basicTone100 }}>hr </span>
      <span style={{ color: basicTone0 }}>0</span>
      <span style={{ color: basicTone100 }}>min </span>
      <span style={{ color: basicTone0 }}>Left</span>
    </>
  );

  return (
    <div
      style={{
        position: "absolute",
        top: "17px",
        color: basicTone0,
        left: "24px",
        right: "24px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ position: "relative" }}>
        <img src={TimerBackground} alt="timer-background" />
        <div
          style={{
            height: "4px",
            position: "absolute",
            top: "5px",
            left: "4px",
            right: "4px",
          }}
        >
          <div
            style={{
              height: "100%",
              width: `${progressPercentage}%`,
              background: mainLinearGradient,
              borderRadius: 20,
              display: "flex",
              alignItems: "center",
            }}
          ></div>
        </div>
        <Typography
          variant={"body2"}
          fontWeight={700}
          sx={{ position: "absolute", top: "16px", left: "calc(50% - 55px) " }}
        >
          {progressPercentage >= 100 ? timeUp : timeLeft}
        </Typography>
      </div>
    </div>
  );
};

export default Timer;
