import {
  Button,
  Card,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { useState, useEffect, useContext } from "react";
import { useCall } from "@usedapp/core";

import { useContracts } from "../../../../hooks";
import { AccountContext } from "../../../../shared/providers/account-provider";
import { NotificationsContext } from "../../../../shared/providers/notifications-provider";
import { IChain } from "../../../../models/project.model";
import { WinnerCard } from "./WinnerCard";
import { useNavigate } from "react-router-dom";

type FundingCardProps = {
  projectChain: IChain;
  maxAllocation?: string;
  minAllocation?: string;
  stableCoinAddress?: string;
  fundingContractAddress?: string;
  coinSymbol?: string;
  dollarTargetAmount?: string;
};

export const FundingCard = ({
  maxAllocation,
  stableCoinAddress,
  fundingContractAddress,
  coinSymbol,
  minAllocation,
  projectChain,
  dollarTargetAmount,
}: FundingCardProps) => {
  const navigate = useNavigate();
  const { fundingContract } = useContracts({
    fundingContractAddress,
  });
  const [loadingIsUserWinner, setLoadingIsUserWinner] = useState(true);
  const [isWinner, setIsWinner] = useState<boolean>();
  const { setNotification } = useContext(NotificationsContext);

  const { account } = useContext(AccountContext);

  // const checkIfUserIsWinner = () => {
  //   const { value: isUserWinner, error: isUserWinnerError } =
  //     useCall({
  //       contract: fundingContract,
  //       method: "winnerAddresses",
  //       args: [account],
  //     }) ?? {};

  //   if (isUserWinnerError) {
  //     console.log("isUserWinnerError", isUserWinnerError);
  //   }
  //   if (isUserWinner !== undefined) {
  //     setIsWinner(isUserWinner[0]);
  //     setLoadingIsUserWinner(false);
  //     // **** Use this to mock your user for being a winner ***
  //     // setIsWinner(!isUserWinner[0]); // TO DO  remove !
  //   }
  // };
  // useEffect(() => {
  //   checkIfUserIsWinner();
  // }, []);

  const { value: isUserWinner, error: isUserWinnerError } =
    useCall({
      contract: fundingContract,
      method: "winnerAddresses",
      args: [account],
    }) ?? {};

  // **** Use this to mock your user for being a winner ***
  useEffect(() => {
    if (isUserWinner !== undefined) {
      setIsWinner(isUserWinner[0]);
      setLoadingIsUserWinner(false);
      // **** Use this to mock your user for being a winner ***
      // setIsWinner(!isUserWinner[0]); // TO DO  remove !
    }
  }, [isUserWinner]);

  useEffect(() => {
    if (isUserWinnerError !== undefined && loadingIsUserWinner) {
      setIsWinner(false);
      setLoadingIsUserWinner(false);
      setNotification({
        message: "An error occured. Please refresh the page!",
        type: "error",
      });
      // **** Use this to mock your user for being a winner ***
      // setIsWinner(!isUserWinner[0]); // TO DO  remove !
    }
  }, [isUserWinnerError]);

  const renderCardContent = (
    <Typography variant="body1" fontSize={"14px"} fontWeight={500}>
      To get involved in project staking please make sure you have completed the
      following requirements.
    </Typography>
  );

  return (
    <Grid
      container
      justifyContent={"flex-start"}
      gap={2}
      paddingTop={"20px"}
      paddingBottom={"5px"}
    >
      <Typography
        variant="h5"
        color="secondary"
        fontWeight={800}
        fontSize="16px"
      >
        Funding started
      </Typography>

      {isWinner ? (
        <WinnerCard
          maxAllocation={maxAllocation}
          stableCoinAddress={stableCoinAddress}
          fundingContractAddress={fundingContractAddress}
          coinSymbol={coinSymbol}
          minAllocation={minAllocation}
          chainId={projectChain.chainId}
          dollarTargetAmount={dollarTargetAmount}
        />
      ) : (
        <>
          {renderCardContent}
          <Grid container justifyContent={"center"}>
            {loadingIsUserWinner ? (
              <CircularProgress color="secondary" />
            ) : (
              <Button
                fullWidth
                color="primary"
                variant="contained"
                disabled={true}
                sx={{ height: "48px", borderRadius: "8px" }}
              >
                You were not selected for this round
              </Button>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default FundingCard;
