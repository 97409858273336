import { useEffect, useContext, useState } from "react";
import {
  Grid,
  Stack,
  Typography,
  ListItem,
  IconButton,
  Skeleton,
  Box,
} from "@mui/material";

import { useContracts } from "../../../../hooks";
import { useCall, useContractFunction, useEthers } from "@usedapp/core";
import DeleteIcon from "@mui/icons-material/Delete";

import { AccountContext } from "../../../../shared/providers/account-provider";
import { TOrder } from "../../types";
import { ThemeModeContext } from "../../../../shared/providers/theme-mode-provider";
import {
  basicTone50,
  basicTone500,
  linearBlackBG,
  secondaryBorderRadius,
} from "../../../../shared/styles/constants";
import { mapOrdersListResponse } from "./ordersModals/ordersModals.utils";
import { NotificationsContext } from "../../../../shared/providers/notifications-provider";
import CancelOrderConfirmationDialog from "./ordersModals/CancelOrderConfirmationDialog";
import { MarketplaceContext } from "../../../../shared/providers/marketplace-provider";

import { payableTokensConfig } from "../../../../config/config";

type PayableToken = {
  symbol: string;
  address: string;
};

export const MyOrdersList = () => {
  const {
    tokenAddress,
    selectedToken,
    isTokenListLoading,
    tokensList,
    getMarketplaceAddressByChain,
  } = useContext(MarketplaceContext);
  const { account } = useContext(AccountContext);
  const { setNotification } = useContext(NotificationsContext);
  const { isLightMode } = useContext(ThemeModeContext);
  const [activeOrders, setActiveOrders] = useState<TOrder[]>([]);
  const [payableTokens, setPayableTokens] = useState<PayableToken[]>([]);

  const [openCancelConfirmationDialog, setOpenCancelDialog] = useState(false);
  const [toCancelSelectedOrder, setToCancelSelectedOrder] = useState<
    TOrder | undefined
  >(undefined);
  const [isActiveOrdersLoading, setIsActiveOrdersLoading] = useState(true);
  const [loadingCancelOrder, setLoadingCancelOrder] = useState(false);
  const { chainId, library } = useEthers();

  const { marketplaceContract } = useContracts({
    marketplaceContractAddress: getMarketplaceAddressByChain(
      selectedToken?.chainId
    ),
  });

  const { value: activeOrdersResult, error: activeOrdersError } =
    useCall(
      selectedToken && {
        contract: marketplaceContract,
        method: "getActiveOrders",
        args: [account, tokenAddress],
      },
      { chainId: selectedToken?.chainId }
    ) ?? {};

  const { value: payableTokensResult, error: payableTokensError } =
    useCall(
      selectedToken && {
        contract: marketplaceContract,
        method: "getPayableTokens",
        args: [],
      },
      { chainId: chainId }
    ) ?? {};

  useEffect(() => {
    if (activeOrdersResult && selectedToken?.chainId) {
      setActiveOrders(
        mapOrdersListResponse(activeOrdersResult[0], selectedToken.chainId)
      );

      setIsActiveOrdersLoading(false);
    } else if (!selectedToken && tokensList.length === 0) {
      setIsActiveOrdersLoading(false);
    }
  }, [activeOrdersResult, activeOrdersError, selectedToken]);

  useEffect(() => {
    if (payableTokensResult && chainId) {
      // Flatten the array of arrays
      const flattenedTokenAddresses = payableTokensResult.flat();

      const payableTokensList = flattenedTokenAddresses.map(
        (tokenAddress: string) => {
          return {
            symbol: payableTokensConfig[chainId][tokenAddress].symbol,
            address: tokenAddress,
          };
        }
      );

      setPayableTokens(payableTokensList);
    }
  }, [payableTokensResult, payableTokensError, library]);

  const { state: cancelAskOrderState, send: cancelAskOrder } =
    useContractFunction(marketplaceContract, "cancelAskOrder", {
      transactionName: "Cancel Ask Order",
    });
  const { state: cancelBidOrderState, send: cancelBidOrder } =
    useContractFunction(marketplaceContract, "cancelBidOrder", {
      transactionName: "Cancel Bid Order",
    });

  useEffect(() => {
    if (cancelAskOrderState?.status === "Success") {
      setNotification({
        message: "You successfully canceled an ask order!",
        type: "success",
      });
      setOpenCancelDialog(false);
      setToCancelSelectedOrder(undefined);
      setLoadingCancelOrder(false);
    }
  }, [cancelAskOrderState]);

  useEffect(() => {
    if (cancelBidOrderState?.status === "Success") {
      setNotification({
        message: "You successfully canceled a bid order!",
        type: "success",
      });
      setOpenCancelDialog(false);
      setToCancelSelectedOrder(undefined);
      setLoadingCancelOrder(false);
    }
  }, [cancelBidOrderState]);

  const handleCancelOrder = (
    orderType: string,
    id: string,
    fundingTermsAddress: string
  ) => {
    setLoadingCancelOrder(true);
    if (orderType === "BID") {
      cancelBidOrder(fundingTermsAddress, id);
    } else {
      cancelAskOrder(fundingTermsAddress, id);
    }
  };

  return (
    <Stack gap={2}>
      <Stack gap={0.5}>
        <ListItem
          sx={{
            padding: "12px",
            borderBottom: `1px solid ${
              isLightMode ? basicTone50 : basicTone500
            }`,
          }}
        >
          <Grid container alignItems={"center"}>
            <Grid item xs={2} gap={1} container alignItems={"center"}>
              <Typography
                variant="h6"
                color="secondary"
                fontSize="10px"
                sx={{ textTransform: "uppercase" }}
              >{`Type`}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography
                variant="h6"
                color="secondary"
                fontSize="10px"
                sx={{ textTransform: "uppercase" }}
              >{`Quantity`}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant="h6"
                color="secondary"
                fontSize="10px"
                sx={{ textTransform: "uppercase" }}
              >{`Price per token`}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography
                variant="h6"
                color="secondary"
                fontSize="10px"
                sx={{ textTransform: "uppercase" }}
              >{`Full price`}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography
                variant="h6"
                color="secondary"
                fontSize="10px"
                sx={{ textTransform: "uppercase" }}
              >{`Pay Token`}</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography
                variant="h6"
                color="secondary"
                fontSize="10px"
                textAlign={"center"}
                sx={{ textTransform: "uppercase" }}
              >{`CANCEL`}</Typography>
            </Grid>
          </Grid>
        </ListItem>
        {isTokenListLoading || isActiveOrdersLoading ? (
          <Stack gap={2} sx={{ p: 2 }}>
            <Box>
              <Skeleton
                variant="rounded"
                width={"100%"}
                height={20}
                sx={{
                  background: !isLightMode ? basicTone50 : linearBlackBG,
                  borderRadius: secondaryBorderRadius,
                }}
              />
            </Box>
            <Box>
              <Skeleton
                variant="rounded"
                width={"100%"}
                height={20}
                sx={{
                  background: !isLightMode ? basicTone50 : linearBlackBG,
                  borderRadius: secondaryBorderRadius,
                }}
              />
            </Box>
            <Box>
              <Skeleton
                variant="rounded"
                width={"100%"}
                height={20}
                sx={{
                  background: !isLightMode ? basicTone50 : linearBlackBG,
                  borderRadius: secondaryBorderRadius,
                }}
              />
            </Box>
          </Stack>
        ) : (
          <>
            {activeOrders.map((item, index) => (
              <ListItem
                key={`${item.id.toString()}${index}`}
                sx={{
                  padding: "0 12px",
                  "&:hover": {
                    background: isLightMode ? basicTone50 : basicTone500,
                  },
                }}
              >
                <Grid container alignItems={"center"}>
                  <Grid item xs={2} gap={1} container alignItems={"center"}>
                    <Typography
                      variant="body2"
                      fontWeight={700}
                      color="secondary"
                    >
                      {`${item.orderType}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} gap={1} container alignItems={"center"}>
                    <Typography
                      variant="body2"
                      fontWeight={400}
                      color="secondary"
                    >
                      {`${item.quantity}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} justifyContent={"center"}>
                    <Typography
                      variant="body2"
                      fontWeight={400}
                      color={"secondary"}
                    >
                      {`$${item.pricePerToken}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography
                      variant="body2"
                      fontWeight={400}
                      color={"secondary"}
                    >
                      {`$${item.fullPrice}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography
                      variant="body2"
                      fontWeight={400}
                      color={"secondary"}
                    >
                      {` ${
                        payableTokens.find(
                          (token) => token.address === item.payToken
                        )?.symbol
                      }`}
                    </Typography>
                  </Grid>
                  <Grid item xs={1} justifyContent="center" display={"flex"}>
                    {selectedToken?.chainId === chainId &&
                      account &&
                      !item.sold && (
                        <IconButton
                          color="info"
                          onClick={() => {
                            setToCancelSelectedOrder(item);
                            setOpenCancelDialog(true);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                  </Grid>
                </Grid>
              </ListItem>
            ))}
          </>
        )}
      </Stack>
      {toCancelSelectedOrder && (
        <CancelOrderConfirmationDialog
          open={openCancelConfirmationDialog}
          handleClose={() => {
            setOpenCancelDialog(false);
            setToCancelSelectedOrder(undefined);
          }}
          orderType={toCancelSelectedOrder.orderType}
          loadingCancel={loadingCancelOrder}
          handleCancelOrder={() =>
            handleCancelOrder(
              toCancelSelectedOrder.orderType,
              toCancelSelectedOrder.id,
              toCancelSelectedOrder.fundingTermsAddress
            )
          }
        />
      )}
    </Stack>
  );
};

export default MyOrdersList;
