import {
  Typography,
  Card,
  Link,
  Grid,
  CardMedia,
  CardContent,
  IconButton,
  Box,
  CardActions,
} from "@mui/material";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";

import React, { useContext } from "react";
import { ThemeModeContext } from "../../shared/providers/theme-mode-provider";
import {
  dropShadowBG,
  basicTone0,
  basicTone450,
  basicTone600,
  basicTone700,
  grayWhtTone,
  whiteTone,
  mainBorderRadius,
  basicTone100,
  basicTone50,
  dropShadowSM,
  mainLinearGradient,
  basicTone200,
} from "../../shared/styles/constants";
import { TBlogPost } from "./types";
import { formatShortDate } from "../../shared/utils";

export const BlogPostCard = ({ post }: { post: TBlogPost }) => {
  const { isLightMode } = useContext(ThemeModeContext);

  const MAX_DESCRIPTION_LENGTH = 100;

  const truncateDescription = (description: string) => {
    if (description.length > MAX_DESCRIPTION_LENGTH) {
      return description.substring(0, MAX_DESCRIPTION_LENGTH) + "...";
    }
    return description;
  };

  return (
    <Link
      component={"a"}
      href={post.blogLink}
      underline="none"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Card
        sx={{
          padding: "0",
          height: "420px",
          cursor: "pointer",
          minWidth: "260px",
          maxWidth: "355px",
          position: "relative",
          backgroundColor: isLightMode ? basicTone0 : basicTone700,
          border: "1px solid transparent",
          "&:hover": {
            backgroundColor: isLightMode ? whiteTone : basicTone600,
            border: `1px solid ${isLightMode ? grayWhtTone : basicTone450}`,
            boxShadow: isLightMode ? "none" : dropShadowBG,
          },
        }}
      >
        <CardMedia
          component={"img"}
          image={post.photoUrl}
          alt={`blog-post-${post.id}`}
          sx={{
            borderTopLeftRadius: mainBorderRadius,
            borderTopRightRadius: mainBorderRadius,
            height: "192px",
            filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.08))",
          }}
        />
        <span
          style={{
            position: "absolute",
            top: "16px",
            right: "16px",
            display: "flex",
            gap: "8px",
          }}
        >
          {post.isFeatured && (
            <Box
              sx={{
                background: mainLinearGradient,
                padding: "4px 16px",
                borderRadius: mainBorderRadius,
                boxShadow: dropShadowSM,
              }}
            >
              <Typography variant="body2" color={basicTone0} fontWeight={700}>
                Featured
              </Typography>
            </Box>
          )}
          <Box
            sx={{
              backgroundColor: basicTone50,
              padding: "4px 16px",
              borderRadius: mainBorderRadius,
              boxShadow: dropShadowSM,
            }}
          >
            <Typography variant="body2" color={basicTone700} fontWeight={700}>
              {formatShortDate(post.createdAt)}
            </Typography>
          </Box>
        </span>
        <CardContent
          sx={{
            height: "calc(100% - 256px)",
            padding: "30px 20px",
            paddingBottom: "0px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            "&:last-child": {
              paddingBottom: 0,
            },
          }}
        >
          <Grid
            container
            gap={1}
            alignItems="space-between"
            height={"100%"}
            sx={{ padding: "16px 8px 0px" }}
          >
            <Grid gap={1}>
              <Typography
                variant={"h5"}
                fontSize="20px"
                color="secondary"
                fontWeight={700}
              >
                {post.title}
              </Typography>
              <Typography variant={"h6"} color={basicTone200} fontWeight={400}>
                {truncateDescription(post.description)}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Grid container justifyContent={"flex-end"} alignItems="center">
            <IconButton>
              <ArrowCircleRightOutlinedIcon color="info" fontSize="large" />
            </IconButton>
          </Grid>
        </CardActions>
      </Card>
    </Link>
  );
};

export default BlogPostCard;
