import {
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Stack,
  Box,
  CircularProgress,
} from "@mui/material";
import { useCall, useContractFunction, useEthers } from "@usedapp/core";
import React, { useContext, useEffect, useState } from "react";

import {
  basicTone0,
  basicTone50,
  basicTone500,
  basicTone700,
  linearBlackBG,
  mainColor2,
  secondaryBorderRadius,
} from "../../../../../shared/styles/constants";

import { useContracts } from "../../../../../hooks";
import { NotificationsContext } from "../../../../../shared/providers/notifications-provider";
import { LoadingButton } from "@mui/lab";
import { ThemeModeContext } from "../../../../../shared/providers/theme-mode-provider";
import { MarketplaceContext } from "../../../../../shared/providers/marketplace-provider";
import BigNumber from "bignumber.js";

import { AccountContext } from "../../../../../shared/providers/account-provider";
import { getDividedByChainId } from "../utils";

type SellTokensModalProps = {
  open: boolean;
  onClose: () => void;
  bidId: string | undefined;
  payToken: string;
  fullPrice: string | undefined;
};

export const SellTokensModal = ({
  open,
  onClose,
  bidId,
  fullPrice,
  payToken,
}: SellTokensModalProps) => {
  const { isLightMode } = useContext(ThemeModeContext);
  const { setNotification } = useContext(NotificationsContext);
  const { account } = useContext(AccountContext);

  const { fundingTermsAddress, getMarketplaceAddressByChain } =
    useContext(MarketplaceContext);
  const [payableTokenSymbol, setPayableTokenSymbol] = useState<string>("");
  const [payableTokenDecimals, setPayableTokenDecimals] = useState<
    number | null
  >(null);
  const [balance, setBallance] = useState<string>("");
  const { chainId } = useEthers();

  const { marketplaceContract, payableTokenContract } = useContracts({
    marketplaceContractAddress: getMarketplaceAddressByChain(chainId),
    payableTokenAddress: payToken,
  });

  const { state: sellState, send: sell } = useContractFunction(
    marketplaceContract,
    "sell",
    {
      transactionName: "Sell ",
    }
  );

  const { value: payableTokenSymbolResult, error: payableTokenSymbolError } =
    useCall({
      contract: payableTokenContract,
      method: "symbol",
      args: [],
    }) ?? {};

  const {
    value: payableTokenDecimalsResult,
    error: payableTokenDecimalsError,
  } =
    useCall({
      contract: payableTokenContract,
      method: "decimals",
      args: [],
    }) ?? {};

  const { value: balanceOf, error: balanceOfError } =
    useCall({
      contract: payableTokenContract,
      method: "balanceOf",
      args: [account],
    }) ?? {};

  useEffect(() => {
    payableTokenSymbolResult && setPayableTokenSymbol(payableTokenSymbolResult);
  }, [payableTokenSymbolResult]);

  useEffect(() => {
    payableTokenDecimalsResult &&
      setPayableTokenDecimals(payableTokenDecimalsResult);
  }, [payableTokenDecimalsResult]);

  useEffect(() => {
    if (sellState?.status === "Success") {
      setNotification({
        message: "You successfully sold tokens!",
        type: "success",
      });
      onClose();
    }
  }, [sellState]);

  useEffect(() => {
    if (balanceOf && payableTokenDecimals) {
      const val = new BigNumber(balanceOf.toString())
        .dividedBy(10 ** payableTokenDecimals)
        .decimalPlaces(5)
        .toString();

      setBallance(val);
    }
  }, [balanceOf]);

  const handleSellTokens = () => {
    sell(fundingTermsAddress, bidId, payToken, fullPrice);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        backgroundColor: "initial",
        "& .MuiDialog-paper": {
          backgroundColor: isLightMode ? basicTone0 : basicTone700,
          padding: "16px 0",
          borderRadius: secondaryBorderRadius,
          minWidth: "300px",
        },
      }}
      aria-labelledby="modal-sell-tokens"
      aria-describedby="modal-sell-tokens"
    >
      <DialogTitle
        sx={{ fontSize: "28px", fontWeight: 700 }}
        color={"secondary"}
      >
        Sell tokens{" "}
        {(!payableTokenDecimals ||
          !payableTokenSymbol ||
          balance.length <= 0) && <CircularProgress color="secondary" />}
      </DialogTitle>

      <DialogContent>
        <Stack gap={2}>
          <Stack>
            <Typography variant="body2" color="secondary">
              BID ID
            </Typography>
            <Box
              sx={{
                backgroundColor: isLightMode ? basicTone50 : basicTone500,
                padding: 1.5,
                borderRadius: "4px",
              }}
            >
              <Typography variant="body2" color="secondary">
                {bidId}
              </Typography>
            </Box>
          </Stack>
          <Stack>
            <Typography variant="body2" color="secondary">
              PAY TOKEN
            </Typography>
            <Box
              sx={{
                backgroundColor: isLightMode ? basicTone50 : basicTone500,
                padding: 1.5,
                borderRadius: "4px",
              }}
            >
              <Typography variant="body2" color="secondary">
                {payableTokenSymbol}
              </Typography>
            </Box>
          </Stack>
          <Stack>
            <Typography variant="body2" color="secondary">
              FULL PRICE
            </Typography>
            <Box
              sx={{
                backgroundColor: isLightMode ? basicTone50 : basicTone500,
                padding: 1.5,
                borderRadius: "4px",
              }}
            >
              {fullPrice && payableTokenDecimals && (
                <Typography variant="body2" color="secondary">
                  {new BigNumber(fullPrice)
                    .dividedBy(10 ** payableTokenDecimals)
                    .toString()}
                </Typography>
              )}
            </Box>
          </Stack>
          <Stack>
            <Typography variant="body2" color="secondary">
              {payableTokenSymbol} BALANCE
            </Typography>
            <Box
              sx={{
                backgroundColor: isLightMode ? basicTone50 : basicTone500,
                padding: 1.5,
                borderRadius: "4px",
              }}
            >
              <Typography variant="body2" color="secondary">
                {balance}
              </Typography>
            </Box>
          </Stack>
          {payableTokenDecimals &&
          balance.length > 0 &&
          fullPrice &&
          new BigNumber(balance).isLessThan(
            new BigNumber(
              new BigNumber(fullPrice)
                .dividedBy(10 ** payableTokenDecimals)
                .toString()
            )
          ) ? (
            <Stack>
              <Typography variant="body2" color={mainColor2}>
                You do not have enough coins to fullfill this order.
              </Typography>
            </Stack>
          ) : null}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ padding: "24px" }}>
        <Button onClick={onClose} variant="text">
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          fullWidth
          onClick={() => handleSellTokens()}
          sx={{
            borderRadius: "3px",
            "& .MuiLoadingButton-loadingIndicator": {
              color: !isLightMode ? basicTone50 : linearBlackBG,
            },
          }}
          loading={
            sellState.status === "PendingSignature" ||
            sellState.status === "Mining"
          }
          disabled={
            payableTokenDecimals &&
            balance.length > 0 &&
            fullPrice &&
            new BigNumber(balance).isGreaterThanOrEqualTo(
              new BigNumber(
                new BigNumber(fullPrice)
                  .dividedBy(10 ** payableTokenDecimals)
                  .toString()
              )
            )
              ? false
              : true
          }
        >
          <span>Sell</span>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default SellTokensModal;
