import { Button, Card, Grid, Typography } from "@mui/material";
import { useState, useContext } from "react";
import { Arbitrum, BSC, BSCTestnet, Polygon, useEthers } from "@usedapp/core";
import { AccountContext } from "../../../shared/providers/account-provider";
import { IChain } from "../../../models/project.model";

type DifferentChainCardProps = {
  projectChain: IChain;
  title: string;
};

export const DifferentChainCard = ({
  projectChain,
  title,
}: DifferentChainCardProps) => {
  const { switchNetwork } = useEthers();
  const [isSwitchNetworkDisabled, setIsSwitchNetworkDisabled] = useState(false);

  const { setUserMetamaskChainId, isConnectedToMetamask } =
    useContext(AccountContext);

  const handleSwitchNetwork = async () => {
    setIsSwitchNetworkDisabled(true);
    let toUpdateChainId = projectChain.chainId;
    if (projectChain.chainId && isConnectedToMetamask) {
      switch (projectChain.chainId) {
        case BSCTestnet.chainId: {
          toUpdateChainId = BSCTestnet.chainId;
          break;
        }
        case Polygon.chainId: {
          toUpdateChainId = Polygon.chainId;
          break;
        }
        case BSC.chainId: {
          toUpdateChainId = BSC.chainId;
          break;
        }
        case Arbitrum.chainId: {
          toUpdateChainId = Arbitrum.chainId;
          break;
        }
        default: {
          break;
        }
      }
      if (toUpdateChainId) {
        await switchNetwork(toUpdateChainId)
          .then(() => {
            setUserMetamaskChainId(toUpdateChainId);
          })
          .catch((err) => console.log(err));
        setIsSwitchNetworkDisabled(false);
      }
    }
  };

  const renderCardContent = (
    <Typography variant="body1" fontSize={"14px"} fontWeight={500}>
      To get involved in project staking please make sure you have completed the
      following requirements.
    </Typography>
  );

  return (
    <Grid
      container
      justifyContent={"flex-start"}
      gap={2}
      paddingTop={"36px"}
      paddingBottom={"20px"}
    >
      <Typography
        variant="h5"
        color="secondary"
        fontWeight={800}
        fontSize="16px"
      >
        {title}
      </Typography>

      {renderCardContent}

      <Typography
        variant="body1"
        textAlign={"center"}
        fontSize={"14px"}
        color="secondary"
        fontWeight={500}
      >
        {`This project is on a different chain. Please switch the chain if you want to see if you're a winner for this round.`}
      </Typography>
      <Button
        fullWidth
        color="primary"
        variant="contained"
        onClick={handleSwitchNetwork}
        sx={{ height: "48px" }}
        disabled={isSwitchNetworkDisabled}
      >
        Switch to {projectChain.label.toUpperCase()}
      </Button>
    </Grid>
  );
};

export default DifferentChainCard;
