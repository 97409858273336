import { Grid, Skeleton } from "@mui/material";

import LeftSidebar from "./sections/left-sidebar/LeftSidebar";
import MainContent from "./sections/main-content/Main-Content";
import RightSidebar from "./sections/right-sidebar/Right-Sidebar";
import { MarketplaceProvider } from "../../shared/providers/marketplace-provider";

export const MarketplacePage = () => {
  return (
    <MarketplaceProvider>
      <Grid
        container
        sx={{
          display: "flex",
          gap: { xs: 4, md: "4px" },
          flex: "1 0 0",
          alignSelf: "stretch",
          height: { md: "100vh" },
          paddingTop: "4px",
          paddingBottom: { xs: 4, md: 0 },
          flexWrap: { xs: "wrap", md: "nowrap" },
        }}
      >
        <LeftSidebar />
        <MainContent />
        <RightSidebar />
      </Grid>
    </MarketplaceProvider>
  );
};

export default MarketplacePage;
