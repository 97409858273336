export const DiscordIconSVG = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.0583 4.44236C14.95 3.92569 13.75 3.55069 12.5 3.33402C12.489 3.33367 12.4781 3.33573 12.4681 3.34005C12.458 3.34437 12.449 3.35084 12.4417 3.35902C12.2917 3.63402 12.1167 3.99236 12 4.26736C10.6742 4.06736 9.32583 4.06736 8 4.26736C7.88333 3.98402 7.70833 3.63402 7.55 3.35902C7.54166 3.34236 7.51666 3.33402 7.49166 3.33402C6.24166 3.55069 5.05 3.92569 3.93333 4.44236C3.925 4.44236 3.91666 4.45069 3.90833 4.45902C1.64166 7.85069 1.01666 11.1507 1.325 14.4174C1.325 14.434 1.33333 14.4507 1.35 14.459C2.85 15.559 4.29166 16.2257 5.71666 16.6674C5.74166 16.6757 5.76666 16.6674 5.775 16.6507C6.10833 16.1924 6.40833 15.709 6.66666 15.2007C6.68333 15.1674 6.66666 15.134 6.63333 15.1257C6.15833 14.9424 5.70833 14.7257 5.26666 14.4757C5.23333 14.459 5.23333 14.409 5.25833 14.384C5.35 14.3174 5.44166 14.2424 5.53333 14.1757C5.55 14.159 5.575 14.159 5.59166 14.1674C8.45833 15.4757 11.55 15.4757 14.3833 14.1674C14.4 14.159 14.425 14.159 14.4417 14.1757C14.5333 14.2507 14.625 14.3174 14.7167 14.3924C14.75 14.4174 14.75 14.4674 14.7083 14.484C14.275 14.7424 13.8167 14.9507 13.3417 15.134C13.3083 15.1424 13.3 15.184 13.3083 15.209C13.575 15.7174 13.875 16.2007 14.2 16.659C14.225 16.6674 14.25 16.6757 14.275 16.6674C15.7083 16.2257 17.15 15.559 18.65 14.459C18.6667 14.4507 18.675 14.434 18.675 14.4174C19.0417 10.6424 18.0667 7.36736 16.0917 4.45902C16.0833 4.45069 16.075 4.44236 16.0583 4.44236ZM7.1 12.4257C6.24166 12.4257 5.525 11.634 5.525 10.659C5.525 9.68402 6.225 8.89236 7.1 8.89236C7.98333 8.89236 8.68333 9.69236 8.675 10.659C8.675 11.634 7.975 12.4257 7.1 12.4257ZM12.9083 12.4257C12.05 12.4257 11.3333 11.634 11.3333 10.659C11.3333 9.68402 12.0333 8.89236 12.9083 8.89236C13.7917 8.89236 14.4917 9.69236 14.4833 10.659C14.4833 11.634 13.7917 12.4257 12.9083 12.4257Z"
      fill="#777887"
    />
  </svg>
);
