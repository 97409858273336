import React, { useContext } from "react";
import {
  Button,
  Grid,
  Stack,
  Typography,
  List,
  ListItem,
  Avatar,
} from "@mui/material";
import {
  basicTone200,
  basicTone500,
  basicTone100,
  mainBorderRadius,
  basicTone50,
  basicTone600,
  dropShadowSM,
  basicTone450,
  whiteTone,
} from "../../../shared/styles/constants";
import { TApplicationItem } from "../types";
import { ThemeModeContext } from "../../../shared/providers/theme-mode-provider";
import { useNavigate } from "react-router-dom";
import { fontPretendard } from "../../../theme";
import { formatDate } from "../../../shared/utils";

export const ApplicationsList = ({ apps }: { apps: TApplicationItem[] }) => {
  const { isLightMode } = useContext(ThemeModeContext);
  const navigate = useNavigate();

  return apps.length ? (
    <Stack justifyContent={"space-between"}>
      <List>
        <ListItem sx={{ display: { xs: "none", sm: "block" } }}>
          <Grid container sx={{ paddingBottom: "10px" }}>
            <Grid item sm={4} md={4}>
              <Typography
                fontFamily={fontPretendard}
                fontWeight={400}
                fontSize={"16px"}
                lineHeight={"14.32px"}
                color={basicTone200}
              >
                Project Name
              </Typography>
            </Grid>
            <Grid item sm={4} md={3.5} paddingRight={"5px"}>
              <Typography
                fontFamily={fontPretendard}
                fontWeight={400}
                fontSize={"16px"}
                lineHeight={"14.32px"}
                color={basicTone200}
              >
                Round name
              </Typography>
            </Grid>
            <Grid item sm={2} md={2.5}>
              <Typography
                fontFamily={fontPretendard}
                fontWeight={400}
                fontSize={"16px"}
                lineHeight={"14.32px"}
                color={basicTone200}
              >
                Invested Amount
              </Typography>
            </Grid>
            <Grid item sm={2} md={2}>
              <Typography
                fontFamily={fontPretendard}
                fontWeight={400}
                fontSize={"16px"}
                lineHeight={"14.32px"}
                color={basicTone200}
              >
                Vesting Start
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
        <Stack>
          {apps.map((application) => {
            return application.FundingRounds.map((app) => (
              <ListItem
                component="a"
                href={`/${application.urlName}`}
                key={app.id}
                sx={{
                  border: `1px solid ${
                    isLightMode ? basicTone100 : basicTone500
                  }`,
                  cursor: "pointer",
                  filter: isLightMode ? "none" : `drop-shadow(${dropShadowSM})`,

                  padding: "15px 12px 12px 24px",

                  "&:hover": {
                    border: `1px solid ${
                      isLightMode ? basicTone50 : basicTone450
                    }`,
                    background: isLightMode ? basicTone100 : basicTone500,
                  },
                }}
              >
                <Grid container alignItems={"center"}>
                  <Grid
                    item
                    sm={4}
                    md={4}
                    container
                    alignItems={"center"}
                    gap={3}
                  >
                    <Avatar
                      src={application.logoUrl}
                      variant="rounded"
                      sx={{ width: "29px", height: "29px" }}
                    />
                    <Typography
                      fontFamily={fontPretendard}
                      fontWeight={700}
                      fontSize={"12px"}
                      lineHeight={"14.32px"}
                      color="secondary"
                    >
                      {application.name}
                    </Typography>
                  </Grid>
                  <Grid item sm={4} md={3.5} paddingRight={"8px"}>
                    <Typography
                      fontFamily={fontPretendard}
                      fontWeight={600}
                      fontSize={"12px"}
                      lineHeight={"14.32px"}
                      color="secondary"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {app.name}
                    </Typography>
                  </Grid>
                  <Grid item sm={2} md={2.5} paddingLeft={"5px"}>
                    <Typography
                      fontFamily={fontPretendard}
                      fontWeight={400}
                      fontSize={"12px"}
                      lineHeight={"14.32px"}
                      color="secondary"
                    >
                      {app.status === "fundingEnded" ||
                      app.status == "waiting" ||
                      app.status === "distributing"
                        ? `$${app.userInvestedAmount}`
                        : "N/A"}
                    </Typography>
                  </Grid>
                  <Grid item sm={2} md={2} paddingLeft={"5px"}>
                    <Typography
                      fontFamily={fontPretendard}
                      fontWeight={400}
                      fontSize={"12px"}
                      lineHeight={"14.32px"}
                      color="secondary"
                    >
                      {app.vestingStartDate
                        ? formatDate(app.vestingStartDate)
                        : "Start TGE"}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
            ));
          })}
        </Stack>
      </List>
      <Stack alignItems={"center"} marginTop={4}>
        <Button
          onClick={() => navigate("/")}
          variant="contained"
          sx={{ padding: "10px 28px", marginBottom: "40px" }}
        >
          See new projects
        </Button>
      </Stack>
    </Stack>
  ) : (
    <Grid
      container
      flexDirection="column"
      justifyContent={"center"}
      alignItems="center"
      gap={4}
      sx={{ padding: "40px 0" }}
    >
      <Stack alignItems={"center"}>
        <Typography
          variant="h2"
          color="secondary"
          textAlign={"center"}
        >{`Seems you don't have any applications yet`}</Typography>
        <Typography
          variant="h6"
          color={basicTone200}
        >{`It's the best time to apply`}</Typography>
      </Stack>
      <Button
        onClick={() => navigate("/")}
        variant="contained"
        sx={{ padding: "10px 28px" }}
      >
        See new projects
      </Button>
    </Grid>
  );
};

export default ApplicationsList;
