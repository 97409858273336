import { List, ListItem, Stack, Typography } from "@mui/material";
import TwitterIcon from "@mui/icons-material/X";
import TelegramIcon from "@mui/icons-material/Telegram";
import React from "react";
import { DiscordSVG } from "../../assets/DiscordSVG";
import {
  basicTone0,
  basicTone500,
  basicTone700,
  dropShadowBG,
} from "../../shared/styles/constants";

import { communityUrls } from "./header-bar-utils.utils";
import { MediumIconSVG } from "../../assets/icons/MediumIconSVG";

export const Community = ({ onMouseOut }: { onMouseOut: () => void }) => {
  return (
    <List
      onMouseOut={onMouseOut}
      sx={{
        position: "absolute",
        top: 42,
        height: "170px",
        transform: "translateX(calc(-46%))",
        borderTop: "1px solid rgb(36, 35, 35)",
        backgroundColor: basicTone700,
        boxShadow: dropShadowBG,
        display: "flex",
        padding: 0,
        borderRadius: "0 0 12px 12px",
      }}
    >
      <ListItem
        component="a"
        href={communityUrls.twitterURL}
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          padding: "28px 20px",
          borderWidth: "0px 1px 1px 1px",
          borderStyle: "solid",
          borderColor: basicTone500,
          borderRadius: "0px 0px 0px 12px",
          flex: 1,
          width: "232px",
          alignItems: "flex-start",
          color: basicTone0,
        }}
      >
        <TwitterIcon />
        <Stack sx={{ paddingLeft: "10px", paddingTop: "3px" }}>
          <Typography variant="body1" fontWeight={700} color={basicTone0}>
            Twitter
          </Typography>
          <Typography variant="body1" color="info">
            Stay informed with the latest CollectivePad's news and developments
          </Typography>
        </Stack>
      </ListItem>
      <ListItem
        component="a"
        href={communityUrls.discordURL}
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          padding: "28px 20px",
          borderWidth: "0px 1px 1px 1px",
          borderStyle: "solid",
          borderColor: basicTone500,
          flex: 1,
          width: "232px",
          alignItems: "flex-start",
          color: basicTone0,
        }}
      >
        <DiscordSVG color={basicTone0} width={"60px"} />
        <Stack sx={{ paddingLeft: "10px", paddingTop: "3px" }}>
          <Typography variant="body1" fontWeight={700} color={basicTone0}>
            Discord
          </Typography>
          <Typography variant="body1" color="info">
            Get access to our community and participate in our topics
          </Typography>
        </Stack>
      </ListItem>
      <ListItem
        component="a"
        href={communityUrls.telegramURL}
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          padding: "28px 20px",
          borderWidth: "0px 1px 1px 1px",
          borderStyle: "solid",
          borderColor: basicTone500,
          flex: 1,
          width: "232px",
          alignItems: "flex-start",
          color: basicTone0,
        }}
      >
        <TelegramIcon />
        <Stack sx={{ paddingLeft: "10px", paddingTop: "3px" }}>
          <Typography variant="body1" fontWeight={700} color={basicTone0}>
            Telegram
          </Typography>
          <Typography variant="body1" color="info">
            Join our community and receive support in real-time
          </Typography>
        </Stack>
      </ListItem>
      <ListItem
        component="a"
        href={communityUrls.mediumURL}
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          padding: "28px 20px",
          borderWidth: "0px 1px 1px 1px",
          borderStyle: "solid",
          borderColor: basicTone500,
          borderRadius: "0px 0px 12px 0px",
          width: "232px",
          flex: 1,
          alignItems: "flex-start",
          color: basicTone0,
        }}
      >
        <MediumIconSVG />
        <Stack sx={{ paddingLeft: "10px", paddingTop: "3px" }}>
          <Typography variant="body1" fontWeight={700} color={basicTone0}>
            Medium
          </Typography>
          <Typography variant="body1" color="info">
            Read about our updates and gain industry knowledge with informative
            content
          </Typography>
        </Stack>
      </ListItem>
    </List>
  );
};
