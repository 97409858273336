import { utils } from "ethers";
import React, {
  createContext,
  FC,
  useState,
  useEffect,
  useContext,
} from "react";
import { useNavigate } from "react-router-dom";
import {
  StableCoinType,
  TUserApplications,
  WalletInfoProps,
} from "../../pages/DashboardPageRedesign/types";
import {
  useUpdateInfoMutation,
  useUploadAvatarMutation,
} from "../../services/authenticationApi";
import { useGetFeaturedProjectsPerUserQuery } from "../../services/featuredProjectsApi";

import { useGetUserWalletDataQuery } from "../../services/walletApi";
import { AccountContext } from "./account-provider";
import { NotificationsContext } from "./notifications-provider";

export interface IDashboardContext {
  walletInfo: WalletInfoProps | undefined;
  applications: TUserApplications;
  showEditUserProfile: boolean;
  setShowEditUserProfile: (show: boolean) => void;
  userNameValue: string | undefined;
  setUserNameValue: (val: string) => void;
  avatarUrl: string | null;
  setAvatarUrl: (newPhoto: string | null) => void;
  handleEditUserProfile: () => void;
  updateUserProfileLoading: boolean;
  handleUploadPhoto: (ev: any) => void;
  usdcInfoByChain: StableCoinType | undefined;
}

export const DashboardContext = createContext<IDashboardContext>({
  walletInfo: undefined,
  applications: {
    signUpFeaturedProjects: [],
    fundingFeaturedProjects: [],
    waitingFeaturedProjects: [],
    distributingFeaturedProjects: [],
  },
  showEditUserProfile: false,
  setShowEditUserProfile: (show: boolean) => {},
  userNameValue: undefined,
  setUserNameValue: (val: string) => {},
  handleEditUserProfile: () => {},
  updateUserProfileLoading: false,
  avatarUrl: null,
  setAvatarUrl: (newPhoto: string | null) => {},
  handleUploadPhoto: (ev: any) => {},
  usdcInfoByChain: undefined,
});

export const DashboardProvider: FC<{ children?: React.ReactNode }> = (
  props
) => {
  //constants
  const token = localStorage.getItem("token");
  const username = localStorage.getItem("username");
  const avatar = localStorage.getItem("avatar");
  // state
  const [walletInfo, setWalletInfo] = useState<WalletInfoProps | undefined>();
  const [updateUserProfileLoading, setUpdateUserProfileLoading] =
    useState(false);
  const [userNameValue, setUserNameValue] = useState<string | undefined>(
    username || undefined
  );
  const [avatarUrl, setAvatarUrl] = useState<string | null>(avatar);
  const [applications, setApplications] = useState<TUserApplications>({
    signUpFeaturedProjects: [],
    fundingFeaturedProjects: [],
    waitingFeaturedProjects: [],
    distributingFeaturedProjects: [],
  });
  const [usdcInfoByChain, setUsdcInfoByChain] = useState<
    StableCoinType | undefined
  >(undefined);

  const [showEditUserProfile, setShowEditUserProfile] = useState(false);

  //dependencies
  const { handleDisconnect, userMetamaskChainId } = useContext(AccountContext);
  const { setNotification } = useContext(NotificationsContext);

  const navigate = useNavigate();

  // requests declarations
  const { data: userWalletData, error: userWalletError } =
    useGetUserWalletDataQuery(token);
  const { data, error: getApplicationsError } =
    useGetFeaturedProjectsPerUserQuery(token);
  const [uploadAvatar] = useUploadAvatarMutation();
  const [updateInfo] = useUpdateInfoMutation();

  useEffect(() => {
    if (data) {
      setApplications(data.data);
    }
  }, [data]);

  useEffect(() => {
    if (userMetamaskChainId && userWalletData) {
      const usdcStableCoin = userWalletData.data.stableCoins.find(
        (stableCoin: StableCoinType) =>
          stableCoin.chainId === userMetamaskChainId
      );
      setUsdcInfoByChain(usdcStableCoin);
    }
  }, [userMetamaskChainId, userWalletData]);

  useEffect(() => {
    if (userWalletData) {
      const usdcStableCoin = userWalletData.data.stableCoins.find(
        (stableCoin: StableCoinType) =>
          stableCoin.chainId === userMetamaskChainId
      );
      setUsdcInfoByChain(usdcStableCoin);
      setWalletInfo(userWalletData.data);
    }
  }, [userWalletData]);

  useEffect(() => {
    if (userWalletError?.status === 403) {
      handleDisconnect();
      navigate("/register");
    }
  }, [userWalletError]);

  useEffect(() => {
    if (getApplicationsError?.status === 403) {
      handleDisconnect();
      navigate("/register");
    }
  }, [getApplicationsError]);

  const handleEditUserProfile = () => {
    setUpdateUserProfileLoading(true);
    updateInfo({ token, username: userNameValue })
      .unwrap()
      .then((response: any) => {
        setUpdateUserProfileLoading(false);
        localStorage.setItem("username", userNameValue || "");
        setShowEditUserProfile(false);
      });
  };

  const handleUploadPhoto = (ev: any) => {
    ev.preventDefault();
    const file = ev.target.files[0];
    setAvatarUrl(URL.createObjectURL(file));

    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", file.name);
    uploadAvatar({ token, file: formData })
      .unwrap()
      .then((data: any) => {
        localStorage.setItem("avatar", data?.data.url);
        setNotification({ message: "Photo uploaded!", type: "success" });
      })
      .catch((er: any) => {
        setNotification({
          message: "An error occurred. Please try again!",
          type: "error",
        });
      });
  };
  return (
    <DashboardContext.Provider
      value={{
        walletInfo,
        applications,
        showEditUserProfile,
        setShowEditUserProfile: (val) => setShowEditUserProfile(val),
        userNameValue,
        setUserNameValue: (val: string) => setUserNameValue(val),
        handleEditUserProfile,
        updateUserProfileLoading,
        avatarUrl,
        setAvatarUrl: (newAvatarUrl: string | null) =>
          setAvatarUrl(newAvatarUrl),
        handleUploadPhoto,
        usdcInfoByChain,
      }}
    >
      {walletInfo && props.children}
    </DashboardContext.Provider>
  );
};
