export const ActiveRoundIconSVG = () => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9" cy="9" r="8.5" stroke="#FF377F" />
    <circle cx="9" cy="9" r="6" fill="#FF377F" />
  </svg>
);

export const ActiveStepIconSVG = () => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9.5" cy="9.5" r="8.5" stroke="#FF377F" />
    <circle cx="9.5" cy="9.5" r="5.5" fill="#FF377F" />
  </svg>
);
