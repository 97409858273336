import React, { CSSProperties } from "react";
import { Typography, useMediaQuery, Theme } from "@mui/material";
import { StepperRound } from "./utils";

import { DotIconSVG } from "../../../assets/icons/Vector 87dotsIcon"; // Assuming this is your dot icon component
import { basicTone400 } from "../../../shared/styles/constants";
import { CompletedRoundIconSVG } from "../../../assets/icons/CompletedRoundIcon";
import { ActiveRoundIconSVG } from "../../../assets/icons/ActiveRoundIcon";
import { InactiveRoundIconSVG } from "../../../assets/icons/InactiveRoundIcon";
import { fontMulish } from "../../../theme";

interface RoundsStepperProps {
  rounds: StepperRound[];
}

export const RoundsStepper = ({ rounds }: RoundsStepperProps) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const isTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.between("sm", "md")
  );

  const HorizontalContainer: CSSProperties = {
    display: "flex",
    padding: "10px 0",
    alignItems: "center", // Ensure vertical alignment
    gap: isMobile ? "3px" : isTablet ? "13px" : "16px", // Space between each set of items
    justifyContent: rounds.length <= 3 ? "center" : "flex-start",
    overflowX: "auto",

    width: isMobile ? "315px" : isTablet ? "700px" : "900px",
  };

  const ItemStyle: CSSProperties = {
    display: "flex",
    alignItems: "center",
  };

  const titleColor = (round: StepperRound) => {
    return round.isActive || round.isCompleted ? "secondary" : basicTone400;
  };

  return (
    <div
      style={{
        width: "100%",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        padding: "1px",
      }}
    >
      <div className="custom-scrollbar" style={HorizontalContainer}>
        {rounds.map((round, index) => (
          <React.Fragment key={round.id}>
            <div style={ItemStyle}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {round.isCompleted && !round.isActive && (
                  <CompletedRoundIconSVG />
                )}
                {round.isActive && <ActiveRoundIconSVG />}
                {!round.isActive && !round.isCompleted && (
                  <InactiveRoundIconSVG />
                )}
              </div>
              <Typography
                fontFamily={fontMulish}
                fontWeight={400}
                fontSize={"14px"}
                lineHeight={"17.57px"}
                color={titleColor(round)}
                style={{ marginLeft: "8px" }}
              >
                {round.name}
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {index < rounds.length - 1 && <DotIconSVG />}
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default RoundsStepper;
