import {
  Button,
  Card,
  CircularProgress,
  Divider,
  FilledInput,
  FormControl,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";

import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";

import {
  Arbitrum,
  BSC,
  BSCTestnet,
  Polygon,
  useCall,
  useContractFunction,
  useEthers,
} from "@usedapp/core";
import React, { useState, useContext, useEffect } from "react";
import { useContracts } from "../../../hooks";
import { AccountContext } from "../../../shared/providers/account-provider";
import BigNumber from "bignumber.js";
import { useRedeemTokensMutation } from "../../../services/fundingFeaturedProjectsApi";
import { LoadingButton } from "@mui/lab";
import { NotificationsContext } from "../../../shared/providers/notifications-provider";
import { IChain } from "../../../models/project.model";
import { fontFamily, fontPretendard } from "../../../theme";
import {
  basicTone200,
  borderBasicTone450,
} from "../../../shared/styles/constants";

type WinnerCardProps = {
  tokenForSaleAddress?: string;
  vestingContractAddress?: string;
  tokenRedeemedAmount: string;
  fullVestedAmount: string;
};

const ValueWithLabel = ({
  label,
  value = "-",
  valPrefix = "",
  valSufix = "",
}: {
  label: string;
  value: string | number | undefined | null;
  valPrefix?: string;
  valSufix?: string;
}) => (
  <Grid container item xs={12} sm={12} justifyContent="space-between">
    <Grid item xs={6}>
      <Typography
        fontFamily={fontFamily}
        fontWeight={400}
        fontSize={"14px"}
        lineHeight={"19.1px"}
        color={basicTone200}
      >
        {label}
      </Typography>
    </Grid>
    <Grid item xs={6} style={{ textAlign: "right" }}>
      <Typography
        fontFamily={fontFamily}
        color="secondary"
        fontWeight={700}
        fontSize={"12px"}
        lineHeight={"16.37px"}
      >
        {`${valPrefix}${value}${valSufix}`}
      </Typography>
    </Grid>
  </Grid>
);

export const WinnerCard = ({
  tokenForSaleAddress,
  vestingContractAddress,
  fullVestedAmount,
  tokenRedeemedAmount,
}: WinnerCardProps) => {
  const { stableCoinContract, vestingContract } = useContracts({
    stableCoinAddress: tokenForSaleAddress,
    vestingContractAddress,
  });
  const { setNotification } = useContext(NotificationsContext);
  const [redeemProxyTokens] = useRedeemTokensMutation();
  const [isRedeemDisabled, setIsRedeemDisabled] = useState(false);
  const [redeemAmount, setRedeemAmount] = useState<string>("");
  const [redeemMessage, setRedeemMessage] = useState<string>("REDEEM");
  const [availableRedeemedAmount, setAvailableRedeemedAmount] =
    useState<string>("0");

  const [balance, setBallance] = useState<string>("0");

  const { account, isConnectedToMetamask } = useContext(AccountContext);

  const { value: balanceOf, error: balanceOfError } =
    useCall({
      contract: stableCoinContract,
      method: "balanceOf",
      args: [account],
    }) ?? {};

  const { value: availableBalance, error: availableBalanceError } =
    useCall({
      contract: vestingContract,
      method: "getAvailableBalance",
      args: [account],
    }) ?? {};

  useEffect(() => {
    if (balanceOf) {
      const val = new BigNumber(balanceOf.toString())
        .dividedBy(1e18)
        .decimalPlaces(2, 1)
        .toString();
      setBallance(val);
    }
  }, [balanceOf]);

  useEffect(() => {
    if (availableBalance) {
      const val = new BigNumber(availableBalance.toString()).dividedBy(1e18);

      let formattedVal;
      if (val.isInteger()) {
        formattedVal = val.toFixed(0);
      } else {
        formattedVal = val.toFixed(4, BigNumber.ROUND_DOWN);
      }

      setAvailableRedeemedAmount(formattedVal);
    }
  }, [availableBalance]);

  // Redeem
  const { state: redeemState, send: redeem } = useContractFunction(
    vestingContract,
    "redeem",
    {
      transactionName: "Redeem ",
    }
  );

  useEffect(() => {
    if (redeemState.status === "Success") {
      setNotification({ message: "Redeem successful!", type: "success" });
      setRedeemAmount("");
    } else if (
      redeemState.status === "Fail" ||
      redeemState.status === "Exception"
    ) {
      setNotification({
        message: "Redeem failed!",
        type: "error",
      });
    }
  }, [redeemState]);

  const handleRedeem = () => {
    const amount = redeemAmount ? parseFloat(redeemAmount) : 0;
    const maxLimit = parseFloat(availableRedeemedAmount);
    const minLimit = 0;
    if (amount && amount > 0 && amount <= maxLimit && amount >= minLimit) {
      const acceptedRedeemAmount = new BigNumber(redeemAmount)
        .multipliedBy(1e18)
        .toFixed();
      if (isConnectedToMetamask) {
        redeem(acceptedRedeemAmount);
      } else {
        const token = localStorage.getItem("token");
        setIsRedeemDisabled(true);
        setRedeemAmount("");
        redeemProxyTokens({
          token,
          amount: acceptedRedeemAmount,
          contractAddress: vestingContractAddress,
        })
          .unwrap()
          .then((data: any) => {
            if (data?.data.receipt.status === 1) {
              setNotification({
                message: "Redeem successfully!",
                type: "success",
              });
            } else {
              setNotification({
                message: "Redeem failed!",
                type: "error",
              });
            }
            setIsRedeemDisabled(false);
          })
          .catch((err: any) => {
            setNotification({
              message: "Redeem failed!",
              type: "error",
            });
            setIsRedeemDisabled(false);
          });
      }
    }
  };

  return (
    <Grid
      container
      item
      xs={12}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      gap={1}
    >
      <Grid container gap={2}>
        <Divider />
        <ValueWithLabel label="Full vested amount:" value={fullVestedAmount} />
        <ValueWithLabel
          label="Token redeemed amount:"
          value={tokenRedeemedAmount}
        />
        <ValueWithLabel
          label="Available amount:"
          value={availableRedeemedAmount}
        />
        <ValueWithLabel label="Wallet Balance:" value={`${balance || 0}`} />
        <Divider />
      </Grid>
      <Typography
        fontFamily={fontPretendard}
        fontWeight={400}
        fontSize={"14px"}
        lineHeight={"16.71px"}
        color={basicTone200}
        marginTop={"10px"}
      >
        Claim rewards
      </Typography>
      <FormControl variant="outlined" fullWidth>
        <FilledInput
          id="outlined-adornment-weight"
          color="secondary"
          placeholder="0.0"
          value={redeemAmount}
          onChange={(ev) => {
            // This regex allows numbers, and if there's a decimal point, it requires at least one digit before it
            if (ev.target.value === "" || /^\d+\.?\d*$/.test(ev.target.value)) {
              setRedeemAmount(ev.target.value);
              setRedeemMessage("REDEEM");
            }
          }}
          endAdornment={
            <InputAdornment position="start">
              <Button
                color="primary"
                onClick={() => {
                  setRedeemAmount(availableRedeemedAmount.toString());
                  setRedeemMessage("REDEEM");
                }}
                variant="contained"
                sx={{
                  filter: "none",
                  padding: "4px 8px 4px 8px",
                  borderRadius: "25px",
                  border: `1px solid ${borderBasicTone450}`,
                  width: "auto",
                  minWidth: 0,
                  minHeight: 0,
                }}
              >
                MAX
              </Button>
            </InputAdornment>
          }
          aria-describedby="outlined-weight-helper-text"
          inputProps={{
            "aria-label": "balance",

            style: { padding: "10px" },
          }}
        />
      </FormControl>
      <Grid item sx={{ width: "100%" }}>
        <LoadingButton
          color="primary"
          fullWidth
          onClick={() => {
            redeemAmount === "" || redeemAmount === "0"
              ? setRedeemMessage("Please enter a value")
              : handleRedeem();
          }}
          loading={isRedeemDisabled || redeemState.status === "Mining"}
          loadingPosition="start"
          startIcon={<AccountBalanceWalletIcon />}
          variant="contained"
          sx={{ height: "48px", marginTop: "5px" }}
        >
          <span>{redeemMessage}</span>
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

type DistributingCardProps = {
  maxAllocation?: string;
  minAllocation?: string;
  tokenForSaleAddress?: string;
  vestingContractAddress?: string;
  projectChain: IChain;
  status: string;
  projectTitle: string;
};
export const DistributingCard = ({
  maxAllocation,
  tokenForSaleAddress,
  vestingContractAddress,
  minAllocation,
  projectChain,
  status,
  projectTitle,
}: DistributingCardProps) => {
  const { vestingContract } = useContracts({
    stableCoinAddress: tokenForSaleAddress,
    vestingContractAddress,
  });

  const [loadingIsUserWinner, setLoadingIsUserWinner] = useState(true);
  const [fullVestedAmount, setFullVestedAmount] = useState<string>("0");
  const [tokenRedeemedAmount, setTokenRedeemedAmount] = useState<string>("0");

  const [isWinner, setIsWinner] = useState<boolean>();
  const {
    account,
    setUserMetamaskChainId,
    userMetamaskChainId,
    isConnectedToMetamask,
  } = useContext(AccountContext);

  const { value: userBalances, error: userBalancesError } =
    useCall({
      contract: vestingContract,
      method: "userBalances",
      args: [account],
    }) ?? {};

  useEffect(() => {
    if (userBalances) {
      const tokenBalance = new BigNumber(
        userBalances.tokenBalance.toString()
      ).dividedBy(1e18);

      setIsWinner(!tokenBalance.isZero());
      setLoadingIsUserWinner(false);

      const tokenRelease = new BigNumber(
        userBalances.tokenReleased.toString()
      ).dividedBy(1e18);

      let formattedTokenRelease;
      if (tokenRelease.isInteger()) {
        formattedTokenRelease = tokenRelease.toFixed(0);
      } else {
        formattedTokenRelease = tokenRelease.toFixed(4, BigNumber.ROUND_DOWN);
      }

      setFullVestedAmount(tokenBalance.toFixed(4, BigNumber.ROUND_DOWN));
      setTokenRedeemedAmount(formattedTokenRelease);
    }
  }, [userBalances]);

  const renderCardContent = (
    <Typography variant="body1" fontSize={"14px"} fontWeight={500}>
      {status === "distributing"
        ? "To get involved in project staking please make sure you have completed the following requirements."
        : "To get involved in project staking please make sure you sign up to a new round when available."}
    </Typography>
  );

  return (
    <Grid
      container
      justifyContent={"flex-start"}
      gap={2}
      paddingTop={"30px"}
      paddingBottom={"20px"}
    >
      <Typography
        variant="h5"
        color="secondary"
        fontWeight={800}
        fontSize="16px"
      >
        {status === "distributing"
          ? "Distributing stage"
          : `Interested in supporting ${projectTitle} project?`}
      </Typography>
      {isWinner ? (
        <WinnerCard
          tokenForSaleAddress={tokenForSaleAddress}
          vestingContractAddress={vestingContractAddress}
          fullVestedAmount={fullVestedAmount}
          tokenRedeemedAmount={tokenRedeemedAmount}
        />
      ) : (
        <>
          {renderCardContent}
          {status === "distributing" ? (
            <Grid container justifyContent={"center"}>
              {loadingIsUserWinner ? (
                <CircularProgress color="secondary" />
              ) : (
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  disabled={true}
                  sx={{ height: "48px", borderRadius: "8px" }}
                >
                  You were not selected for this round
                </Button>
              )}
            </Grid>
          ) : (
            <Button
              fullWidth
              color="primary"
              variant="contained"
              disabled={true}
              sx={{ height: "48px", borderRadius: "8px" }}
            >
              Finished
            </Button>
          )}
        </>
      )}
    </Grid>
  );
};
export default DistributingCard;
