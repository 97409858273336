import { Stack, Grid, Button, Typography, Skeleton, Box } from "@mui/material";

import TabsContainer from "./TabsContainer";
import {
  basicTone400,
  basicTone50,
  basicTone800,
  linearBlackBG,
  secondaryBorderRadius,
  whiteTone,
} from "../../../../shared/styles/constants";
import CreateBidOrderModal from "./ordersModals/CreateBidOrderModal";
import CreateAskOrderModal from "./ordersModals/CreateAskOrderModal";
import { useContext, useEffect, useState } from "react";
import { ThemeModeContext } from "../../../../shared/providers/theme-mode-provider";
import { MarketplaceContext } from "../../../../shared/providers/marketplace-provider";
import GraphContainer from "./GraphContainer";
import { AccountContext } from "../../../../shared/providers/account-provider";
import { useNavigate } from "react-router-dom";
import DifferentChainCard from "./DifferentChainCard";
import { IFundingRound } from "../../../../models/project.model";
import { useGetFundingRoundByFundingTermsAndSelectedTokenAddressesQuery } from "../../../../services/featuredProjectsApi";

export const MainContent = () => {
  const { isLightMode } = useContext(ThemeModeContext);
  const { selectedToken, fundingTermsAddress, isTokenListLoading } =
    useContext(MarketplaceContext);
  const { account, userMetamaskChainId } = useContext(AccountContext);
  const [fundingRoundData, setFundingRoundData] = useState<IFundingRound>();

  const [loadingDelay, setLoadingDelay] = useState(true);

  const emailIsValidated = !!localStorage.getItem("emailValidated");
  const navigate = useNavigate();

  const [showCreateBidOrderModal, setShowCreateBidOrderModal] =
    useState<boolean>(false);
  const [showCreateAskOrderModal, setShowCreateAskOrderModal] =
    useState<boolean>(false);

  // Check if fundingTermsAddress and selectedToken are defined before making the query
  const skipQuery = !fundingTermsAddress || !selectedToken?.tokenAddress;

  // Funding round data fetch from backend
  // fundingTermsAddress is vesting terms addreess actually
  const { data, isSuccess, error, isLoading } =
    useGetFundingRoundByFundingTermsAndSelectedTokenAddressesQuery(
      {
        vestingTermsAddress: fundingTermsAddress,
        selectedTokenAddress: selectedToken?.tokenAddress,
      },
      { skip: skipQuery } // Skip the query if necessary
    );

  useEffect(() => {
    if (isSuccess && data.data) {
      setFundingRoundData(data.data as IFundingRound);
    }
  }, [isSuccess, data]);

  useEffect(() => {
    const delay = setTimeout(() => {
      setLoadingDelay(false);
    }, 4000); // Adjust the delay time as needed (e.g., 3000ms for 3 seconds)

    return () => clearTimeout(delay); // Clear the timeout if the component unmounts
  }, []);

  return (
    <Grid item xs={12} md={7} container sx={{ height: "100%", width: "100%" }}>
      <Stack width="100%" gap={"8px"}>
        {!isTokenListLoading ? (
          <>
            <GraphContainer selectedToken={selectedToken} />
            <Grid container spacing={1} px={1}>
              {account ? (
                userMetamaskChainId !== selectedToken?.chainId &&
                selectedToken?.chainId ? (
                  <DifferentChainCard
                    selectedTokenChainId={selectedToken?.chainId}
                  />
                ) : emailIsValidated ? (
                  !isTokenListLoading && !loadingDelay && !isLoading ? (
                    <>
                      <Grid
                        item
                        container
                        xs={12}
                        display="flex"
                        flexDirection={"column"}
                        justifyContent="center"
                        alignContent={"center"}
                        gap={1}
                      >
                        {fundingRoundData &&
                          fundingRoundData.status === "distributing" && (
                            <Typography
                              variant="body1"
                              color="secondary"
                              fontSize={"14px"}
                              textAlign="center"
                            >
                              {`You can't create orders because distributing has started.`}
                            </Typography>
                          )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Button
                          onClick={() => setShowCreateBidOrderModal(true)}
                          sx={{
                            backgroundColor: "#5DB03E",
                            width: "100%",
                            borderRadius: "3px",
                            height: "48px",
                            color: basicTone800,
                            fontSize: "14px",
                            fontWeight: 600,
                            transition: "all 400ms",
                            "&:hover": {
                              color: isLightMode ? basicTone400 : whiteTone,
                            },
                          }}
                          disabled={
                            (fundingRoundData &&
                              fundingRoundData.status === "distributing") ||
                            !selectedToken
                          }
                        >
                          Create Bid
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Button
                          onClick={() => setShowCreateAskOrderModal(true)}
                          sx={{
                            width: "100%",
                            borderRadius: "3px",
                            height: "48px",
                            fontSize: "14px",
                            fontWeight: 600,
                            transition: "all 400ms",
                          }}
                          variant="contained"
                          disabled={
                            (fundingRoundData &&
                              fundingRoundData.status === "distributing") ||
                            !selectedToken
                          }
                        >
                          Create Ask
                        </Button>
                      </Grid>
                    </>
                  ) : (
                    <Skeleton
                      variant="rounded"
                      width={"97%"}
                      height={50}
                      sx={{
                        background: !isLightMode ? basicTone50 : linearBlackBG,
                        borderRadius: secondaryBorderRadius,
                        margin: "auto",
                      }}
                    />
                  )
                ) : (
                  <Grid
                    item
                    container
                    xs={12}
                    display="flex"
                    flexDirection={"column"}
                    justifyContent="center"
                    alignContent={"center"}
                    gap={1}
                  >
                    <Typography
                      variant="body1"
                      color="secondary"
                      fontSize={"14px"}
                      textAlign="center"
                    >
                      {`You can't create orders because you haven't validated your e-mail yet. Please go to your account and validate your e-mail.`}
                    </Typography>

                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        navigate("/my-wallet");
                      }}
                      sx={{
                        height: "48px",
                        borderRadius: "8px",
                        width: "32%",
                        alignSelf: "center",
                      }}
                    >
                      My account
                    </Button>
                  </Grid>
                )
              ) : (
                <Grid
                  item
                  container
                  xs={12}
                  display="flex"
                  flexDirection={"column"}
                  justifyContent="center"
                  alignContent={"center"}
                  gap={1}
                >
                  <Typography
                    variant="body1"
                    color="secondary"
                    fontSize={"14px"}
                    textAlign="center"
                  >
                    You need to be authenticated before creating orders.
                  </Typography>

                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      navigate("/register");
                    }}
                    sx={{
                      height: "48px",
                      borderRadius: "8px",
                      width: "32%",
                      alignSelf: "center",
                    }}
                  >
                    Sign In
                  </Button>
                </Grid>
              )}
              <CreateBidOrderModal
                open={showCreateBidOrderModal}
                onClose={() => setShowCreateBidOrderModal(false)}
              />
              <CreateAskOrderModal
                open={showCreateAskOrderModal}
                onClose={() => setShowCreateAskOrderModal(false)}
              />
            </Grid>
            <TabsContainer />
          </>
        ) : (
          <Stack gap={2} sx={{ p: 2 }}>
            <Box>
              <Skeleton
                variant="rounded"
                width={"40%"}
                height={50}
                sx={{
                  background: !isLightMode ? basicTone50 : linearBlackBG,
                  borderRadius: secondaryBorderRadius,
                }}
              />
            </Box>
            <Box>
              <Skeleton
                variant="rounded"
                width={"100%"}
                height={350}
                sx={{
                  background: !isLightMode ? basicTone50 : linearBlackBG,
                  borderRadius: secondaryBorderRadius,
                }}
              />
            </Box>
            <Box>
              <Skeleton
                variant="rounded"
                width={"100%"}
                height={50}
                sx={{
                  background: !isLightMode ? basicTone50 : linearBlackBG,
                  borderRadius: secondaryBorderRadius,
                }}
              />
            </Box>
            <Box>
              <Skeleton
                variant="rounded"
                width={"20%"}
                height={20}
                sx={{
                  background: !isLightMode ? basicTone50 : linearBlackBG,
                  borderRadius: secondaryBorderRadius,
                }}
              />
            </Box>
            <Box>
              <Skeleton
                variant="rounded"
                width={"100%"}
                height={20}
                sx={{
                  background: !isLightMode ? basicTone50 : linearBlackBG,
                  borderRadius: secondaryBorderRadius,
                }}
              />
            </Box>
            <Box>
              <Skeleton
                variant="rounded"
                width={"100%"}
                height={20}
                sx={{
                  background: !isLightMode ? basicTone50 : linearBlackBG,
                  borderRadius: secondaryBorderRadius,
                }}
              />
            </Box>
            <Box>
              <Skeleton
                variant="rounded"
                width={"100%"}
                height={20}
                sx={{
                  background: !isLightMode ? basicTone50 : linearBlackBG,
                  borderRadius: secondaryBorderRadius,
                }}
              />
            </Box>
            <Box>
              <Skeleton
                variant="rounded"
                width={"100%"}
                height={20}
                sx={{
                  background: !isLightMode ? basicTone50 : linearBlackBG,
                  borderRadius: secondaryBorderRadius,
                }}
              />
            </Box>
          </Stack>
        )}
      </Stack>
    </Grid>
  );
};

export default MainContent;
