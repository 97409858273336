import {
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
} from "@mui/material";
import { useCall, useContractFunction } from "@usedapp/core";

import React, { useContext, useEffect, useState } from "react";

import {
  basicTone0,
  basicTone500,
  mainColor2,
} from "../../../shared/styles/constants";

import { ThemeModeContext } from "../../../shared/providers/theme-mode-provider";
import { useContracts } from "../../../hooks";
import { NotificationsContext } from "../../../shared/providers/notifications-provider";

import { useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { STABLE_COIN_ADDRESS } from "../../../config/CoinAbi";
import { AccountContext } from "../../../shared/providers/account-provider";
import BigNumber from "bignumber.js";
import { MarketplaceContext } from "../../../shared/providers/marketplace-provider";

type BuyTokensModalProps = {
  open: boolean;
  onClose: () => void;
  saleId: string | undefined;
  payToken: string;
  fullPrice: string;
};

export const BuyTokensModal = ({
  open,
  onClose,
  saleId,
  fullPrice,
  payToken,
}: BuyTokensModalProps) => {
  const { isLightMode } = useContext(ThemeModeContext);
  const { setNotification } = useContext(NotificationsContext);
  const { marketplaceAddresses } = useContext(MarketplaceContext);
  const { account } = useContext(AccountContext);
  const [allowanceValue, setAllowanceValue] = useState<boolean>(false);

  const { fundingTermsAddress } = useParams();

  const { marketplaceContract, stableCoinContract } = useContracts({
    marketplaceContractAddress: marketplaceAddresses?.marketplaceMaticAddress,
    stableCoinAddress: STABLE_COIN_ADDRESS,
  });

  const { state: approveState, send: approve } = useContractFunction(
    stableCoinContract,
    "approve",
    {
      transactionName: "Approve",
    }
  );

  const { value: allowance, error: allowanceError } =
    useCall({
      contract: stableCoinContract,
      method: "allowance",
      args: [account, marketplaceAddresses?.marketplaceMaticAddress],
    }) ?? {};

  useEffect(() => {
    if (allowance) {
      const val = new BigNumber(allowance.toString());

      setAllowanceValue(
        val.isGreaterThanOrEqualTo(new BigNumber(fullPrice.toString()))
      );
    }
  }, [allowance]);

  const handleApprove = () => {
    approve(marketplaceAddresses?.marketplaceMaticAddress, fullPrice);
  };

  const { state: buyState, send: buy } = useContractFunction(
    marketplaceContract,
    "buy",
    {
      transactionName: "Buy ",
    }
  );

  useEffect(() => {
    if (buyState?.status === "Success") {
      setNotification({
        message: "You successfully bought tokens!",
        type: "success",
      });
      onClose();
    }
  }, [buyState]);

  const handleBuyTokens = () => {
    buy(fundingTermsAddress, saleId, payToken, fullPrice);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        backgroundColor: "initial",
        "& .MuiDialog-paper": {
          backgroundColor: isLightMode ? basicTone0 : basicTone500,
          padding: "24px",
          border: `1px solid ${mainColor2}`,
        },
      }}
      aria-labelledby="modal-buy-tokens"
      aria-describedby="modal-buy-tokens"
    >
      <DialogTitle sx={{ fontSize: "28px", fontWeight: 700 }}>
        Buy tokens
      </DialogTitle>

      <DialogContent>
        <Typography variant="h6">SaleId: {saleId}</Typography>
        <Typography variant="h6">PayToken: {payToken}</Typography>
        <Typography variant="h6">fullPrice: {fullPrice?.toString()}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text">
          Cancel
        </Button>
        {allowanceValue ? (
          <LoadingButton
            color="primary"
            variant="contained"
            onClick={() => handleBuyTokens()}
            loading={
              buyState.status === "PendingSignature" ||
              buyState.status === "Mining"
            }
          >
            <span>Buy</span>
          </LoadingButton>
        ) : (
          <LoadingButton
            color="primary"
            variant="contained"
            onClick={() => handleApprove()}
            loading={
              approveState.status === "PendingSignature" ||
              approveState.status === "Mining"
            }
          >
            <span>Approve</span>
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default BuyTokensModal;
