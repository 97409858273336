import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
} from "react";

import {
  TCoin,
  TMarketCoinAddresses,
  TMarketplaceAddresses,
} from "../../pages/MarketplacePageRedesign/types";
import {
  mapAvailableTokensForSaleList,
  mapSupportedTokensList,
} from "../../pages/MarketplacePageRedesign/Marketplace.utils";
import { AccountContext } from "./account-provider";
import { useContracts } from "../../hooks";

import { Arbitrum, BSCTestnet, Polygon, useCall } from "@usedapp/core";

import { AvailableChains } from "../utils";
import { useGetMarketAndCoinAddressesQuery } from "../../services/featuredProjectsApi";
import { BlastTestNet } from "../..";
import { useLocation } from "react-router-dom";

export interface IMarketplaceContext {
  selectedToken: TCoin | undefined;
  tokenAddress: string | undefined;
  fundingTermsAddress: string | undefined;
  tokensList: TCoin[];
  myTokensList: TCoin[];
  marketplaceAddresses: TMarketplaceAddresses;
  selectedTokensTabValue: number;
  onChangeSelectedToken: (val: TCoin) => void;
  onSelectTokensTab: (val: number) => void;
  getMarketplaceAddressByChain: (val: number | undefined) => string | undefined;
  isTokenListLoading: boolean;
}

export const MarketplaceContext = createContext<IMarketplaceContext>({
  selectedToken: undefined,
  tokenAddress: undefined,
  fundingTermsAddress: undefined,
  tokensList: [],
  myTokensList: [],
  marketplaceAddresses: {
    marketplaceBSCTestnetAddress: "",
    marketplaceBlastTestnetAddress: "",
    marketplaceMaticAddress: "",
    marketplaceArbitrumAddress: "",
  },
  selectedTokensTabValue: 0,
  onChangeSelectedToken: (val: TCoin) => {},
  onSelectTokensTab: (val: number) => {},
  getMarketplaceAddressByChain: (val: number | undefined) => "",
  isTokenListLoading: false,
});

export const TOKENS = {
  MY_TOKENS: 0,
  ALL_TOKENS: 1,
};

export const MarketplaceProvider: FC<{ children?: React.ReactNode }> = (
  props
) => {
  const location = useLocation();
  const [selectedToken, setSelectedToken] = useState<TCoin | undefined>(
    undefined
  );
  const [selectedTokensTabValue, setSelectedTokensTabValue] = useState<number>(
    TOKENS.MY_TOKENS
  );

  const [tokensList, setTokensList] = useState<TCoin[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const [marketplaceAddresses, setMarketplaceAddresses] =
    useState<TMarketplaceAddresses>({
      marketplaceBSCTestnetAddress: "",
      marketplaceBlastTestnetAddress: "",
      marketplaceMaticAddress: "",
      marketplaceArbitrumAddress: "",
    });

  const { account, isConnectedToMetamask } = useContext(AccountContext);

  const { marketplaceContract: marketplaceMaticContract } = useContracts({
    marketplaceContractAddress: marketplaceAddresses.marketplaceMaticAddress,
  });
  // const { marketplaceContract: marketplaceBinanceTestNetContract } =
  //   useContracts({
  //     marketplaceContractAddress: MARKETPLACE_BSCTESTNET_ADDRESS,
  //   });

  const { marketplaceContract: marketplaceArbitrumContract } = useContracts({
    marketplaceContractAddress: marketplaceAddresses.marketplaceArbitrumAddress,
  });

  const {
    data: marketAndCoinAddressesData,
    error: marketAndCoinAddressesError,
    isSuccess,
  } = useGetMarketAndCoinAddressesQuery();

  const { value: listSupportedTokensMatic } =
    useCall(
      !isConnectedToMetamask &&
        marketplaceMaticContract && {
          contract: marketplaceMaticContract,
          method: "listSupportedTokens",
          args: [],
        },
      { chainId: AvailableChains.Polygon }
    ) ?? {};

  const { value: listSupportedTokensArbitrum } =
    useCall(
      !isConnectedToMetamask &&
        marketplaceArbitrumContract && {
          contract: marketplaceArbitrumContract,
          method: "listSupportedTokens",
          args: [],
        },
      { chainId: Arbitrum.chainId }
    ) ?? {};

  const { value: listAvailableTokensForSaleMatic } =
    useCall(
      isConnectedToMetamask &&
        marketplaceMaticContract && {
          contract: marketplaceMaticContract,
          method: "listAvailableTokensForSale",
          args: [account],
        },
      { chainId: AvailableChains.Polygon }
    ) ?? {};

  const { value: listAvailableTokensForSaleArbitrum } =
    useCall(
      isConnectedToMetamask &&
        marketplaceArbitrumContract && {
          contract: marketplaceArbitrumContract,
          method: "listAvailableTokensForSale",
          args: [account],
        },
      { chainId: Arbitrum.chainId }
    ) ?? {};

  const setSupportedTokensResults = () => {
    if (listSupportedTokensArbitrum && listSupportedTokensMatic) {
      const updatedCoinsList: TCoin[] = [
        ...mapSupportedTokensList(
          listSupportedTokensMatic?.[0],
          AvailableChains.Polygon
        ),
        ...mapSupportedTokensList(
          listSupportedTokensArbitrum?.[0],
          AvailableChains.Arbitrum
        ),
      ];

      setTokensList(updatedCoinsList);
      setIsLoading(false);
    }
  };

  const setAvailableTokensforSaleResults = () => {
    if (listAvailableTokensForSaleArbitrum && listAvailableTokensForSaleMatic) {
      const updatedCoinsList: TCoin[] = [
        ...mapAvailableTokensForSaleList(
          listAvailableTokensForSaleMatic?.[0],
          AvailableChains.Polygon
        ),
        ...mapAvailableTokensForSaleList(
          listAvailableTokensForSaleArbitrum?.[0],
          AvailableChains.Arbitrum
        ),
      ];

      setTokensList(updatedCoinsList);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    if (isConnectedToMetamask) {
      setAvailableTokensforSaleResults();
    } else {
      setSupportedTokensResults();
    }
  }, [
    isConnectedToMetamask,
    listAvailableTokensForSaleArbitrum,
    listAvailableTokensForSaleMatic,
    listSupportedTokensArbitrum,
    listSupportedTokensMatic,
  ]);

  useEffect(() => {
    if (location.state && location.state.selectedRoundVestingAdress) {
      const foundSelectedRoundToken = tokensList.find(
        (tokenItem) =>
          tokenItem.fundingTermsAddress ===
          location.state.selectedRoundVestingAdress
      );

      if (foundSelectedRoundToken) {
        setSelectedToken(foundSelectedRoundToken);
      }
    } else if (!selectedToken && tokensList.length) {
      setSelectedToken(
        tokensList.filter((token) => token.availableTokensForSale !== "0")
          .length
          ? tokensList.filter(
              (token) => token.availableTokensForSale !== "0"
            )[0]
          : tokensList[0]
      );
    }
  }, [tokensList, location.state]);

  useEffect(() => {
    if (isSuccess && marketAndCoinAddressesData) {
      const marketplaceAddresesData = {
        marketplaceBSCTestnetAddress: marketAndCoinAddressesData.data.find(
          (item: TMarketCoinAddresses) =>
            item.chain.chainId === BSCTestnet.chainId
        ).marketPlace,

        marketplaceBlastTestnetAddress: marketAndCoinAddressesData.data.find(
          (item: TMarketCoinAddresses) =>
            item.chain.chainId === BlastTestNet.chainId
        ).marketPlace,

        marketplaceMaticAddress: marketAndCoinAddressesData.data.find(
          (item: TMarketCoinAddresses) => item.chain.chainId === Polygon.chainId
        ).marketPlace,
        marketplaceArbitrumAddress: marketAndCoinAddressesData.data.find(
          (item: TMarketCoinAddresses) =>
            item.chain.chainId === Arbitrum.chainId
        ).marketPlace,
      };
      setMarketplaceAddresses(marketplaceAddresesData);
    }
  }, [isSuccess, marketAndCoinAddressesData]);

  const getTokenAddress = () => selectedToken?.tokenAddress;
  const getFundingTermsAddress = () => selectedToken?.fundingTermsAddress;

  const getMarketplaceAddressByChain = (chain: number | undefined) => {
    switch (chain) {
      case BSCTestnet.chainId: {
        return marketplaceAddresses?.marketplaceBSCTestnetAddress;
      }
      case Polygon.chainId: {
        return marketplaceAddresses?.marketplaceMaticAddress;
      }
      case BlastTestNet.chainId: {
        return marketplaceAddresses?.marketplaceBlastTestnetAddress;
      }
      case Arbitrum.chainId: {
        return marketplaceAddresses?.marketplaceArbitrumAddress;
      }
      default: {
        return marketplaceAddresses?.marketplaceMaticAddress;
      }
    }
  };

  const getMyTokens = () =>
    tokensList?.filter((token) => token.availableTokensForSale !== "0");

  const removeDuplicates = (listOfTokens: TCoin[]) =>
    listOfTokens.filter((token, index) => {
      return (
        index ===
        listOfTokens.findIndex((t) => token.internalId === t.internalId)
      );
    });

  return (
    <MarketplaceContext.Provider
      value={{
        selectedToken,
        tokenAddress: getTokenAddress(),
        fundingTermsAddress: getFundingTermsAddress(),
        tokensList,
        myTokensList: getMyTokens() || [],
        marketplaceAddresses,
        selectedTokensTabValue,
        onChangeSelectedToken: (val) => {
          setSelectedToken(val);
        },
        onSelectTokensTab: (val) => setSelectedTokensTabValue(val),
        getMarketplaceAddressByChain: (val) => {
          return getMarketplaceAddressByChain(val);
        },
        isTokenListLoading: isLoading,
      }}
    >
      {props.children}
    </MarketplaceContext.Provider>
  );
};
