import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import {
  Button,
  Divider,
  FilledInput,
  FormControl,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import BigNumber from "bignumber.js";
import LoadingButton from "@mui/lab/LoadingButton";

import { ethers } from "ethers";
import { useState, useEffect, useContext } from "react";

import { useCall, useContractFunction } from "@usedapp/core";

import { useContracts } from "../../../../hooks";
import { AccountContext } from "../../../../shared/providers/account-provider";
import { useBuyTokensMutation } from "../../../../services/fundingFeaturedProjectsApi";
import { NotificationsContext } from "../../../../shared/providers/notifications-provider";
import { getDividedByChainId } from "../utils";
import { fontFamily, fontPretendard } from "../../../../theme";
import {
  basicTone200,
  basicTone800,
  borderBasicTone450,
  mainColor2,
} from "../../../../shared/styles/constants";

type WinnerCardProps = {
  maxAllocation?: string;
  minAllocation?: string;
  stableCoinAddress?: string;
  fundingContractAddress?: string;
  coinSymbol?: string;
  dollarTargetAmount?: string;
  chainId: number;
};

const ValueWithLabel = ({
  label,
  value = "-",
  valPrefix = "",
  valSufix = "",
}: {
  label: string;
  value: string | number | undefined | null;
  valPrefix?: string;
  valSufix?: string;
}) => (
  <Grid container item xs={12} sm={12} justifyContent="space-between">
    <Grid item xs={6}>
      <Typography
        fontFamily={fontFamily}
        fontWeight={400}
        fontSize={"14px"}
        lineHeight={"19.1px"}
        color={basicTone200}
      >
        {label}
      </Typography>
    </Grid>
    <Grid item xs={6} style={{ textAlign: "right" }}>
      <Typography
        fontFamily={fontFamily}
        color="secondary"
        fontWeight={700}
        fontSize={"12px"}
        lineHeight={"16.37px"}
      >
        {`${valPrefix}${value}${valSufix}`}
      </Typography>
    </Grid>
  </Grid>
);

export const WinnerCard = ({
  stableCoinAddress,
  maxAllocation = "0",
  fundingContractAddress,
  coinSymbol,
  minAllocation = "0",
  dollarTargetAmount,
  chainId,
}: WinnerCardProps) => {
  const { stableCoinContract, fundingContract } = useContracts({
    stableCoinAddress,
    fundingContractAddress,
  });
  const { setNotification } = useContext(NotificationsContext);
  const [buyProxyTokens] = useBuyTokensMutation();
  const [isBuyDisabled, setIsBuyDisabled] = useState(false);
  const [stakeAmount, setStakeAmount] = useState<string>("");
  const [stakeMessage, setStakeMessage] = useState<string>("BUY");
  const [allowanceValue, setAllowanceValue] = useState<BigNumber | undefined>(
    undefined
  );
  const [totalAmountPaid, setTotalAmountPaid] = useState<number>(0);
  const [stableCoinAmountRaised, setStableCoinAmountRaised] =
    useState<number>(0);
  const [validityMessage, setValidityMessage] = useState<string>("");
  const [isAmountLimited, setIsAmountLimited] = useState<boolean>(false);

  const [balance, setBallance] = useState<string>("0");

  const { account, isConnectedToMetamask } = useContext(AccountContext);

  const { value: balanceOf, error: balanceOfError } =
    useCall({
      contract: stableCoinContract,
      method: "balanceOf",
      args: [account],
    }) ?? {};

  const { value: allowance, error: allowanceError } =
    useCall({
      contract: stableCoinContract,
      method: "allowance",
      args: [account, fundingContractAddress],
    }) ?? {};

  const { value: userBalances, error: userBalancesError } =
    useCall({
      contract: fundingContract,
      method: "userBalances",
      args: [account],
    }) ?? {};

  const {
    value: stableCoinAmountRaisedResult,
    error: stableCoinAmountRaisedError,
  } =
    useCall({
      contract: fundingContract,
      method: "stableCoinAmountRaised",
      args: [],
    }) ?? {};

  useEffect(() => {
    if (allowance) {
      const val = new BigNumber(allowance.toString());
      setAllowanceValue(val);
      // setAllowanceValue(!val.eq(new BigNumber(0)));
    }
  }, [allowance]);

  useEffect(() => {
    if (userBalances) {
      setTotalAmountPaid(userBalances.totalAmountPaid.toNumber());
    }
  }, [userBalances]);

  useEffect(() => {
    if (stableCoinAmountRaisedResult) {
      setStableCoinAmountRaised(
        new BigNumber(stableCoinAmountRaisedResult.toString()).toNumber()
      );
    }
  }, [stableCoinAmountRaisedResult]);

  useEffect(() => {
    if (balanceOf) {
      const divideBy = getDividedByChainId(chainId);

      const val = new BigNumber(balanceOf.toString())
        .dividedBy(divideBy)
        .decimalPlaces(5)
        .toString();

      setBallance(val);
    }
  }, [balanceOf]);

  // APPROVE
  const { state: approveState, send: approve } = useContractFunction(
    stableCoinContract,
    "approve",
    {
      transactionName: "Approve",
    }
  );
  // BUY TOKENS
  const { state: buyTokensState, send: buyTokens } = useContractFunction(
    fundingContract,
    "buyTokens",
    {
      transactionName: "Buy Tokens",
    }
  );

  useEffect(() => {
    if (approveState.status === "Success") {
      setNotification({
        message: "Successfully approved!",
        type: "success",
      });
    } else if (
      approveState.status === "Fail" ||
      approveState.status === "Exception"
    ) {
      setNotification({
        message: "Approve failed!",
        type: "error",
      });
    }
  }, [approveState]);

  useEffect(() => {
    if (buyTokensState.status === "Success") {
      setNotification({
        message: "You bought tokens successfully!",
        type: "success",
      });
      setStakeAmount("");
    } else if (
      buyTokensState.status === "Fail" ||
      buyTokensState.status === "Exception"
    ) {
      setNotification({
        message: "Buying tokens failed!",
        type: "error",
      });
    }
  }, [buyTokensState]);

  const handleApprove = () => {
    approve(fundingContractAddress, ethers.constants.MaxUint256);
  };

  const handleBuyTokens = () => {
    const amount = stakeAmount ? parseInt(stakeAmount) : 0;
    const maxLimit = parseInt(maxAllocation);
    const minLimit = parseInt(minAllocation);

    if (
      amount &&
      amount > 0 &&
      amount + totalAmountPaid <= maxLimit &&
      amount + totalAmountPaid >= minLimit
    ) {
      if (isConnectedToMetamask) {
        buyTokens(amount);
      } else {
        const token = localStorage.getItem("token");
        setIsBuyDisabled(true);
        setStakeAmount("");
        buyProxyTokens({
          contractAddress: fundingContractAddress,
          stableCoinAddress,
          amount: stakeAmount,
          token,
        })
          .unwrap()
          .then((data: any) => {
            if (data?.data.receipt.status === 1) {
              setNotification({
                message: "You bought tokens successfully!",
                type: "success",
              });
            } else {
              setNotification({
                message: "Buying tokens failed!",
                type: "error",
              });
            }
            setIsBuyDisabled(false);
          })
          .catch((err: any) => {
            setNotification({
              message: "Buying tokens failed!",
              type: "error",
            });
            setIsBuyDisabled(false);
          });
      }
    }
  };

  const showBuy = () => {
    const isStakeAmountApproved = allowanceValue
      ?.dividedBy(getDividedByChainId(chainId))
      .isGreaterThanOrEqualTo(stakeAmount);
    return (
      !isConnectedToMetamask ||
      isStakeAmountApproved ||
      (allowanceValue && !allowanceValue.eq(new BigNumber(0)))
    );
  };

  return (
    <Grid
      container
      item
      xs={12}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      gap={1}
    >
      <Grid container gap={2}>
        <Divider />
        <ValueWithLabel label="Token:" value={coinSymbol} />
        <ValueWithLabel label="Bought amount:" value={totalAmountPaid} />
        <ValueWithLabel
          label="Min-max amount:"
          value={`${minAllocation}-${maxAllocation}`}
        />
        <ValueWithLabel label="Balance:" value={`${balance || 0}`} />
        <Divider />
      </Grid>
      <Typography
        fontFamily={fontPretendard}
        fontWeight={400}
        fontSize={"14px"}
        lineHeight={"16.71px"}
        color={basicTone200}
        marginTop={"10px"}
      >
        Choose amount to buy
      </Typography>
      <FormControl variant="outlined" fullWidth>
        <FilledInput
          id="outlined-adornment-weight"
          color="secondary"
          // type=""
          placeholder="0"
          value={stakeAmount ? parseInt(stakeAmount) : stakeAmount}
          onChange={(ev: any) => {
            if (
              ev.target.value === "" ||
              (!isNaN(parseInt(ev.target.value)) &&
                /^[0-9]+$/.test(ev.target.value))
            ) {
              setStakeAmount(ev.target.value);

              if (
                parseInt(ev.target.value) + totalAmountPaid <
                parseInt(minAllocation)
              ) {
                setValidityMessage(
                  `Bought amount must be at least ${minAllocation}.`
                );
              } else if (
                parseInt(ev.target.value) + totalAmountPaid >
                parseInt(maxAllocation)
              ) {
                setValidityMessage(
                  `Bought amount cannot exceed ${maxAllocation}.`
                );
              } else {
                setValidityMessage(""); // Clear message when within range
              }
              if (
                parseInt(dollarTargetAmount!) - stableCoinAmountRaised <
                  parseInt(maxAllocation) &&
                parseInt(ev.target.value) >
                  parseInt(dollarTargetAmount!) - stableCoinAmountRaised
              ) {
                setIsAmountLimited(true);
              } else {
                setIsAmountLimited(false);
              }
              setStakeMessage("BUY");
            }
          }}
          disabled={isBuyDisabled || buyTokensState.status === "Mining"}
          endAdornment={
            <InputAdornment position="start">
              <Button
                color="primary"
                onClick={() => {
                  parseInt(dollarTargetAmount!) - stableCoinAmountRaised <
                  parseInt(maxAllocation)
                    ? setStakeAmount(
                        (
                          parseInt(dollarTargetAmount!) - stableCoinAmountRaised
                        ).toString()
                      )
                    : setStakeAmount(maxAllocation);
                  setStakeMessage("BUY");
                }}
                variant="contained"
                disabled={isBuyDisabled || buyTokensState.status === "Mining"}
                sx={{
                  filter: "none",
                  padding: "4px 8px 4px 8px",
                  borderRadius: "25px",
                  border: `1px solid ${borderBasicTone450}`,
                  width: "auto",
                  minWidth: 0,
                  minHeight: 0,
                }}
              >
                MAX
              </Button>
            </InputAdornment>
          }
          aria-describedby="outlined-weight-helper-text"
          inputProps={{
            "aria-label": "balance",

            style: { padding: "10px" },
          }}
        />
      </FormControl>
      <Grid item sx={{ width: "100%" }}>
        {showBuy() ? (
          <>
            {parseInt(dollarTargetAmount!) - stableCoinAmountRaised <
            parseInt(maxAllocation) ? (
              totalAmountPaid !== parseFloat(maxAllocation) ? (
                <Typography
                  variant="body2"
                  color={mainColor2}
                  sx={{ marginTop: "8px" }}
                  textAlign={"center"}
                >
                  *Available amount to buy is{" "}
                  {parseInt(dollarTargetAmount!) - stableCoinAmountRaised}
                </Typography>
              ) : parseInt(dollarTargetAmount!) === stableCoinAmountRaised ? (
                <Typography
                  variant="body2"
                  color={mainColor2}
                  sx={{ marginTop: "8px" }}
                  textAlign={"center"}
                >
                  *Target amount successfully raised {stableCoinAmountRaised}
                </Typography>
              ) : null
            ) : null}
            <LoadingButton
              fullWidth
              variant="contained"
              startIcon={<AccountBalanceWalletIcon />}
              onClick={() => {
                stakeAmount === "" || stakeAmount === "0"
                  ? setStakeMessage("Please enter a value")
                  : handleBuyTokens();
              }}
              sx={{ height: "48px", marginTop: "5px" }}
              loading={isBuyDisabled || buyTokensState.status === "Mining"}
              loadingPosition="start"
              disabled={
                totalAmountPaid === parseFloat(maxAllocation) ||
                validityMessage !== "" ||
                isAmountLimited ||
                stableCoinAmountRaised === parseInt(dollarTargetAmount!)
              }
            >
              <span>{stakeMessage}</span>
            </LoadingButton>
            {totalAmountPaid === parseFloat(maxAllocation) ? (
              <Typography
                variant="body2"
                color="secondary"
                sx={{ marginTop: "8px" }}
                textAlign={"center"}
              >
                *You have reached the maximum amount allocated.
              </Typography>
            ) : null}
            {validityMessage !== "" &&
            totalAmountPaid !== parseFloat(maxAllocation) ? (
              <Typography
                variant="body2"
                color="secondary"
                sx={{ marginTop: "8px" }}
                textAlign={"center"}
              >
                *{validityMessage}
              </Typography>
            ) : null}
          </>
        ) : (
          <LoadingButton
            fullWidth
            variant="contained"
            startIcon={<AccountBalanceWalletIcon />}
            onClick={() => handleApprove()}
            sx={{ height: "48px", marginTop: "5px" }}
            loading={approveState.status === "Mining"}
            loadingPosition="start"
          >
            APPROVE
          </LoadingButton>
        )}
      </Grid>
    </Grid>
  );
};
