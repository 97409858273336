import {
  Dialog,
  Typography,
  Stack,
  DialogTitle,
  DialogContent,
  OutlinedInput,
  FormControl,
  InputAdornment,
  FormHelperText,
  FormLabel,
  Select,
  MenuItem,
} from "@mui/material";

import { useCall, useContractFunction, useEthers } from "@usedapp/core";
import { Formik, Form } from "formik";
import { useContext, useEffect, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Web3Provider } from "@ethersproject/providers";

import {
  basicTone0,
  basicTone100,
  basicTone200,
  basicTone50,
  basicTone500,
  basicTone700,
  secondaryBorderRadius,
} from "../../../../../shared/styles/constants";
import { createBidOrderValidationSchema } from "./ordersModals.utils";
import { ThemeModeContext } from "../../../../../shared/providers/theme-mode-provider";
import { useContracts } from "../../../../../hooks";

import { NotificationsContext } from "../../../../../shared/providers/notifications-provider";
import BidOrderDialogActions from "./BidOrderDialogActions";
import { MarketplaceContext } from "../../../../../shared/providers/marketplace-provider";
import { payableTokensConfig } from "../../../../../config/config";

import BigNumber from "bignumber.js";

type CreateBidModalProps = {
  open: boolean;
  onClose: () => void;
};

export type CreateBidValues = {
  quantity: string;
  pricePerToken: string;
  fullPrice: string;
  payToken: string;
};

type PayableToken = {
  symbol: string;
  address: string;
};

export const CreateBidOrderModal = ({ open, onClose }: CreateBidModalProps) => {
  const { isLightMode } = useContext(ThemeModeContext);
  const { setNotification } = useContext(NotificationsContext);

  const { fundingTermsAddress, getMarketplaceAddressByChain } =
    useContext(MarketplaceContext);
  const [payableTokens, setPayableTokens] = useState<PayableToken[]>([]);
  const { chainId, library } = useEthers();

  const { marketplaceContract } = useContracts({
    marketplaceContractAddress: getMarketplaceAddressByChain(chainId),
  });

  const createBidInitialValues: CreateBidValues = {
    quantity: "",
    pricePerToken: "",
    fullPrice: "",
    payToken: payableTokens[0]?.address || "",
  };

  const { value: payableTokensResult, error: payableTokensError } =
    useCall(
      {
        contract: marketplaceContract,
        method: "getPayableTokens",
        args: [],
      },
      { chainId: chainId }
    ) ?? {};

  const { state: createBidState, send: createBid } = useContractFunction(
    marketplaceContract,
    "createBid",
    {
      transactionName: "CreateBid ",
    }
  );

  useEffect(() => {
    if (payableTokensResult && chainId) {
      // Flatten the array of arrays
      const flattenedTokenAddresses = payableTokensResult.flat();

      const payableTokensList = flattenedTokenAddresses.map(
        (tokenAddress: string) => {
          return {
            symbol: payableTokensConfig[chainId][tokenAddress].symbol,
            address: tokenAddress,
          };
        }
      );

      setPayableTokens(payableTokensList);
    }
  }, [payableTokensResult, payableTokensError, library]);

  useEffect(() => {
    if (createBidState?.status === "Success") {
      setNotification({
        message: "You successfully created a bid order!",
        type: "success",
      });
      onClose();
    }
  }, [createBidState]);

  const handleCreateBidOrder = async (values: CreateBidValues) => {
    if (chainId) {
      const quantity = new BigNumber(values.quantity)
        .multipliedBy(1e18)
        .toFixed(0);
      const fullPrice = new BigNumber(values.fullPrice)
        .multipliedBy(
          new BigNumber(10).pow(
            payableTokensConfig[chainId][values.payToken].decimals
          )
        )
        .toFixed(0);

      createBid(fundingTermsAddress, quantity, fullPrice, values.payToken);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
      sx={{
        backgroundColor: "initial",
        "& .MuiDialog-paper": {
          backgroundColor: isLightMode ? basicTone0 : basicTone700,
          padding: "16px 0",
          borderRadius: secondaryBorderRadius,
        },
      }}
      aria-labelledby="modal-create-bid-order"
      aria-describedby="modal-create-bid-order"
    >
      <DialogTitle sx={{ fontSize: "28px", fontWeight: 700 }} color="secondary">
        Create bid order
      </DialogTitle>
      <Formik
        initialValues={createBidInitialValues}
        onSubmit={handleCreateBidOrder}
        validationSchema={createBidOrderValidationSchema}
        validateOnBlur
      >
        {({
          values,
          handleChange,
          errors,
          handleSubmit,
          handleBlur,
          setFieldValue,
          touched,
          dirty,
        }) => {
          return (
            <>
              <DialogContent>
                <Form
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    width: "100%",
                  }}
                >
                  {/* QUANTITY */}
                  <Stack gap={1}>
                    <FormLabel
                      sx={{
                        color: isLightMode ? basicTone700 : basicTone0,
                        fontSize: "10px",
                        paddingLeft: "2px",
                        textTransform: "uppercase",
                        fontWeight: 600,
                        letterSpacing: "5%",
                        lineHeight: "12px",
                      }}
                    >
                      Quantity
                    </FormLabel>
                    <FormControl variant="outlined" fullWidth>
                      <OutlinedInput
                        fullWidth
                        type="text"
                        color="primary"
                        inputProps={{ min: 0 }}
                        name="quantity"
                        value={values.quantity}
                        onChange={(ev) => {
                          const value = ev.target.value;
                          if (
                            value === "" ||
                            (!isNaN(parseFloat(value)) &&
                              /^(?!0\d)\d*(\.\d*)?$/.test(value))
                          ) {
                            setFieldValue("quantity", value);

                            if (parseFloat(values.pricePerToken)) {
                              setTimeout(() => {
                                const newFullPrice =
                                  parseFloat(value) *
                                  parseFloat(values.pricePerToken);
                                setFieldValue(
                                  "fullPrice",
                                  isNaN(newFullPrice)
                                    ? "0"
                                    : newFullPrice.toString()
                                );
                              }, 0);
                            }
                          }
                        }}
                        onBlur={handleBlur}
                        error={!!errors?.quantity && touched?.quantity}
                        required
                        placeholder="0"
                        sx={{
                          border: "none",
                          borderRadius: "4px",
                          backgroundColor: isLightMode
                            ? basicTone50
                            : basicTone500,
                          "& input": {
                            padding: "10px 12px",
                          },
                        }}
                      />
                      {errors?.quantity && touched?.quantity ? (
                        <FormHelperText error>
                          *{errors.quantity}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  </Stack>
                  {/* PRICE PER TOKEN */}
                  <Stack gap={1}>
                    <FormLabel
                      sx={{
                        color: isLightMode ? basicTone700 : basicTone0,
                        fontSize: "10px",
                        paddingLeft: "2px",
                        textTransform: "uppercase",
                        fontWeight: 600,
                        letterSpacing: "5%",
                        lineHeight: "12px",
                      }}
                    >
                      Price per token
                    </FormLabel>
                    <FormControl variant="outlined" fullWidth>
                      <OutlinedInput
                        fullWidth
                        color="primary"
                        name="pricePerToken"
                        value={values.pricePerToken}
                        onChange={(ev) => {
                          const value = ev.target.value;
                          if (
                            value === "" ||
                            (!isNaN(parseFloat(value)) &&
                              /^(?!0\d)\d*(\.\d*)?$/.test(value))
                          ) {
                            setFieldValue("pricePerToken", value);

                            if (parseFloat(values.quantity)) {
                              setTimeout(() => {
                                const newFullPrice =
                                  parseFloat(values.quantity) *
                                  parseFloat(value);
                                setFieldValue(
                                  "fullPrice",
                                  isNaN(newFullPrice)
                                    ? "0"
                                    : newFullPrice.toString()
                                );
                              }, 0);
                            }
                          }
                        }}
                        onBlur={handleBlur}
                        type="text"
                        error={
                          !!errors?.pricePerToken && touched?.pricePerToken
                        }
                        required
                        placeholder="0"
                        inputProps={{ min: 0 }}
                        sx={{
                          border: "none",
                          borderRadius: "4px",
                          backgroundColor: isLightMode
                            ? basicTone50
                            : basicTone500,
                          "& input": {
                            padding: "10px 12px",
                          },
                        }}
                        endAdornment={
                          <InputAdornment
                            position="start"
                            sx={{
                              "& .MuiTypography-root": { color: basicTone200 },
                            }}
                          >
                            {` ${
                              payableTokens.find(
                                (token) => token.address === values.payToken
                              )?.symbol
                            }`}
                          </InputAdornment>
                        }
                      />
                      {errors?.pricePerToken && touched?.pricePerToken ? (
                        <FormHelperText error>
                          *{errors.pricePerToken}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  </Stack>
                  {/* FULL PRICE */}
                  <Stack gap={1}>
                    <FormLabel
                      sx={{
                        color: isLightMode ? basicTone700 : basicTone0,
                        fontSize: "10px",
                        paddingLeft: "2px",
                        textTransform: "uppercase",
                        fontWeight: 600,
                        letterSpacing: "5%",
                        lineHeight: "12px",
                      }}
                    >
                      Full price
                    </FormLabel>
                    <FormControl variant="outlined" fullWidth>
                      <OutlinedInput
                        fullWidth
                        color="primary"
                        type="text"
                        name="fullPrice"
                        inputProps={{ min: 0 }}
                        value={values.fullPrice}
                        onChange={(ev) => {
                          const value = ev.target.value;
                          if (
                            value === "" ||
                            (!isNaN(parseFloat(value)) &&
                              /^(?!0\d)\d*(\.\d*)?$/.test(value))
                          ) {
                            setFieldValue("fullPrice", value);

                            if (parseFloat(values.quantity)) {
                              setTimeout(() => {
                                const newPricePerToken =
                                  parseFloat(value) /
                                  parseFloat(values.quantity);
                                setFieldValue(
                                  "pricePerToken",
                                  isNaN(newPricePerToken)
                                    ? "0"
                                    : newPricePerToken.toString()
                                );
                              }, 0);
                            }
                          }
                        }}
                        onBlur={handleBlur}
                        error={!!errors?.fullPrice && touched?.fullPrice}
                        required
                        placeholder="0"
                        sx={{
                          border: "none",
                          borderRadius: "4px",
                          backgroundColor: isLightMode
                            ? basicTone50
                            : basicTone500,
                          "& input": {
                            padding: "10px 12px",
                          },
                        }}
                        endAdornment={
                          <InputAdornment
                            position="start"
                            sx={{
                              "& .MuiTypography-root": { color: basicTone200 },
                            }}
                          >
                            {` ${
                              payableTokens.find(
                                (token) => token.address === values.payToken
                              )?.symbol
                            }`}
                          </InputAdornment>
                        }
                      />
                      {errors?.fullPrice && touched?.fullPrice ? (
                        <FormHelperText error>
                          *{errors.fullPrice}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  </Stack>
                  {/* COIN DROPDOWN */}
                  <Stack gap={1}>
                    <FormLabel
                      sx={{
                        color: isLightMode ? basicTone700 : basicTone0,
                        fontSize: "10px",
                        paddingLeft: "2px",
                        textTransform: "uppercase",
                        fontWeight: 600,
                        letterSpacing: "5%",
                        lineHeight: "12px",
                      }}
                    >
                      Pay token
                    </FormLabel>
                    <Select
                      fullWidth
                      value={values.payToken}
                      onChange={(ev) => {
                        setFieldValue("payToken", ev.target.value);
                      }}
                      IconComponent={() => (
                        <ArrowDropDownIcon color="secondary" />
                      )}
                      sx={{
                        backgroundColor: isLightMode
                          ? basicTone50
                          : basicTone500,
                        paddingRight: "8px",
                        border: "none",
                        borderRadius: "4px",
                        "& .MuiSelect-select": {
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          padding: "16px 8px 16px 16px",
                        },
                      }}
                    >
                      {payableTokens.map((option) => (
                        <MenuItem
                          key={option.address}
                          value={option.address}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            padding: "16px 16px",
                            gap: 1,

                            backgroundColor: isLightMode
                              ? basicTone50
                              : basicTone500,
                            borderBottom: `1px solid ${
                              isLightMode ? basicTone100 : basicTone500
                            }`,
                            "&.Mui-selected": {
                              backgroundColor: isLightMode
                                ? basicTone50
                                : basicTone500,
                            },
                          }}
                        >
                          <Typography variant={"body2"} color="secondary">
                            {option.symbol}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>
                </Form>
              </DialogContent>
              <BidOrderDialogActions
                onClose={onClose}
                createBidLoading={
                  createBidState.status === "PendingSignature" ||
                  createBidState.status === "Mining"
                }
              />
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default CreateBidOrderModal;
