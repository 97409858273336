import { CssBaseline } from "@mui/material";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import * as dotenv from "dotenv";
import {
  Config,
  DAppProvider,
  Polygon,
  BSCTestnet,
  BSC,
  Mainnet,
  Chain,
  Arbitrum,
} from "@usedapp/core";

import { getDefaultProvider } from "ethers";
import { Network } from "@ethersproject/networks";

import { store } from "./app/store";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { ThemeModeProvider } from "./shared/providers/theme-mode-provider";
import AppWrapper from "./AppWrapper";

export const matic: Network = {
  name: "matic",
  chainId: 137,
  _defaultProvider: (providers: any) =>
    new providers.JsonRpcProvider(
      "https://polygon-mainnet.g.alchemy.com/v2/0Eijd1zkFBSX5TiU6i-_0W11HZRZ6I9b"
    ),
};

export const arbitrum: Network = {
  name: "arbitrum",
  chainId: 42161,
  _defaultProvider: (providers: any) =>
    new providers.JsonRpcProvider(
      "https://arb-mainnet.g.alchemy.com/v2/43XxfqmLsVqTlyK3Gb8dmfnuz2urAUUZ"
    ),
};

export const BlastTestNet: Network = {
  name: "Blast TestNet",
  chainId: 168587773,
  _defaultProvider: (providers: any) =>
    new providers.JsonRpcProvider("https://sepolia.blast.io"),
};

export const Blast: Chain = {
  chainId: 168587773,
  chainName: "Blast TestNet",
  isTestChain: true,
  isLocalChain: false,
  multicallAddress: "0xcA11bde05977b3631167028862bE2a173976CA11",
  rpcUrl: "https://sepolia.blast.io",
  blockExplorerUrl: "https://testnet.blastscan.io",
  nativeCurrency: {
    name: "Sepolia Ether",
    symbol: "SepoliaETH",
    decimals: 18,
  },
  getExplorerAddressLink: (address: string) =>
    `https://testnet.blastscan.io/address/${address}`,
  getExplorerTransactionLink: (transactionHash: string) =>
    `https://testnet.blastscan.io/tx/${transactionHash}`,
};

const config: Config = {
  networks: [Polygon, BSCTestnet, BSC, Mainnet, Blast, Arbitrum], // ADD this in production env:  BSC
  readOnlyChainId: Polygon.chainId,
  readOnlyUrls: {
    [Polygon.chainId]: getDefaultProvider(matic),
    [BSCTestnet.chainId]: "https://data-seed-prebsc-2-s1.binance.org:8545", //"https://data-seed-prebsc-1-s1.binance.org:8545",
    [BSC.chainId]: "https://bsc-dataseed1.binance.org",
    [Mainnet.chainId]:
      "https://eth-mainnet.g.alchemy.com/v2/VxT8WWlF47wk528CpaG1aQtp4DWF6QTj", // getDefaultProvider("mainnet"),
    [BlastTestNet.chainId]: getDefaultProvider(BlastTestNet),
    [Arbitrum.chainId]: getDefaultProvider(arbitrum),
  },
  notifications: {
    expirationPeriod: 1000,
    checkInterval: 5000,
  },
  // multicallVersion: 2 as const,
};

// const MMSDKOptions = {
//   dappMetadata: { name: "CollectivePad", url: "" },
//   checkInstallationImmediately: true,
//   preferDesktop: false,
//   injectProvider: true,
// };

// const MMSDK = new MetaMaskSDK(MMSDKOptions);
// export const ethereum = MMSDK.getProvider();

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <DAppProvider config={config}>
      <Provider store={store}>
        <ThemeModeProvider>
          <CssBaseline />
          <AppWrapper />
        </ThemeModeProvider>
      </Provider>
    </DAppProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
