import { Stack } from "@mui/material";

import { SupportedTokenCard } from "./SupportedTokenCard";
import { SupportedToken } from "../types";

import { mapSupportedTokensWithBalancesInitialValues } from "../utils";

export const SupportedTokensDifferentChain = ({
  supportedTokens,
  chainId,
}: {
  supportedTokens: SupportedToken[];
  chainId: number;
}) => {
  return (
    <Stack>
      {mapSupportedTokensWithBalancesInitialValues(supportedTokens).map(
        (supportedToken) => (
          <SupportedTokenCard key={supportedToken.id} {...supportedToken} />
        )
      )}
    </Stack>
  );
};

export default SupportedTokensDifferentChain;
