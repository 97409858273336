import * as Yup from "yup";
import { TOrder, TOrderResponseItem } from "../../../types";
import BigNumber from "bignumber.js";

import { payableTokensConfig } from "../../../../../config/config";

export const createBidOrderValidationSchema = Yup.object().shape({
  quantity: Yup.number()
    .positive("Quantity must be greater than zero")
    .required("Please enter tokens quantity."),
  fullPrice: Yup.number()
    .positive("Full price must be greater than zero")
    .required("Please add full price!"),
  pricePerToken: Yup.number()
    .positive("Price per token must be greater than zero")
    .required("Please add price per token!"),
  payToken: Yup.string().required("Pay token is required"),
});

export const createAskOrderValidationSchema = Yup.object().shape({
  quantity: Yup.number()
    .positive("Quantity must be greater than zero")
    .required("Please enter tokens quantity."),
  fullPrice: Yup.number()
    .positive("Full price must be greater than zero")
    .required("Please add full price!"),
  pricePerToken: Yup.number()
    .positive("Price per token must be greater than zero")
    .required("Please add price per token!"),
  payToken: Yup.string().required("Pay token is required"),
});

export const mapOrdersListResponse = (
  list: TOrderResponseItem[],
  chainId: number
): TOrder[] =>
  list.map((item) => ({
    id: item.id.toString(),
    quantity: new BigNumber(item.quantity.toString()).dividedBy(1e18).toFixed(),
    fullPrice: new BigNumber(item.fullPrice.toString())
      .dividedBy(10 ** payableTokensConfig[chainId][item.payToken].decimals)
      .toFixed(),
    pricePerToken: new BigNumber(item.pricePerToken.toString())
      .dividedBy(10 ** payableTokensConfig[chainId][item.payToken].decimals)
      .toFixed(),
    sold: item.sold,
    seller: item.seller,
    buyer: item.buyer,
    fundingTermsAddress: item.fundingTermsAddress,
    createdAt: item.createdAt.toString(),
    fulfilledAt: item.fulfilledAt.toString(),
    orderType: item.orderType,
    payToken: item.payToken,
  }));

export const shortFormatAddress = (address: string) => address.slice(-6);
