import { Grid, Typography } from "@mui/material";
import { IFeaturedProject } from "../../../../models/project.model";
import StopRoundedIcon from "@mui/icons-material/StopRounded";
import {
  basicTone200,
  secondaryColor1,
} from "../../../../shared/styles/constants";
import Stack from "@mui/material/Stack";
import { formatBigNumbersStrings, formatDate } from "../../../../shared/utils";
import Divider from "@mui/material/Divider";
import TeamCard from "../TeamCard";

const ValueWithLabel = ({
  label,
  value = "-",
  valPrefix = "",
  valSufix = "",
}: {
  label: string;
  value: string | number | undefined | null;
  valPrefix?: string;
  valSufix?: string;
}) => (
  <Grid item xs={6} sm={4} paddingBottom={2} sx={{ maxWidth: "100%" }}>
    <Stack gap={0.5}>
      <Typography variant="body2" color={basicTone200}>
        {label}
      </Typography>
      <Typography
        variant="h5"
        color={"secondary"}
        fontWeight={700}
        sx={{ maxWidth: "80%", overflowWrap: "break-word" }}
      >
        {`${valPrefix}${value}${valSufix}`}
      </Typography>
    </Stack>
  </Grid>
);

export const TeamContent = (projectData: IFeaturedProject) => {
  const {
    name,
    targetAmount,
    dollarTargetAmount,
    pricePerToken,
    minAllocation,
    maxAllocation,
    signUpDeadline,
    signUpStartDate,
    vestingDuration,
    vestingStartDate,
    initialMarketCap,
    initialTokenSupply,
    startRedeemPercentage,
  } = projectData.currentFundingRound;

  return (
    <Grid container gap={2} paddingTop={3}>
      <TeamCard teamMembers={projectData?.teamMembers} />
    </Grid>
  );
};
export default TeamContent;
