import {
  Button,
  Card,
  Grid,
  Stack,
  Typography,
  Link,
  IconButton,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ProjectInfoTabs from "./sections/ProjectInfoTabs";
import ProjectProgress from "./sections/ProjectProgress";
import {
  useGetFeaturedProjectByUrlNameQuery,
  useGetUserIsSignedUpToCurrentRoundMutation,
} from "../../services/featuredProjectsApi";
import { IFeaturedProject } from "../../models/project.model";
import SignUp from "./sections/SignUp";
import {
  defaultPhases,
  mapPhasesStatuses,
  TRoundPhase,
} from "./sections/utils";
import FundingCard from "./sections/funding-card/FundingCard";
import {
  basicTone0,
  basicTone100,
  basicTone450,
  basicTone500,
  basicTone700,
  borderBasicTone450,
} from "../../shared/styles/constants";

import { AccountContext } from "../../shared/providers/account-provider";
import DistributingCard from "./sections/DistributingCard";
import RoundSmallCard from "./sections/RoundSmallCard";
import { ThemeModeContext } from "../../shared/providers/theme-mode-provider";
import SignUpEnded from "./sections/SignUpEnded";
import DifferentChainCard from "./sections/DifferentChainCard";
import { GitBookIconSVG } from "../../assets/icons/IconGitBook";
import { WhitepaperIconSVG } from "../../assets/icons/foundation_downloadwhitepaperIcon";
import { fontMulish, fontPretendard } from "../../theme";
import Upcoming from "./sections/Upcoming";

import WaitingCard from "./sections/WaitingCard";

const states = {
  pending: "pending",
  funding: "funding",
  fundingReady: "fundingReady",
  signUp: "signUp",
  signUpEnded: "signUpEnded",
  waiting: "waiting",
  fundingEnded: "fundingEnded",
  distributing: "distributing",
  finished: "finished",
};

const AuthenticateCard = ({ title, content }: any) => {
  const navigate = useNavigate();
  const renderCardContent = (
    <Typography variant="body1" fontSize={"14px"} fontWeight={500}>
      To get involved in project staking please make sure you have completed the
      following requirements.
    </Typography>
  );
  return (
    <Grid
      container
      justifyContent={"flex-start"}
      gap={2}
      paddingTop={"36px"}
      paddingBottom={"36px"}
      flexDirection={"row"}
    >
      <Typography
        variant="h5"
        color="secondary"
        fontWeight={800}
        fontSize="16px"
      >
        {title}
      </Typography>
      {renderCardContent}
      <Grid
        item
        xs={12}
        display="flex"
        flexDirection={"column"}
        justifyContent="center"
        gap={1}
      >
        <Typography
          variant="body1"
          color="secondary"
          fontSize={"14px"}
          textAlign="center"
        >
          {content}
        </Typography>

        <Button
          fullWidth
          color="primary"
          variant="contained"
          onClick={() => {
            navigate("/register");
          }}
          sx={{ height: "48px", borderRadius: "8px" }}
        >
          Sign In
        </Button>
      </Grid>
    </Grid>
  );
};

const renderCardDependingOnProjectPhase = (
  account: string | undefined,
  isSignedUpToCurrentFundingRound: boolean,
  userMetamaskChainId: number | undefined,
  projectData?: IFeaturedProject
) => {
  const STABLE_COIN_ADDRESS =
    projectData?.currentFundingRound.stableCoinAddress;
  const FUNDING_CONTRACT_ADDRESS =
    projectData?.currentFundingRound.fundingContractAddress;
  const TOKEN_FOR_SALE_ADDRESS =
    projectData?.currentFundingRound.tokenForSaleAddress;
  const VESTING_CONTRACT_ADDRESS =
    projectData?.currentFundingRound.vestingContractAddress;
  const COIN_SYMBOL = projectData?.coinSymbol;

  switch (projectData?.currentFundingRound.status) {
    case states.pending: {
      return <Upcoming projectTitle={projectData?.name} />;
    }
    case states.signUp: {
      return (
        <SignUp
          isAlreadySignedUp={isSignedUpToCurrentFundingRound}
          fundingRoundId={projectData?.currentFundingRoundId?.toString()}
          projectTitle={projectData?.name}
          socialMedia={{
            discordUrl: projectData?.discordUrl,
            twitterUrl: projectData?.twitterUrl,
            telegramUrl: projectData?.telegramURl,
          }}
        />
      );
    }
    case states.signUpEnded: {
      return <SignUpEnded />;
    }
    case states.fundingReady: {
      return <SignUpEnded />;
    }
    case states.funding: {
      const isDifferentChain =
        userMetamaskChainId !==
        projectData?.currentFundingRound.fundingChain.chainId;
      if (isDifferentChain && account) {
        return (
          <DifferentChainCard
            projectChain={projectData?.currentFundingRound.fundingChain}
            title="Funding started"
          />
        );
      }
      return account ? (
        <FundingCard
          stableCoinAddress={STABLE_COIN_ADDRESS}
          fundingContractAddress={FUNDING_CONTRACT_ADDRESS}
          coinSymbol={COIN_SYMBOL}
          maxAllocation={projectData.currentFundingRound.maxAllocation}
          minAllocation={projectData.currentFundingRound.minAllocation}
          projectChain={projectData?.currentFundingRound.fundingChain}
          dollarTargetAmount={
            projectData?.currentFundingRound.dollarTargetAmount
          }
        />
      ) : (
        <AuthenticateCard
          title="Funding started"
          content="You need to be authenticated before funding."
        />
      );
    }

    case states.fundingEnded: {
      const isDifferentChain =
        userMetamaskChainId !==
        projectData?.currentFundingRound.fundingChain.chainId;
      if (isDifferentChain && account) {
        return (
          <DifferentChainCard
            projectChain={projectData?.currentFundingRound.fundingChain}
            title="Waiting status"
          />
        );
      }
      return account ? (
        <WaitingCard
          stableCoinAddress={STABLE_COIN_ADDRESS}
          fundingContractAddress={FUNDING_CONTRACT_ADDRESS}
          coinSymbol={COIN_SYMBOL}
          maxAllocation={projectData.currentFundingRound.maxAllocation}
          minAllocation={projectData.currentFundingRound.minAllocation}
          projectChain={projectData?.currentFundingRound.fundingChain}
          isAddedToMarketplace={
            projectData?.currentFundingRound.isAddedToMarketplace
          }
          vestingContractAddress={
            projectData?.currentFundingRound.vestingContractAddress
          }
        />
      ) : (
        <AuthenticateCard
          title="Waiting status"
          content="You need to be authenticated to view details."
        />
      );
    }
    case states.waiting: {
      const isDifferentChain =
        userMetamaskChainId !==
        projectData?.currentFundingRound.fundingChain.chainId;
      if (isDifferentChain && account) {
        return (
          <DifferentChainCard
            projectChain={projectData?.currentFundingRound.fundingChain}
            title="Waiting status"
          />
        );
      }
      return account ? (
        <WaitingCard
          stableCoinAddress={STABLE_COIN_ADDRESS}
          fundingContractAddress={FUNDING_CONTRACT_ADDRESS}
          coinSymbol={COIN_SYMBOL}
          maxAllocation={projectData.currentFundingRound.maxAllocation}
          minAllocation={projectData.currentFundingRound.minAllocation}
          projectChain={projectData?.currentFundingRound.fundingChain}
          isAddedToMarketplace={
            projectData?.currentFundingRound.isAddedToMarketplace
          }
          vestingContractAddress={
            projectData?.currentFundingRound.vestingContractAddress
          }
        />
      ) : (
        <AuthenticateCard
          title="Waiting status"
          content="You need to be authenticated to view details."
        />
      );
    }
    case states.distributing: {
      const isDifferentChain =
        userMetamaskChainId !==
        projectData?.currentFundingRound.vestingChain.chainId;
      if (isDifferentChain && account) {
        return (
          <DifferentChainCard
            projectChain={projectData?.currentFundingRound.vestingChain}
            title="Distributing stage"
          />
        );
      }
      return account ? (
        <DistributingCard
          tokenForSaleAddress={TOKEN_FOR_SALE_ADDRESS}
          vestingContractAddress={VESTING_CONTRACT_ADDRESS}
          maxAllocation={projectData.currentFundingRound.maxAllocation}
          minAllocation={projectData.currentFundingRound.minAllocation}
          projectChain={projectData?.currentFundingRound.vestingChain}
          status={projectData?.currentFundingRound.status}
          projectTitle={projectData?.name}
        />
      ) : (
        <AuthenticateCard
          title="Distributing started"
          content="You need to be authenticated before distributing."
        />
      );
    }
    case states.finished: {
      const isDifferentChain =
        userMetamaskChainId !==
        projectData?.currentFundingRound.vestingChain.chainId;
      if (isDifferentChain && account) {
        return (
          <DifferentChainCard
            projectChain={projectData?.currentFundingRound.vestingChain}
            title="Finished stage"
          />
        );
      }
      return account ? (
        <DistributingCard
          tokenForSaleAddress={TOKEN_FOR_SALE_ADDRESS}
          vestingContractAddress={VESTING_CONTRACT_ADDRESS}
          maxAllocation={projectData.currentFundingRound.maxAllocation}
          minAllocation={projectData.currentFundingRound.minAllocation}
          projectChain={projectData?.currentFundingRound.vestingChain}
          status={projectData?.currentFundingRound.status}
          projectTitle={projectData?.name}
        />
      ) : (
        <AuthenticateCard
          title="Finished"
          content="You need to be authenticated before finished."
        />
      );
    }
    default:
      return null;
  }
};

export const FeaturedProjectPage = () => {
  const navigate = useNavigate();
  const { isLightMode } = useContext(ThemeModeContext);
  const { account, handleDisconnect, userMetamaskChainId } =
    useContext(AccountContext);
  const { projectUrl = "" } = useParams();
  const [projectData, setProjectData] = React.useState<IFeaturedProject>();
  const [roundPhases, setRoundPhases] =
    React.useState<TRoundPhase[]>(defaultPhases);
  const [isSignedUpToCurrentFundingRound, setIsSignedUpToCurrentFundingRound] =
    React.useState(false);

  const [getIsUserSignedUp] = useGetUserIsSignedUpToCurrentRoundMutation();

  const { data, isSuccess, error } =
    useGetFeaturedProjectByUrlNameQuery(projectUrl);

  useEffect(() => {
    if (isSuccess && data.data) {
      setProjectData(data.data as IFeaturedProject);
      setRoundPhases(mapPhasesStatuses(data.data.currentFundingRound.status));
    }
    if (error) {
      navigate("/404");
    }
  }, [isSuccess, error]);
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (
      account &&
      projectData?.currentFundingRoundId &&
      !isSignedUpToCurrentFundingRound &&
      !!token
    ) {
      getIsUserSignedUp({
        currentFundingRoundId: projectData?.currentFundingRoundId,
        token,
      })
        .unwrap()
        .then((data: any) => {
          if (data?.data.isSignedUp) {
            setIsSignedUpToCurrentFundingRound(true);
          }
        })
        .catch((err: any) => {
          if (err?.status === 403 && account) {
            handleDisconnect();
            navigate("/register");
          }
        });
    }
  }, [
    account,
    isSignedUpToCurrentFundingRound,
    token,
    projectData?.currentFundingRoundId,
    getIsUserSignedUp,
  ]);

  return (
    data && (
      <div className="ProjectContent">
        <Grid
          container
          paddingTop={2}
          paddingBottom={2}
          gap={1.5}
          flexDirection={"column"}
        >
          <Card
            sx={
              {
                // // border: `1px solid ${basicTone0}`,
                // borderRadius: "12px",
                // boxShadow: `${
                //   isLightMode && "0px 8px 20px rgba(77, 91, 142, 0.1)"
                // }`,
              }
            }
          >
            <Grid
              container
              gap={1.5}
              justifyContent="space-between"
              flexWrap={{ md: "nowrap" }}
              sx={{
                height: { md: "723px", xs: "auto", sm: "auto" },
              }}
            >
              <Grid
                item
                container
                xs={12}
                md={6}
                padding={"12px"}
                borderRadius={"24px"}
              >
                <img
                  src={projectData?.coverUrl}
                  alt="banner"
                  style={{
                    width: "100%",
                    maxHeight: "699px",
                    objectFit: "cover",
                    borderRadius: "10px",
                    backgroundColor: basicTone450,
                  }}
                />
              </Grid>
              <Grid
                item
                container
                xs={12}
                md={6}
                alignItems={"center"}
                borderRadius={"24px"}
                justifyContent={"center"}
              >
                <Grid
                  container
                  sx={{ width: { md: "70%", xs: "90%", sm: "95%" } }}
                >
                  <Grid container gap={3.5}>
                    <Grid item container gap={2}>
                      <IconButton
                        component="a"
                        href={projectData?.currentFundingRound.onePagerUrl}
                        rel="noopener noreferrer"
                        target="_blank"
                        sx={{
                          borderRadius: "8px",
                          padding: "12px",
                          border: "1px solid #B983FF",
                        }}
                      >
                        <GitBookIconSVG />
                      </IconButton>
                      <Button
                        component="a"
                        href={projectData?.currentFundingRound.whitePaperUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Open Whitepaper"
                        variant="outlined"
                        sx={{
                          borderRadius: "8px",
                          gap: 1,
                          border: `1px solid ${borderBasicTone450}`,
                          color: basicTone700,
                          padding: "4px 12px 4px 4px",
                        }}
                      >
                        <WhitepaperIconSVG
                          color={isLightMode ? basicTone700 : basicTone0}
                        />
                        <Typography
                          fontFamily={fontPretendard}
                          fontWeight={600}
                          fontSize={"12px"}
                          lineHeight={"14.32px"}
                          color="secondary"
                        >
                          Download whitepaper
                        </Typography>
                      </Button>
                    </Grid>
                    <Stack gap={1}>
                      <Grid container>
                        <Typography
                          fontSize={"40px"}
                          fontFamily={fontPretendard}
                          fontWeight={700}
                          lineHeight={"40px"}
                          color="secondary"
                        >
                          {projectData?.name}
                        </Typography>
                      </Grid>
                      <Grid container>
                        {projectData?.websiteUrl && (
                          <Typography
                            fontFamily={fontMulish}
                            fontWeight={400}
                            sx={{ fontStyle: "italic" }}
                            fontSize={"14px"}
                            lineHeight={"16px"}
                            color={"#B983FF"}
                          >
                            by{" "}
                            <Link
                              color={"#B983FF"}
                              href={projectData?.websiteUrl}
                              underline="always"
                              target="_blank"
                              rel="noopener noreferrer"
                              fontFamily={fontMulish}
                              fontStyle={"normal"}
                              fontWeight={700}
                              lineHeight={"16px"}
                              fontSize={"14px"}
                            >
                              {projectData?.websiteUrl}
                            </Link>
                          </Typography>
                        )}
                      </Grid>
                    </Stack>
                  </Grid>

                  <Typography
                    fontFamily={fontPretendard}
                    fontWeight={400}
                    fontSize={"16px"}
                    lineHeight={"22px"}
                    color={isLightMode ? basicTone500 : basicTone100}
                    marginTop={"15px"}
                  >
                    {projectData?.projectOverview}
                  </Typography>

                  {projectData && <RoundSmallCard {...projectData} />}

                  <Grid
                    container
                    display={"flex"}
                    justifyContent={"center"}
                    alignContent={"center"}
                  >
                    {renderCardDependingOnProjectPhase(
                      account,
                      isSignedUpToCurrentFundingRound,
                      userMetamaskChainId,
                      projectData
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>

          <Grid
            item
            container
            xs={12}
            gap={1.5}
            justifyContent={{ md: "flex-start" }}
            flexDirection={{ md: "row-reverse" }}
            flexWrap={{ md: "nowrap" }}
          >
            {/* right side cards */}
            <Grid
              item
              container
              xs={12}
              md={3}
              borderRadius={"12px"}
              flexDirection="column"
            >
              <ProjectProgress phases={roundPhases} />
            </Grid>
            {/* left side cards */}
            <Grid item xs={12} md={9} borderRadius={"12px"}>
              {projectData && <ProjectInfoTabs {...projectData} />}
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  );
};

export default FeaturedProjectPage;
