import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import { TOrder } from '../../types'

import { Grid, Stack, Typography } from '@mui/material'
import {
  basicTone100,
  basicTone200,
  basicTone50,
  basicTone600,
  whiteTone,
} from '../../../../shared/styles/constants'
import { useContext } from 'react'
import { ThemeModeContext } from '../../../../shared/providers/theme-mode-provider'

export const ActivityChart = ({ activityList }: { activityList: TOrder[] }) => {
  const { isLightMode } = useContext(ThemeModeContext)

  const renderCustomXAxisTick = ({ x, y, payload }: any) => {
    const formatedDate = new Date(parseInt(payload.value) * 1000)

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor='end'
          fill='#666'
          fontSize={'12px'}
          letterSpacing={-0.5}
        >
          {formatedDate.toLocaleTimeString()}
        </text>
      </g>
    )
  }

  const renderCustomYAxisTick = ({ x, y, payload }: any) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor='center'
          fill='#666'
          fontSize={'12px'}
        >
          {payload.value}
        </text>
      </g>
    )
  }

  const CustomTooltip = ({
    payload,
    label,
    active,
    coordinate,
    viewBox,
  }: any) => {
    const positionX = viewBox ? viewBox.width : 0 // Calculate position only if viewBox is available

    return active ? (
      <div
        style={{
          position: 'absolute',
          top: `${coordinate.y}px`,
          left: `${positionX}px`, // Positioned on the right side of the chart
          width: '42px',
          height: '12px',
          background: `linear-gradient(93.88deg, #8161DC 0.5%, #CE44A8 99.7%)`,
          color: 'rgba(255, 255, 255, 1)', // White text color
          padding: '10px',
          borderRadius: '5px',
          boxShadow: '0px 0px 5px rgba(0,0,0,0.25)',
          pointerEvents: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000, // Ensure it's above all other chart components
        }}
      >
        <Stack sx={{ px: 2, py: 1 }} gap={0}>
          <Typography variant='h6' style={{ color: 'rgba(255, 255, 255, 1)' }}>
            {`$${payload[0].value}`}
          </Typography>
        </Stack>
      </div>
    ) : null
  }

  return activityList.length ? (
    <ResponsiveContainer width='100%' height={354}>
      <AreaChart
        width={500}
        height={200}
        data={activityList.sort(
          (a: TOrder, b: TOrder) =>
            parseInt(a.fulfilledAt!, 10) - parseInt(b.fulfilledAt!, 10)
        )}
        syncId='anyId'
        margin={{
          top: 10,
          right: 10,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid
          stroke={isLightMode ? basicTone50 : basicTone600}
          fill={isLightMode ? whiteTone : '#060606'}
        />
        <defs>
          <linearGradient id='colorPricePerToken' x1='0' y1='0' x2='0' y2='1'>
            <stop offset='-0.03%' stopColor='#7556CF' stopOpacity={0.18} />
            <stop offset='100%' stopColor='#D14EAC00' stopOpacity={0} />
          </linearGradient>
          <linearGradient id='stroke' x1='0' y1='0' x2='1' y2='0'>
            <stop offset='0.5%' stopColor='#8161DC' stopOpacity={1} />
            <stop offset='99.7%' stopColor='#CE44A8' stopOpacity={1} />
          </linearGradient>
        </defs>
        <XAxis dataKey='fulfilledAt' tick={renderCustomXAxisTick} />
        <YAxis
          orientation='right'
          tick={renderCustomYAxisTick}
          yAxisId={'right'}
        />
        <Tooltip
          content={<CustomTooltip />}
          cursor={{
            strokeDasharray: '3 3',
            stroke: isLightMode ? basicTone200 : '#F5F5F580',
          }}
        />
        <Area
          type='monotone'
          dataKey='pricePerToken'
          stroke='url(#stroke)'
          fillOpacity={1}
          fill='url(#colorPricePerToken)'
          yAxisId='right'
        />
      </AreaChart>
    </ResponsiveContainer>
  ) : (
    <Grid
      container
      width={'100%'}
      height='354px'
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography>No data</Typography>
    </Grid>
  )
}

export default ActivityChart
