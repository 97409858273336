import {
  Container,
  Stack,
  Typography,
  Grid,
  Box,
  Tabs,
  Tab,
  IconButton,
  Skeleton,
} from "@mui/material";
import React, { useContext, useState, useEffect } from "react";

import { TCoin } from "./types";

import TokensList from "./sections/left-sidebar/TokensList";

import {
  basicTone0,
  basicTone100,
  basicTone200,
  basicTone50,
  basicTone500,
  basicTone700,
  basicTone800,
  linearBlackBG,
  secondaryBorderRadius,
} from "../../shared/styles/constants";

import { ThemeModeContext } from "../../shared/providers/theme-mode-provider";
import { MarketplaceContext } from "../../shared/providers/marketplace-provider";

type TokensContainerProps = {
  onSelectToken: (value: TCoin) => void;
};

export const TokensContainer = ({ onSelectToken }: TokensContainerProps) => {
  const { isLightMode } = useContext(ThemeModeContext);
  const {
    selectedTokensTabValue,
    onSelectTokensTab,
    tokensList,
    myTokensList,
    isTokenListLoading,
  } = useContext(MarketplaceContext);

  const tabStyles = {
    width: "auto",
    backgroundColor: isLightMode ? basicTone50 : basicTone800,
    color: basicTone200,
    border: "none",
    fontSize: "12px",
    fontWeight: 600,
    minHeight: "32px",
    margin: "0 8px 8px 0",
    "&.Mui-selected": {
      background: "none",
      backgroundColor: isLightMode ? basicTone0 : basicTone500,
      border: "1px solid #A256C7",
      color: basicTone200,
    },
  };

  return (
    <Stack sx={{ width: "100%", gap: "4px" }}>
      {!isTokenListLoading ? (
        <>
          <Box sx={{ padding: "12px", display: "flex" }}>
            <Box
              sx={{
                backgroundColor: isLightMode ? basicTone50 : basicTone800,
                borderRadius: secondaryBorderRadius,
                padding: "10px",
                border: `1px solid ${basicTone500}`,
                height: "53px",
              }}
            >
              <Tabs
                value={selectedTokensTabValue}
                onChange={(_, newValue: number) => onSelectTokensTab(newValue)}
                aria-label="tokens-tabs"
                sx={{}}
              >
                <Tab
                  label="My tokens"
                  id="my-tokens-tab"
                  aria-controls="my-tokens-tabpanel"
                  sx={tabStyles}
                />
                <Tab
                  label="All tokens"
                  id="all-tokens-tab"
                  aria-controls="all-tokens-tabpanel"
                  sx={tabStyles}
                />
              </Tabs>
            </Box>
            {/* <IconButton sx={{ "&:hover": { background: "transparent" } }}>
          <SearchIconSVG width={"20"} height="20" />
        </IconButton> */}
          </Box>
          <div
            role="tabpanel"
            hidden={selectedTokensTabValue !== 0}
            id="my-tokens-tabpanel"
            aria-labelledby="my-tokens-tab"
          >
            {selectedTokensTabValue === 0 && (
              <TokensList
                tokensList={myTokensList}
                onSelectToken={onSelectToken}
              />
            )}
          </div>
          <div
            role="tabpanel"
            hidden={selectedTokensTabValue !== 1}
            id="all-tokens-tabpanel"
            aria-labelledby="all-tokens-tab"
          >
            {selectedTokensTabValue === 1 && (
              <TokensList
                tokensList={tokensList}
                onSelectToken={onSelectToken}
              />
            )}
          </div>
        </>
      ) : (
        <Stack gap={2} sx={{ p: 2 }}>
          <Box>
            <Skeleton
              variant="rounded"
              width={"75%"}
              height={50}
              sx={{
                background: !isLightMode ? basicTone50 : linearBlackBG,
                borderRadius: secondaryBorderRadius,
              }}
            />
          </Box>
          <Box>
            <Skeleton
              variant="rounded"
              width={"100%"}
              height={40}
              sx={{
                background: !isLightMode ? basicTone50 : linearBlackBG,
                borderRadius: secondaryBorderRadius,
              }}
            />
          </Box>
          <Box>
            <Skeleton
              variant="rounded"
              width={"100%"}
              height={40}
              sx={{
                background: !isLightMode ? basicTone50 : linearBlackBG,
                borderRadius: secondaryBorderRadius,
              }}
            />
          </Box>
          <Box>
            <Skeleton
              variant="rounded"
              width={"100%"}
              height={40}
              sx={{
                background: !isLightMode ? basicTone50 : linearBlackBG,
                borderRadius: secondaryBorderRadius,
              }}
            />
          </Box>
        </Stack>
      )}
    </Stack>
  );
};

export default TokensContainer;
