export const YoutubeIconSVG = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.33335 12.4993L12.6584 9.99935L8.33335 7.49935V12.4993ZM17.9667 5.97435C18.075 6.36602 18.15 6.89102 18.2 7.55768C18.2584 8.22435 18.2834 8.79935 18.2834 9.29935L18.3334 9.99935C18.3334 11.8243 18.2 13.166 17.9667 14.0243C17.7584 14.7743 17.275 15.2577 16.525 15.466C16.1334 15.5743 15.4167 15.6493 14.3167 15.6993C13.2334 15.7577 12.2417 15.7827 11.325 15.7827L10 15.8327C6.50835 15.8327 4.33335 15.6993 3.47502 15.466C2.72502 15.2577 2.24169 14.7743 2.03335 14.0243C1.92502 13.6327 1.85002 13.1077 1.80002 12.441C1.74169 11.7743 1.71669 11.1993 1.71669 10.6993L1.66669 9.99935C1.66669 8.17435 1.80002 6.83268 2.03335 5.97435C2.24169 5.22435 2.72502 4.74102 3.47502 4.53268C3.86669 4.42435 4.58335 4.34935 5.68335 4.29935C6.76669 4.24102 7.75835 4.21602 8.67502 4.21602L10 4.16602C13.4917 4.16602 15.6667 4.29935 16.525 4.53268C17.275 4.74102 17.7584 5.22435 17.9667 5.97435Z"
      fill="#777887"
    />
  </svg>
);
