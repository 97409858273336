import { Button, Card, Grid, Typography } from "@mui/material";
import { useState, useContext } from "react";
import {
  Arbitrum,
  BSC,
  BSCTestnet,
  ChainId,
  Polygon,
  useEthers,
} from "@usedapp/core";
import { AccountContext } from "../../../../shared/providers/account-provider";
import { IChain } from "../../../../models/project.model";
import { getChainNameById } from "../../../../shared/utils";

type DifferentChainCardProps = {
  selectedTokenChainId: number;
};

export const DifferentChainCard = ({
  selectedTokenChainId,
}: DifferentChainCardProps) => {
  const { switchNetwork } = useEthers();
  const [isSwitchNetworkDisabled, setIsSwitchNetworkDisabled] = useState(false);

  const { setUserMetamaskChainId, isConnectedToMetamask } =
    useContext(AccountContext);

  const handleSwitchNetwork = async () => {
    setIsSwitchNetworkDisabled(true);
    let toUpdateChainId = selectedTokenChainId;
    if (selectedTokenChainId && isConnectedToMetamask) {
      switch (selectedTokenChainId) {
        case BSCTestnet.chainId: {
          toUpdateChainId = BSCTestnet.chainId;
          break;
        }
        case Polygon.chainId: {
          toUpdateChainId = Polygon.chainId;
          break;
        }
        case BSC.chainId: {
          toUpdateChainId = BSC.chainId;
          break;
        }
        case Arbitrum.chainId: {
          toUpdateChainId = Arbitrum.chainId;
          break;
        }
        default: {
          break;
        }
      }
      if (toUpdateChainId) {
        await switchNetwork(toUpdateChainId)
          .then(() => {
            setUserMetamaskChainId(toUpdateChainId);
          })
          .catch((err) => console.log(err));
        setIsSwitchNetworkDisabled(false);
      }
    }
  };

  const renderCardContent = (
    <Typography variant="body1" fontSize={"14px"} fontWeight={500}>
      To get involved in project staking please make sure you have completed the
      following requirements.
    </Typography>
  );

  return (
    <Grid
      item
      container
      xs={12}
      display="flex"
      flexDirection={"column"}
      justifyContent="center"
      alignContent={"center"}
      gap={1}
    >
      <Typography
        variant="body1"
        textAlign={"center"}
        fontSize={"14px"}
        color="secondary"
        fontWeight={500}
      >
        {`This selected token is on a different chain. Please switch the chain if you want to create orders.`}
      </Typography>
      <Button
        color="primary"
        variant="contained"
        onClick={handleSwitchNetwork}
        sx={{
          height: "48px",
          borderRadius: "8px",
          width: "32%",
          alignSelf: "center",
        }}
        disabled={isSwitchNetworkDisabled}
      >
        Switch to {getChainNameById(selectedTokenChainId).toUpperCase()}
      </Button>
    </Grid>
  );
};

export default DifferentChainCard;
