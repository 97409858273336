import React, { useContext } from "react";
import { DashboardContext } from "../../../shared/providers/dashboard-provider";
import EditUserProfile from "./EditUserProfile";
import UserApplicationsCard from "./UserApplicationsCard";

export const RightSideContent = () => {
  const { showEditUserProfile } = useContext(DashboardContext);
  return showEditUserProfile ? <EditUserProfile /> : <UserApplicationsCard />;
};

export default RightSideContent;
