export const pages = [
  { page: "Dashboard", path: "/dashboard", redirectPath: "/register" },
  { page: "Marketplace", path: "/marketplace" },
  { page: "Community", path: "#" },
  { page: "Blog", path: "/blog", redirectPath: "/" },
  { page: "Contact", path: "/contact" },
];

export const communityUrls = {
  twitterURL: "https://twitter.com/CollectivePad",
  discordURL: "https://discord.gg/gR2HqEVbUT",
  mediumURL: "https://medium.com/@collectivepadfinance",
  telegramURL: "https://t.me/CollectivePad",
};
