import { Dialog, DialogTitle, DialogActions, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  basicTone50,
  linearBlackBG,
} from "../../../../../shared/styles/constants";
import { useContext } from "react";
import { ThemeModeContext } from "../../../../../shared/providers/theme-mode-provider";

type CancelOrderConfirmationDialogProps = {
  open: boolean;
  handleClose: () => void;
  orderType: "ASK" | "BID";
  handleCancelOrder: () => void;
  loadingCancel: boolean;
};
export const CancelOrderConfirmationDialog = ({
  handleClose,
  open,
  orderType,
  handleCancelOrder,
  loadingCancel,
}: CancelOrderConfirmationDialogProps) => {
  const { isLightMode } = useContext(ThemeModeContext);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ backgroundColor: `rgba(13, 13, 14, .2)` }}
      PaperProps={{ sx: { p: 3 } }}
    >
      <>
        <DialogTitle
          id="alert-dialog-title"
          typography={"h5"}
          color="secondary"
        >
          {`Are you sure you want to cancel this ${orderType.toLowerCase()} order?`}
        </DialogTitle>

        <DialogActions>
          <Button
            onClick={handleClose}
            variant="text"
            color="secondary"
            sx={{ height: "48px", borderRadius: "3px" }}
          >
            No
          </Button>
          <LoadingButton
            color="primary"
            variant="contained"
            autoFocus
            onClick={handleCancelOrder}
            loading={loadingCancel}
            sx={{
              height: "48px",
              borderRadius: "3px",
              minWidth: "100px",
              "& .MuiLoadingButton-loadingIndicator": {
                color: !isLightMode ? basicTone50 : linearBlackBG,
              },
            }}
          >
            <span>Yes</span>
          </LoadingButton>
        </DialogActions>
      </>
    </Dialog>
  );
};

export default CancelOrderConfirmationDialog;
