import React, { useContext } from "react";
import { Grid, Typography } from "@mui/material";
import { IFeaturedProject } from "../../../../models/project.model";
import StopRoundedIcon from "@mui/icons-material/StopRounded";
import {
  basicTone0,
  basicTone50,
  basicTone500,
  mainColor1,
  secondaryColor1,
} from "../../../../shared/styles/constants";
import Divider from "@mui/material/Divider";
import { ThemeModeContext } from "../../../../shared/providers/theme-mode-provider";
import { fontPretendard } from "../../../../theme";

export const ProjectOverviewContent = (projectData: IFeaturedProject) => {
  const { isLightMode } = useContext(ThemeModeContext);
  const { overview } = projectData.currentFundingRound;
  return (
    <Grid container gap={4} paddingTop={3}>
      <Grid item container alignItems="center" paddingTop={3}>
        <Typography
          fontFamily={fontPretendard}
          color="secondary"
          fontWeight={700}
          fontSize="32px"
          lineHeight={"38.19px"}
          letterSpacing={-0.02}
        >
          Overview
        </Typography>
      </Grid>
      <Grid container wrap="nowrap">
        <Typography
          fontFamily={fontPretendard}
          fontSize={"16px"}
          lineHeight={"24px"}
          color={isLightMode ? basicTone500 : basicTone50}
          fontWeight={400}
        >
          <span style={{ fontWeight: 700 }}>Product: </span>
          {overview.product}
        </Typography>
      </Grid>
      <Grid container wrap="nowrap">
        <Typography
          fontFamily={fontPretendard}
          fontSize={"16px"}
          lineHeight={"24px"}
          color={isLightMode ? basicTone500 : basicTone50}
          fontWeight={400}
        >
          <span style={{ fontWeight: 700 }}>Problem: </span>
          {overview.problem}
        </Typography>
      </Grid>
      <Grid container wrap="nowrap">
        <Typography
          fontFamily={fontPretendard}
          fontSize={"16px"}
          lineHeight={"24px"}
          color={isLightMode ? basicTone500 : basicTone50}
          fontWeight={400}
        >
          <span style={{ fontWeight: 700 }}>Solution: </span>
          {overview.solution}
        </Typography>
      </Grid>
      <Grid container wrap="nowrap">
        <Typography
          fontFamily={fontPretendard}
          fontSize={"16px"}
          lineHeight={"24px"}
          color={isLightMode ? basicTone500 : basicTone50}
          fontWeight={400}
        >
          <span style={{ fontWeight: 700 }}>Business model: </span>
          {overview.businessModel}
        </Typography>
      </Grid>
      <Grid container wrap="nowrap">
        <Typography
          fontFamily={fontPretendard}
          fontSize={"16px"}
          lineHeight={"24px"}
          color={isLightMode ? basicTone500 : basicTone50}
          fontWeight={400}
        >
          <span style={{ fontWeight: 700 }}>Market: </span>
          {overview.market}
        </Typography>
      </Grid>
      <Grid container wrap="nowrap">
        <Typography
          fontFamily={fontPretendard}
          fontSize={"16px"}
          lineHeight={"24px"}
          color={isLightMode ? basicTone500 : basicTone50}
          fontWeight={400}
        >
          <span style={{ fontWeight: 700 }}>Investors: </span>
          {overview.investors}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ProjectOverviewContent;
