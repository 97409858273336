import {
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Stack,
  Box,
  CircularProgress,
} from "@mui/material";
import { useCall, useContractFunction, useEthers } from "@usedapp/core";

import React, { useContext, useEffect, useState } from "react";

import {
  basicTone0,
  basicTone50,
  basicTone500,
  basicTone700,
  basicTone800,
  linearBlackBG,
  mainColor2,
  secondaryBorderRadius,
} from "../../../../../shared/styles/constants";

import { ThemeModeContext } from "../../../../../shared/providers/theme-mode-provider";
import { useContracts } from "../../../../../hooks";
import { NotificationsContext } from "../../../../../shared/providers/notifications-provider";

import { useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { STABLE_COIN_ADDRESS } from "../../../../../config/CoinAbi";
import { AccountContext } from "../../../../../shared/providers/account-provider";
import BigNumber from "bignumber.js";
import { MarketplaceContext } from "../../../../../shared/providers/marketplace-provider";

import { getDividedByChainId } from "../utils";

type BuyTokensModalProps = {
  open: boolean;
  onClose: () => void;
  saleId: string | undefined;
  payToken: string;
  fullPrice: string;
};

export const BuyTokensModal = ({
  open,
  onClose,
  saleId,
  fullPrice,
  payToken,
}: BuyTokensModalProps) => {
  const { isLightMode } = useContext(ThemeModeContext);
  const { setNotification } = useContext(NotificationsContext);
  const { account } = useContext(AccountContext);

  const [allowanceValue, setAllowanceValue] = useState<boolean>(false);
  const [payableTokenSymbol, setPayableTokenSymbol] = useState<string>("");
  const [payableTokenDecimals, setPayableTokenDecimals] = useState<
    number | null
  >(null);
  const [balance, setBallance] = useState<string>("");
  const { chainId } = useEthers();

  const { fundingTermsAddress, getMarketplaceAddressByChain } =
    useContext(MarketplaceContext);

  const { marketplaceContract, payableTokenContract } = useContracts({
    marketplaceContractAddress: getMarketplaceAddressByChain(chainId),
    stableCoinAddress: STABLE_COIN_ADDRESS,
    payableTokenAddress: payToken,
  });

  const { state: approveState, send: approve } = useContractFunction(
    payableTokenContract,
    "approve",
    {
      transactionName: "Approve",
    }
  );

  const { value: payableTokenSymbolResult, error: payableTokenSymbolError } =
    useCall({
      contract: payableTokenContract,
      method: "symbol",
      args: [],
    }) ?? {};

  const {
    value: payableTokenDecimalsResult,
    error: payableTokenDecimalsError,
  } =
    useCall({
      contract: payableTokenContract,
      method: "decimals",
      args: [],
    }) ?? {};

  const { value: allowance, error: allowanceError } =
    useCall({
      contract: payableTokenContract,
      method: "allowance",
      args: [account, getMarketplaceAddressByChain(chainId)],
    }) ?? {};

  const { value: balanceOf, error: balanceOfError } =
    useCall({
      contract: payableTokenContract,
      method: "balanceOf",
      args: [account],
    }) ?? {};

  useEffect(() => {
    payableTokenSymbolResult && setPayableTokenSymbol(payableTokenSymbolResult);
  }, [payableTokenSymbolResult]);

  useEffect(() => {
    payableTokenDecimalsResult &&
      setPayableTokenDecimals(payableTokenDecimalsResult);
  }, [payableTokenDecimalsResult]);

  useEffect(() => {
    if (allowance) {
      const val = new BigNumber(allowance.toString());

      setAllowanceValue(
        val.isGreaterThanOrEqualTo(new BigNumber(fullPrice.toString()))
      );
    }
  }, [allowance]);

  useEffect(() => {
    if (balanceOf && payableTokenDecimals) {
      const val = new BigNumber(balanceOf.toString())
        .dividedBy(10 ** payableTokenDecimals)
        .decimalPlaces(5)
        .toString();

      setBallance(val);
    }
  }, [balanceOf]);

  const handleApprove = () => {
    approve(getMarketplaceAddressByChain(chainId), fullPrice);
  };

  const { state: buyState, send: buy } = useContractFunction(
    marketplaceContract,
    "buy",
    {
      transactionName: "Buy ",
    }
  );

  useEffect(() => {
    if (buyState?.status === "Success") {
      setNotification({
        message: "You successfully bought tokens!",
        type: "success",
      });
      onClose();
    }
  }, [buyState]);

  const handleBuyTokens = () => {
    buy(fundingTermsAddress, saleId, payToken, fullPrice);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        backgroundColor: "initial",
        "& .MuiDialog-paper": {
          backgroundColor: isLightMode ? basicTone0 : basicTone700,
          padding: "16px 0",
          borderRadius: secondaryBorderRadius,
          minWidth: "300px",
        },
      }}
      aria-labelledby="modal-buy-tokens"
      aria-describedby="modal-buy-tokens"
    >
      <DialogTitle sx={{ fontSize: "28px", fontWeight: 700 }} color="secondary">
        Buy tokens{" "}
        {(!payableTokenDecimals ||
          !payableTokenSymbol ||
          balance.length <= 0) && <CircularProgress color="secondary" />}
      </DialogTitle>

      <DialogContent>
        <Stack gap={2}>
          <Stack>
            <Typography variant="body2" color="secondary">
              SALE ID
            </Typography>
            <Box
              sx={{
                backgroundColor: isLightMode ? basicTone50 : basicTone500,
                padding: 1.5,
                borderRadius: "4px",
              }}
            >
              <Typography variant="body2" color="secondary">
                {saleId}
              </Typography>
            </Box>
          </Stack>
          <Stack>
            <Typography variant="body2" color="secondary">
              PAY TOKEN
            </Typography>
            <Box
              sx={{
                backgroundColor: isLightMode ? basicTone50 : basicTone500,
                padding: 1.5,
                borderRadius: "4px",
              }}
            >
              <Typography variant="body2" color="secondary">
                {payableTokenSymbol}
              </Typography>
            </Box>
          </Stack>
          <Stack>
            <Typography variant="body2" color="secondary">
              FULL PRICE
            </Typography>
            <Box
              sx={{
                backgroundColor: isLightMode ? basicTone50 : basicTone500,
                padding: 1.5,
                borderRadius: "4px",
              }}
            >
              {fullPrice && payableTokenDecimals && (
                <Typography variant="body2" color="secondary">
                  {new BigNumber(fullPrice)
                    .dividedBy(10 ** payableTokenDecimals)
                    .toString()}
                </Typography>
              )}
            </Box>
          </Stack>
          <Stack>
            <Typography variant="body2" color="secondary">
              {payableTokenSymbol} BALANCE
            </Typography>
            <Box
              sx={{
                backgroundColor: isLightMode ? basicTone50 : basicTone500,
                padding: 1.5,
                borderRadius: "4px",
              }}
            >
              <Typography variant="body2" color="secondary">
                {balance}
              </Typography>
            </Box>
          </Stack>
          {payableTokenDecimals &&
          balance.length > 0 &&
          fullPrice &&
          new BigNumber(balance).isLessThan(
            new BigNumber(
              new BigNumber(fullPrice)
                .dividedBy(10 ** payableTokenDecimals)
                .toString()
            )
          ) ? (
            <Stack>
              <Typography variant="body2" color={mainColor2}>
                You do not have enough coins to fullfill this order.
              </Typography>
            </Stack>
          ) : null}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ padding: "24px" }}>
        <Button
          onClick={onClose}
          variant="text"
          sx={{ borderRadius: "3px", height: "39px" }}
        >
          Cancel
        </Button>
        {allowanceValue ? (
          <LoadingButton
            color="primary"
            variant="contained"
            fullWidth
            onClick={() => handleBuyTokens()}
            sx={{
              borderRadius: "3px",
              backgroundColor: "#5DB03E",
              "& .MuiLoadingButton-loadingIndicator": {
                color: !isLightMode ? basicTone50 : linearBlackBG,
              },
            }}
            loading={
              buyState.status === "PendingSignature" ||
              buyState.status === "Mining"
            }
            disabled={
              payableTokenDecimals &&
              balance.length > 0 &&
              fullPrice &&
              new BigNumber(balance).isGreaterThanOrEqualTo(
                new BigNumber(
                  new BigNumber(fullPrice)
                    .dividedBy(10 ** payableTokenDecimals)
                    .toString()
                )
              )
                ? false
                : true
            }
          >
            <span>Buy</span>
          </LoadingButton>
        ) : (
          <LoadingButton
            color="primary"
            variant="contained"
            fullWidth
            onClick={() => handleApprove()}
            sx={{
              borderRadius: "3px",
              color: basicTone800,
              background: "none",
              backgroundColor: "#5DB03E",
              "& .MuiLoadingButton-loadingIndicator": {
                color: !isLightMode ? basicTone50 : linearBlackBG,
              },
            }}
            loading={
              approveState.status === "PendingSignature" ||
              approveState.status === "Mining"
            }
            disabled={
              payableTokenDecimals &&
              balance.length > 0 &&
              fullPrice &&
              new BigNumber(balance).isGreaterThanOrEqualTo(
                new BigNumber(
                  new BigNumber(fullPrice)
                    .dividedBy(10 ** payableTokenDecimals)
                    .toString()
                )
              )
                ? false
                : true
            }
          >
            <span>Approve</span>
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default BuyTokensModal;
