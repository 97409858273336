import { Grid, Box, Tabs, Tab, Typography } from "@mui/material";
import React, { useContext, useState } from "react";

import {
  basicTone0,
  basicTone200,
  basicTone450,
  basicTone50,
  basicTone500,
  basicTone700,
} from "../../../../shared/styles/constants";
import { ThemeModeContext } from "../../../../shared/providers/theme-mode-provider";
import MyOrdersList from "./MyOrdersList";
import { MarketplaceContext } from "../../../../shared/providers/marketplace-provider";

export const TabsContainer = () => {
  const { isLightMode } = useContext(ThemeModeContext);
  const { selectedToken } = useContext(MarketplaceContext);
  const [selectedTokensTabValue, setSelectedTokensTabValue] =
    useState<number>(0);

  const tabStyles = {
    backgroundColor: "transparent",
    color: basicTone200,
    border: "none",
    fontSize: "13px",
    fontWeight: 600,
    height: "32px",
    minHeight: "auto",
    margin: "0",
    borderRadius: 0,
    "&.Mui-selected": {
      background: "none",
      backgroundColor: isLightMode ? basicTone50 : basicTone500,
      color: isLightMode ? basicTone500 : basicTone0,
    },
  };
  return (
    <Grid
      sx={{
        width: "100%",
        backgroundColor: isLightMode ? basicTone0 : basicTone700,
        height: "100%",
      }}
    >
      <Box sx={{}}>
        <Tabs
          value={selectedTokensTabValue}
          onChange={(_, newValue: number) =>
            setSelectedTokensTabValue(newValue)
          }
          aria-label="tokens-tabs"
          sx={{
            minHeight: "auto",
            "& .MuiTabs-flexContainer": {
              borderBottom: `1px solid ${
                isLightMode ? basicTone50 : basicTone450
              }`,
              height: "100%",
            },
            "& .MuiTabs-indicator": {
              backgroundColor: "#A256C7",
            },
          }}
        >
          <Tab
            label="My orders"
            id="my-orders-tab"
            aria-controls="my-orders-tabpanel"
            sx={tabStyles}
          />
        </Tabs>
      </Box>
      <div
        role="tabpanel"
        hidden={selectedTokensTabValue !== 0}
        id="my-orders-tabpanel"
        aria-labelledby="my-orders-tab"
      >
        {selectedTokensTabValue === 0 && <MyOrdersList />}
      </div>
    </Grid>
  );
};

export default TabsContainer;
