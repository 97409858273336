import {
  Button,
  ListItem,
  Grid,
  Stack,
  Typography,
  Skeleton,
  Box,
} from "@mui/material";
import { Polygon, useCall, useEthers } from "@usedapp/core";
import React, { useContext, useState, useEffect } from "react";

import { ThemeModeContext } from "../../../../shared/providers/theme-mode-provider";
import {
  basicTone0,
  basicTone100,
  basicTone50,
  basicTone500,
  linearBlackBG,
  secondaryBorderRadius,
} from "../../../../shared/styles/constants";
import { AccountContext } from "../../../../shared/providers/account-provider";
import { useContracts } from "../../../../hooks";

import BigNumber from "bignumber.js";
import { mapAskOrdersListData } from "./utils";
import { TAskOrder } from "../../types";
import BuyTokensModal from "./sellBuyModals/BuyTokensModal";
import { MarketplaceContext } from "../../../../shared/providers/marketplace-provider";

import { payableTokensConfig } from "../../../../config/config";

export const AskOrdersList = () => {
  const { isLightMode } = useContext(ThemeModeContext);
  const {
    selectedToken,
    tokenAddress,
    isTokenListLoading,
    tokensList,
    getMarketplaceAddressByChain,
  } = useContext(MarketplaceContext);
  const { account } = useContext(AccountContext);
  const [askOrders, setAskOrders] = useState<TAskOrder[]>([]);
  const [isListAskOrdersLoading, setIsListAskOrdersLoading] = useState(true);
  const [selectedAskOrder, setSelectedAskOrder] = useState<
    TAskOrder | undefined
  >(undefined);
  const { chainId } = useEthers();

  const { marketplaceContract } = useContracts({
    marketplaceContractAddress: getMarketplaceAddressByChain(
      selectedToken?.chainId
    ),
  });

  const {
    value: listAskOrdersPerTokenResult,
    error: listAskOrdersPerTokenError,
  } =
    useCall(
      selectedToken && {
        contract: marketplaceContract,
        method: "listAskOrdersPerToken",
        args: [tokenAddress],
      },
      { chainId: selectedToken?.chainId }
    ) ?? {};

  useEffect(() => {
    if (listAskOrdersPerTokenResult && selectedToken?.chainId) {
      setAskOrders(
        mapAskOrdersListData(
          listAskOrdersPerTokenResult[0],
          selectedToken.chainId
        )
      );
      setIsListAskOrdersLoading(false);
    } else if (!selectedToken && tokensList.length === 0) {
      setIsListAskOrdersLoading(false);
    }
  }, [listAskOrdersPerTokenResult, listAskOrdersPerTokenError, selectedToken]);

  return (
    <Stack gap={0.5} width={"100%"}>
      {isTokenListLoading || isListAskOrdersLoading ? (
        <Stack gap={2} sx={{ p: 2 }}>
          <Box>
            <Skeleton
              variant="rounded"
              width={"100%"}
              sx={{
                background: !isLightMode ? basicTone50 : linearBlackBG,
                borderRadius: secondaryBorderRadius,
              }}
            />
          </Box>
          <Box>
            <Skeleton
              variant="rounded"
              width={"100%"}
              sx={{
                background: !isLightMode ? basicTone50 : linearBlackBG,
                borderRadius: secondaryBorderRadius,
              }}
            />
          </Box>
          <Box>
            <Skeleton
              variant="rounded"
              width={"100%"}
              sx={{
                background: !isLightMode ? basicTone50 : linearBlackBG,
                borderRadius: secondaryBorderRadius,
              }}
            />
          </Box>
        </Stack>
      ) : (
        askOrders.map((item, index) => (
          <ListItem
            key={`${item.id.toString()}${index}`}
            sx={{
              padding: "2px 12px",
              "&:hover": {
                background: isLightMode ? basicTone50 : basicTone500,
              },
            }}
          >
            <Grid container alignItems={"center"}>
              <Grid item xs={4}>
                <Typography variant="body2" fontWeight={500} color={"#5DB03E"}>
                  {`$${item.pricePerToken}`}
                </Typography>
              </Grid>
              <Grid item xs={3} gap={1} container alignItems={"center"}>
                <Typography variant="body2" fontWeight={400} color="secondary">
                  {`${item.quantity.toString()}`}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  variant="body2"
                  fontWeight={400}
                  color={"secondary"}
                >
                  {`$${item.fullPrice}`}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                {selectedToken?.chainId === chainId && account && (
                  <Button
                    variant="text"
                    color="info"
                    onClick={async () => {
                      setSelectedAskOrder(item);
                    }}
                    sx={{
                      padding: "2px",
                      fontSize: "12px",
                      textDecoration: "underline",
                    }}
                  >
                    BUY
                  </Button>
                )}
              </Grid>
            </Grid>
          </ListItem>
        ))
      )}
      {selectedAskOrder && selectedToken?.chainId && (
        <BuyTokensModal
          open={!!selectedAskOrder}
          onClose={() => setSelectedAskOrder(undefined)}
          saleId={selectedAskOrder?.id}
          payToken={selectedAskOrder.payToken}
          fullPrice={new BigNumber(selectedAskOrder?.fullPrice.toString())
            .multipliedBy(
              10 **
                payableTokensConfig[selectedToken.chainId][
                  selectedAskOrder.payToken
                ].decimals
            )
            .toString()}
        />
      )}
    </Stack>
  );
};

export default AskOrdersList;
