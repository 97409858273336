export const TwitterIconSVG = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.9186 4.9677C17.321 5.23626 16.6706 5.42953 16.0007 5.50481C16.6962 5.07438 17.2171 4.39438 17.4655 3.59238C16.8128 3.99681 16.0978 4.28041 15.3522 4.43059C15.0406 4.08356 14.6637 3.8071 14.245 3.61843C13.8263 3.42977 13.3748 3.33294 12.9186 3.33399C11.0729 3.33399 9.58856 4.89242 9.58856 6.80486C9.58856 7.07342 9.61981 7.34197 9.67059 7.60035C6.90692 7.4498 4.44208 6.07447 2.80341 3.96876C2.50482 4.5 2.34835 5.10488 2.35028 5.72047C2.35028 6.9249 2.93817 7.98691 3.83466 8.6115C3.30634 8.58983 2.7904 8.43857 2.3288 8.17001V8.21274C2.3288 9.89934 3.47333 11.2971 4.99872 11.6185C4.71231 11.696 4.41768 11.7356 4.12177 11.7365C3.90497 11.7365 3.69989 11.7141 3.49286 11.6836C3.91473 13.0589 5.14325 14.0579 6.60614 14.0904C5.46161 15.0243 4.02802 15.5736 2.47137 15.5736C2.19208 15.5736 1.93427 15.5634 1.66669 15.5309C3.14325 16.5176 4.8952 17.0873 6.78192 17.0873C12.9069 17.0873 16.2585 11.8016 16.2585 7.2138C16.2585 7.06324 16.2585 6.91269 16.2487 6.76214C16.8972 6.26775 17.4655 5.65536 17.9186 4.9677Z"
      fill="#777887"
    />
  </svg>
);
