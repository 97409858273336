import { IProjectRound } from "../../../models/project.model";
import { AvailableChains } from "../../../shared/utils";

export type TRoundPhase = {
  id: number;
  status: string[];
  title: string;
  completed?: boolean;
  active?: boolean;
  label: string;
  description?: string;
};

export type TeamMember = {
  createdAt: string;
  description: string;
  featuredProjectId: number;
  id: number;
  linkedInUrl: string;
  name: string;
  photoUrl: string;
  position: string;
  telegramUrl: string;
  twitterUrl: string;
  updatedAt: string;
};

export type StepperRound = IProjectRound & {
  isActive: boolean;
  isCompleted: boolean;
};

export const defaultPhases: TRoundPhase[] = [
  {
    id: 0,
    title: "Waiting list",
    label: "Waiting list",
    status: ["pending", "signUp", "signUpEnded", "fundingReady"],
    completed: false,
  },
  {
    id: 1,
    title: "Funding",
    label: "Funding",
    status: ["funding"],
    completed: false,
  },
  {
    id: 2,
    title: "Awaiting TGE",
    label: "Awaiting TGE",
    status: ["fundingEnded", "waiting"],
    completed: false,
  },
  {
    id: 3,
    title: "Distributing/Vesting",
    label: "Distributing/Vesting",
    status: ["distributing"],
    completed: false,
  },
];
export const mapPhasesStatuses = (currentFundingRoundStatus?: string) => {
  const currentPhaseIndex = defaultPhases.findIndex((phase) =>
    phase.status.find((status: string) => status === currentFundingRoundStatus)
  );

  return defaultPhases.map((phase, index) => ({
    ...phase,
    completed: index < currentPhaseIndex,
    active: index === currentPhaseIndex,
  }));
};

export const mapRoundsList = (
  curentFundinRoundId?: number,
  defaultRounds?: IProjectRound[]
): StepperRound[] => {
  const allRounds = defaultRounds
    ? [...defaultRounds, { id: "futureRound", name: "Future" }]
    : [];
  const curentFundinRoundIndex = allRounds.findIndex(
    (r) => r.id === curentFundinRoundId
  );
  const mappedRounds = allRounds.map((r, i) => {
    return {
      ...r,
      isActive: i === curentFundinRoundIndex,
      isCompleted: i <= curentFundinRoundIndex,
    };
  });

  return mappedRounds;
};

export const getDividedByChainId = (chainId: number | undefined) => {
  if (
    chainId === AvailableChains.BinanceTestNet ||
    chainId === AvailableChains.BinanceMainnet
  ) {
    return 1e18;
  }
  return 1e6;
};
