import { Button, DialogActions } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import BigNumber from "bignumber.js";

import { useCall, useContractFunction } from "@usedapp/core";
import { useFormikContext } from "formik";
import React, { useContext, useState, useEffect } from "react";

import { useContracts } from "../../../hooks";

import { AccountContext } from "../../../shared/providers/account-provider";
import { STABLE_COIN_ADDRESS } from "../../../config/CoinAbi";
import { CreateBidValues } from "./CreateBidOrderModal";
import { MarketplaceContext } from "../../../shared/providers/marketplace-provider";

export const BidOrderDialogActions = ({
  onClose,
  createBidLoading,
}: {
  onClose: () => void;
  createBidLoading: boolean;
}) => {
  const { values, handleSubmit } = useFormikContext<CreateBidValues>();
  const { account } = useContext(AccountContext);
  const { marketplaceAddresses } = useContext(MarketplaceContext);
  const [allowanceValue, setAllowanceValue] = useState<boolean | undefined>(
    undefined
  );

  const { stableCoinContract } = useContracts({
    stableCoinAddress: STABLE_COIN_ADDRESS,
  });

  const { state: approveState, send: approve } = useContractFunction(
    stableCoinContract,
    "approve",
    {
      transactionName: "Approve",
    }
  );

  const { value: allowance, error: allowanceError } =
    useCall({
      contract: stableCoinContract,
      method: "allowance",
      args: [account, marketplaceAddresses?.marketplaceMaticAddress],
    }) ?? {};

  useEffect(() => {
    if (allowance) {
      const val = new BigNumber(allowance.toString());

      setAllowanceValue(
        val.isGreaterThanOrEqualTo(
          new BigNumber((values.fullPrice * 1e6).toString())
        )
      );
    }
  }, [allowance, values.fullPrice]);

  const handleApprove = (fullPrice: number) => {
    approve(marketplaceAddresses?.marketplaceMaticAddress, fullPrice);
  };

  return (
    <DialogActions>
      <Button onClick={onClose} variant="text">
        Cancel
      </Button>
      {allowanceValue ? (
        <LoadingButton
          type="submit"
          color="primary"
          variant="contained"
          onClick={() => handleSubmit()}
          loading={createBidLoading}
        >
          <span>Create order</span>
        </LoadingButton>
      ) : (
        <LoadingButton
          color="primary"
          variant="contained"
          onClick={() => handleApprove(values.fullPrice * 1e6)}
          loading={
            approveState.status === "Mining" ||
            approveState.status === "PendingSignature"
          }
        >
          <span>Approve</span>
        </LoadingButton>
      )}
    </DialogActions>
  );
};

export default BidOrderDialogActions;
