import { Button, DialogActions } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import BigNumber from "bignumber.js";

import { useCall, useContractFunction, useEthers } from "@usedapp/core";
import { useFormikContext } from "formik";
import { useContext, useState, useEffect } from "react";

import { useContracts } from "../../../../../hooks";

import { AccountContext } from "../../../../../shared/providers/account-provider";

import { CreateBidValues } from "./CreateBidOrderModal";
import { ethers } from "ethers";

import { MarketplaceContext } from "../../../../../shared/providers/marketplace-provider";
import { ThemeModeContext } from "../../../../../shared/providers/theme-mode-provider";
import {
  basicTone0,
  basicTone50,
  basicTone500,
  linearBlackBG,
} from "../../../../../shared/styles/constants";

export const BidOrderDialogActions = ({
  onClose,
  createBidLoading,
}: {
  onClose: () => void;
  createBidLoading: boolean;
}) => {
  const { isLightMode } = useContext(ThemeModeContext);
  const { getMarketplaceAddressByChain } = useContext(MarketplaceContext);
  const { values, handleSubmit } = useFormikContext<CreateBidValues>();
  const { account } = useContext(AccountContext);
  const [allowanceValue, setAllowanceValue] = useState<boolean | undefined>(
    undefined
  );
  const [payableTokenDecimals, setPayableTokenDecimals] = useState<
    number | null
  >(null);
  const { chainId } = useEthers();

  const { payableTokenContract } = useContracts({
    payableTokenAddress: values.payToken,
  });

  const { state: approveState, send: approve } = useContractFunction(
    payableTokenContract,
    "approve",
    {
      transactionName: "Approve",
    }
  );

  const {
    value: payableTokenDecimalsResult,
    error: payableTokenDecimalsError,
  } =
    useCall({
      contract: payableTokenContract,
      method: "decimals",
      args: [],
    }) ?? {};

  const { value: allowance, error: allowanceError } =
    useCall({
      contract: payableTokenContract,
      method: "allowance",
      args: [account, getMarketplaceAddressByChain(chainId)],
    }) ?? {};

  useEffect(() => {
    if (allowance) {
      const val = new BigNumber(allowance.toString());

      payableTokenDecimals &&
        values.fullPrice !== "" &&
        setAllowanceValue(
          val.isGreaterThanOrEqualTo(
            new BigNumber(
              (
                parseFloat(values.fullPrice) *
                10 ** payableTokenDecimals
              ).toString()
            )
          )
        );
    }
  }, [allowance, values.fullPrice]);

  useEffect(() => {
    payableTokenDecimalsResult &&
      setPayableTokenDecimals(payableTokenDecimalsResult);
  }, [payableTokenDecimalsResult]);

  const handleApprove = (fullPrice: number) => {
    // Accessing MaxUint256 constant
    const maxUint256 = ethers.constants.MaxUint256;

    approve(getMarketplaceAddressByChain(chainId), maxUint256);
  };

  return (
    <DialogActions sx={{ flexDirection: "column", px: "24px", gap: 1 }}>
      {allowanceValue ? (
        <LoadingButton
          type="submit"
          color="primary"
          variant="contained"
          onClick={() =>
            values.pricePerToken !== "" &&
            values.quantity !== "" &&
            handleSubmit()
          }
          loading={createBidLoading}
          fullWidth
          sx={{
            height: "48px",
            borderRadius: "3px",
            background: "#5DB03E",
            "& .MuiLoadingButton-loadingIndicator": {
              color: !isLightMode ? basicTone50 : linearBlackBG,
            },
          }}
          disabled={
            values.fullPrice === "" ||
            values.pricePerToken === "" ||
            values.quantity === "" ||
            parseFloat(values.pricePerToken) <= 0 ||
            parseFloat(values.quantity) <= 0 ||
            parseFloat(values.fullPrice) <= 0
              ? true
              : false
          }
        >
          <span>Create order</span>
        </LoadingButton>
      ) : (
        <LoadingButton
          color="primary"
          variant="contained"
          onClick={() =>
            payableTokenDecimals &&
            values.fullPrice !== "" &&
            handleApprove(
              parseFloat(values.fullPrice) * 10 ** payableTokenDecimals
            )
          }
          loading={
            approveState.status === "Mining" ||
            approveState.status === "PendingSignature"
          }
          fullWidth
          sx={{
            height: "48px",
            borderRadius: "3px",
            "& .MuiLoadingButton-loadingIndicator": {
              color: !isLightMode ? basicTone50 : linearBlackBG,
            },
          }}
          disabled={
            values.fullPrice === "" ||
            values.pricePerToken === "" ||
            values.quantity === "" ||
            parseFloat(values.pricePerToken) <= 0 ||
            parseFloat(values.quantity) <= 0 ||
            parseFloat(values.fullPrice) <= 0
              ? true
              : false
          }
        >
          <span>Approve</span>
        </LoadingButton>
      )}
      <Button
        onClick={onClose}
        variant="text"
        fullWidth
        sx={{
          height: "48px",
          borderRadius: "3px",
          marginLeft: "0 !important",
        }}
      >
        Cancel
      </Button>
    </DialogActions>
  );
};

export default BidOrderDialogActions;
