import { Card, Grid, Typography } from "@mui/material";
import { useContext } from "react";
import { TRoundPhase } from "./utils";
import {
  basicTone100,
  basicTone200,
  borderBasicTone450,
} from "../../../shared/styles/constants";
import { ThemeModeContext } from "../../../shared/providers/theme-mode-provider";
import { CompletedStepIconSVG } from "../../../assets/icons/CompletedRoundIcon";
import { ActiveStepIconSVG } from "../../../assets/icons/ActiveRoundIcon";
import { InactiveStepIconSVG } from "../../../assets/icons/InactiveRoundIcon";
import { fontPretendard } from "../../../theme";
interface ProjectProgressProps {
  phases: TRoundPhase[];
}

export const ProjectProgress = ({ phases }: ProjectProgressProps) => {
  const { isLightMode } = useContext(ThemeModeContext);
  return (
    <Card
      sx={{
        height: "477px",
        borderRadius: "12px",
        // border: `1px solid ${borderBasicTone450}`,
      }}
    >
      <Grid
        container
        display={"flex"}
        flexDirection={"column"}
        flexWrap={{ md: "nowrap" }}
      >
        {phases.map((step) => (
          <Grid
            item
            container
            xs={12}
            key={step.id}
            justifyContent="space-between"
            alignItems={"center"}
            padding={"16px"}
            sx={{
              borderBottomColor: `${
                isLightMode ? basicTone100 : borderBasicTone450
              }`,
              borderBottom: `1px solid `,
            }}
          >
            <Grid item display={"flex"} flexDirection={"column"} gap={0.5}>
              <Typography
                fontFamily={fontPretendard}
                fontWeight={600}
                fontSize={"14px"}
                lineHeight={"16.71px"}
                color={
                  !step.active && !step.completed ? basicTone200 : "secondary"
                }
              >
                {step.title}
              </Typography>
              <Typography
                fontFamily={fontPretendard}
                fontWeight={500}
                fontSize={"12px"}
                lineHeight={"14.32px"}
                color={
                  step.completed
                    ? "#5DB03E"
                    : step.active
                    ? "secondary"
                    : basicTone200
                }
              >
                {step.completed && "100%"}
                {step.active && "50%"}
                {!step.completed && !step.active && "0%"}
              </Typography>
            </Grid>
            <Grid item>
              {step.completed && !step.active && <CompletedStepIconSVG />}
              {step.active && <ActiveStepIconSVG />}
              {!step.active && !step.completed && <InactiveStepIconSVG />}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Card>
  );
};

export default ProjectProgress;
