import React, { useContext, useState } from "react";
import { IconButton, Menu, MenuItem, Typography } from "@mui/material";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import PersonIcon from "@mui/icons-material/Person";

import {
  basicTone0,
  basicTone100,
  basicTone400,
  basicTone450,
  basicTone50,
  basicTone500,
  basicTone700,
  mainBorderRadius,
} from "../shared/styles/constants";
import { useNavigate } from "react-router-dom";
import { AccountContext } from "../shared/providers/account-provider";
import OutlinedPrimaryCustomButton from "./OutlinedPrimaryCustomButton/OutlinedPrimaryCustomButton";
import { LogoutIconSVG } from "../assets/icons/LogoutIconSVG";
import { ThemeModeContext } from "../shared/providers/theme-mode-provider";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Arbitrum,
  BSC,
  BSCTestnet,
  Mainnet,
  Polygon,
  useEthers,
} from "@usedapp/core";
import { getChainLogoById, getChainNameById } from "../shared/utils";
import { config } from "../config/config";
import { BlastTestNet } from "..";

export const SignInButton = ({
  dark = true,
  handleOpenNavMenu,
}: {
  dark?: boolean;
  handleOpenNavMenu?: () => void;
}) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { isLightMode } = useContext(ThemeModeContext);
  const { switchNetwork, activateBrowserWallet } = useEthers();
  const {
    account,
    handleDisconnect,
    setUserMetamaskChainId,
    userMetamaskChainId,
    isConnectedToMetamask,
  } = useContext(AccountContext);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = async (chainOption: number) => {
    await switchNetwork(chainOption)
      .then(() => {
        setUserMetamaskChainId(chainOption);
      })
      .catch((err) => {
        console.log(err);
        activateBrowserWallet(); // needed?
      });
    handleCloseMenu();
  };

  const handleOpenChainsMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return account ? (
    <>
      <OutlinedPrimaryCustomButton
        dark={dark}
        text="My account"
        onClick={() => {
          navigate("/my-wallet");
          handleOpenNavMenu && handleOpenNavMenu();
        }}
        StartIcon={<PersonIcon />}
      />
      {account && isConnectedToMetamask && (
        <>
          <OutlinedPrimaryCustomButton
            dark={dark}
            text={getChainNameById(userMetamaskChainId)}
            onClick={handleOpenChainsMenu}
            endIcon={<KeyboardArrowDownIcon />}
            startImg={getChainLogoById(userMetamaskChainId)}
          />
          <Menu
            id="chain-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseMenu}
            MenuListProps={{
              "aria-labelledby": "lock-button",
              role: "listbox",
            }}
            PaperProps={{
              sx: { backgroundColor: dark ? basicTone700 : undefined },
            }}
          >
            <MenuItem
              disabled={userMetamaskChainId === Polygon.chainId}
              selected={userMetamaskChainId === Polygon.chainId}
              onClick={() => handleMenuItemClick(Polygon.chainId)}
              sx={{
                color: isLightMode && !dark ? basicTone400 : basicTone0,
                display: "flex",
                gap: "8px",
                alignItems: "center",
              }}
            >
              <img
                src={getChainLogoById(Polygon.chainId)}
                alt="PolygonLogoUrl"
                style={{
                  height: "24px",
                  width: "24px",
                  borderRadius: "50%",
                }}
              />
              {Polygon.chainName}
            </MenuItem>

            <MenuItem
              disabled={userMetamaskChainId === Arbitrum.chainId}
              selected={userMetamaskChainId === Arbitrum.chainId}
              onClick={() => handleMenuItemClick(Arbitrum.chainId)}
              sx={{
                color: isLightMode && !dark ? basicTone400 : basicTone0,
                display: "flex",
                gap: "8px",
                alignItems: "center",
              }}
            >
              <img
                src={getChainLogoById(Arbitrum.chainId)}
                alt="ArbitrumLogoUrl"
                style={{
                  height: "24px",
                  width: "24px",
                  borderRadius: "50%",
                }}
              />
              {Arbitrum.chainName}
            </MenuItem>

            <MenuItem
              disabled={userMetamaskChainId === Mainnet.chainId}
              selected={userMetamaskChainId === Mainnet.chainId}
              onClick={() => handleMenuItemClick(Mainnet.chainId)}
              sx={{
                color: isLightMode && !dark ? basicTone400 : basicTone0,
                display: "flex",
                gap: "8px",
                alignItems: "center",
              }}
            >
              <img
                src={getChainLogoById(Mainnet.chainId)}
                alt="MainnetLogoUrl"
                style={{
                  height: "24px",
                  width: "24px",
                  borderRadius: "50%",
                }}
              />
              {"Ethereum Mainnet"}
            </MenuItem>
            {config.DEV_MODE ? (
              <MenuItem
                disabled={userMetamaskChainId === BSCTestnet.chainId}
                selected={userMetamaskChainId === BSCTestnet.chainId}
                onClick={() => handleMenuItemClick(BSCTestnet.chainId)}
                sx={{
                  color: isLightMode && !dark ? basicTone400 : basicTone0,
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                }}
              >
                <img
                  src={getChainLogoById(BSCTestnet.chainId)}
                  alt="BSCTestNetLogoUrl"
                  style={{
                    height: "24px",
                    width: "24px",
                    borderRadius: "50%",
                  }}
                />
                {BSCTestnet.chainName}
              </MenuItem>
            ) : null}
            {config.DEV_MODE ? (
              <MenuItem
                disabled={userMetamaskChainId === BlastTestNet.chainId}
                selected={userMetamaskChainId === BlastTestNet.chainId}
                onClick={() => handleMenuItemClick(BlastTestNet.chainId)}
                sx={{
                  color: isLightMode && !dark ? basicTone400 : basicTone0,
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                }}
              >
                <img
                  src={getChainLogoById(BlastTestNet.chainId)}
                  alt="BlastTestNetLogoUrl"
                  style={{
                    height: "24px",
                    width: "24px",
                    borderRadius: "50%",
                  }}
                />
                {BlastTestNet.name}
              </MenuItem>
            ) : null}
            {/* FOR PRODUCTION ENV */}
            <MenuItem
              disabled={userMetamaskChainId === BSC.chainId}
              selected={userMetamaskChainId === BSC.chainId}
              onClick={(event) => handleMenuItemClick(BSC.chainId)}
              sx={{
                color: isLightMode && !dark ? basicTone400 : basicTone0,
                display: "flex",
                gap: "8px",
                alignItems: "center",
              }}
            >
              <img
                src={getChainLogoById(BSC.chainId)}
                alt="BSClogourl"
                style={{
                  height: "24px",
                  width: "24px",
                  borderRadius: "50%",
                }}
              />
              {BSC.chainName}
            </MenuItem>
          </Menu>
        </>
      )}

      <IconButton
        onClick={() => handleDisconnect(() => navigate("/"))}
        sx={{
          border: `1px solid ${
            !dark && isLightMode ? basicTone100 : basicTone450
          }`,
          borderRadius: mainBorderRadius,
          marginLeft: "4px",
          padding: "6px 8px",
          background: !dark && isLightMode ? basicTone0 : basicTone700,
          "&:hover": { background: !dark ? basicTone50 : basicTone500 },
        }}
      >
        <LogoutIconSVG />
        {!dark && (
          <Typography
            variant="body1"
            color="secondary"
            sx={{ marginLeft: "8px" }}
          >
            Log Out
          </Typography>
        )}
      </IconButton>
    </>
  ) : (
    <OutlinedPrimaryCustomButton
      onClick={() => navigate("/register")}
      dark={dark}
      text="Sign in"
      StartIcon={<RocketLaunchIcon />}
    />
  );
};

export default SignInButton;
