import { Avatar, Button, Card, Grid, Stack, Typography } from "@mui/material";

import SettingsIcon from "@mui/icons-material/Settings";
import AppsIcon from "@mui/icons-material/Apps";
import React, { useContext } from "react";
import { AccountContext } from "../../../shared/providers/account-provider";
import { ThemeModeContext } from "../../../shared/providers/theme-mode-provider";
import {
  basicTone100,
  basicTone200,
  basicTone450,
  basicTone50,
  basicTone500,
  basicTone600,
  borderBasicTone450,
  dropShadowSM,
  mainLinearGradient,
  whiteTone,
} from "../../../shared/styles/constants";
import { formatAddress } from "../../../shared/utils";
import IconButton from "@mui/material/IconButton";
import { DashboardContext } from "../../../shared/providers/dashboard-provider";
import Tooltip from "@mui/material/Tooltip";
import AvatarDefault from "../../../assets/Avatar.jpg";
import BalanceSection from "./BalanceSection";
import { fontPretendard } from "../../../theme";
import AssetsSection from "./AssetsSection";
import { WalletContext } from "../../../shared/providers/wallet-provider";

export const LeftSideContent = () => {
  const { usdcInfoByChain, showEditUserProfile, setShowEditUserProfile } =
    useContext(DashboardContext);
  const { walletInfo } = useContext(WalletContext);
  const { isLightMode } = useContext(ThemeModeContext);
  // const [balance, setBallance] = useState<string>("0");
  const { account, isConnectedToMetamask } = useContext(AccountContext);

  const email = localStorage.getItem("email");
  const userName = localStorage.getItem("username");
  const avatar = localStorage.getItem("avatar");

  // const { stableCoinContract } = useContracts({
  //   stableCoinAddress: usdcInfoByChain?.address,
  // });

  // const { value: balanceOf } =
  //   useCall({
  //     contract: stableCoinContract,
  //     method: "balanceOf",
  //     args: [account],
  //   }) ?? {};

  // useEffect(() => {
  //   if (balanceOf) {
  //     const val = new BigNumber(balanceOf.toString()).dividedBy(1e6).toString();
  //     setBallance(val);
  //   }
  // }, [balanceOf]);

  return (
    <Stack gap={1}>
      <Card>
        <Grid
          container
          justifyContent={"space-between"}
          flexWrap="nowrap"
          alignItems="center"
          padding={"20px 20px 20px 20px"}
        >
          <Grid
            xs={11}
            item
            container
            gap={2}
            alignItems="center"
            flexWrap={{ xs: "wrap", md: "nowrap" }}
            padding={"16px 0px 16px 0px"}
          >
            <Avatar
              alt={"avatar"}
              src={avatar !== "null" ? avatar || undefined : AvatarDefault}
              sx={{
                width: { xs: "30px", md: "40px" },
                height: { xs: "30px", md: "40px" },
              }}
              variant="circular"
            />
            <Grid flexDirection={"column"}>
              {userName !== "null" ? (
                <Typography
                  fontFamily={fontPretendard}
                  fontWeight={700}
                  fontSize={"16px"}
                  lineHeight={"24px"}
                  letterSpacing={"2%"}
                  color="secondary"
                >
                  {userName}
                </Typography>
              ) : null}
              <Typography
                variant="body1"
                color="default"
                fontWeight={700}
                sx={{
                  background: mainLinearGradient,
                  backgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                {formatAddress(account)}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            xs={1}
            container
            item
            justifyContent={"flex-end"}
            alignItems={{ xs: "flex-end", md: "center" }}
          >
            {showEditUserProfile ? (
              <Tooltip title="Your applications">
                <IconButton onClick={() => setShowEditUserProfile(false)}>
                  <AppsIcon fontSize="medium" color="info" />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Edit profile">
                <IconButton onClick={() => setShowEditUserProfile(true)}>
                  <SettingsIcon fontSize="medium" color="info" />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        </Grid>

        <Stack gap={1.5}>
          {usdcInfoByChain && (
            <BalanceSection usdcInfoByChain={usdcInfoByChain} />
          )}
          {isConnectedToMetamask && !usdcInfoByChain && (
            <Card
              sx={{
                padding: "30px 22px",
                display: "flex",
                alignItems: "center",
                gap: 1,
                boxShadow: !isLightMode ? dropShadowSM : "none",
                border: isLightMode
                  ? `1px solid ${basicTone50}`
                  : `1px solid ${basicTone500}`,
                backgroundColor: isLightMode ? whiteTone : basicTone600,
              }}
            >
              <Stack gap={1}>
                <Typography variant="h5" color="secondary">
                  Unsupported chain!
                </Typography>
                <Typography>
                  Please switch network to be able to see wallet info.
                </Typography>
              </Stack>
            </Card>
          )}
        </Stack>
        <Grid>
          <Typography
            fontFamily={fontPretendard}
            fontWeight={600}
            fontSize={"14px"}
            lineHeight={"16.71px"}
            color="secondary"
            sx={{ padding: "20px" }}
          >
            Assets
          </Typography>
          {walletInfo && (
            <AssetsSection supportedTokens={walletInfo.supportedTokens} />
          )}
        </Grid>
        {/* <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            backgroundColor: isLightMode ? basicTone100 : basicTone500,
          }}
        >
          <Button
            sx={{
              padding: "13px 85px 13px 85px",
              border: `1px solid ${borderBasicTone450}`,
              borderRadius: "0",
            }}
          >
            <Typography
              fontFamily={fontPretendard}
              fontWeight={600}
              fontSize={"14px"}
              lineHeight={"16.71px"}
              color="secondary"
            >
              Button1
            </Typography>
          </Button>

          <Button
            sx={{
              padding: "13px 85px 13px 85px",
              border: `1px solid ${borderBasicTone450}`,
              borderRadius: "0",
            }}
          >
            <Typography
              fontFamily={fontPretendard}
              fontWeight={600}
              fontSize={"14px"}
              lineHeight={"16.71px"}
              color="secondary"
            >
              Button2
            </Typography>
          </Button>
        </Grid> */}
      </Card>
      {/* to continue */}
      {/* <Card>
        <Grid
          container
          flexWrap="nowrap"
          alignItems="center"
          padding={"20px 20px 20px 20px"}
        >
          <Grid item container gap={1}>
            <Typography
              fontFamily={fontPretendard}
              fontWeight={600}
              fontSize={"14px"}
              lineHeight={"16.71px"}
              color="secondary"
              paddingBottom={"14px"}
            >
              Personal Information
            </Typography>

            <Grid item container justifyContent={"space-between"}>
              <Typography
                fontFamily={fontPretendard}
                fontWeight={400}
                fontSize={"12px"}
                lineHeight={"14.32px"}
                color={basicTone200}
              >
                Stat 1
              </Typography>

              <Typography
                fontFamily={fontPretendard}
                fontWeight={400}
                fontSize={"12px"}
                lineHeight={"14.32px"}
                color="secondary"
              >
                Info
              </Typography>
            </Grid>

            <Grid item container justifyContent={"space-between"}>
              <Typography
                fontFamily={fontPretendard}
                fontWeight={400}
                fontSize={"12px"}
                lineHeight={"14.32px"}
                color={basicTone200}
              >
                Stat 1
              </Typography>

              <Typography
                fontFamily={fontPretendard}
                fontWeight={400}
                fontSize={"12px"}
                lineHeight={"14.32px"}
                color="secondary"
              >
                Info
              </Typography>
            </Grid>

            <Grid item container justifyContent={"space-between"}>
              <Typography
                fontFamily={fontPretendard}
                fontWeight={400}
                fontSize={"12px"}
                lineHeight={"14.32px"}
                color={basicTone200}
              >
                Stat 2
              </Typography>

              <Typography
                fontFamily={fontPretendard}
                fontWeight={400}
                fontSize={"12px"}
                lineHeight={"14.32px"}
                color="secondary"
              >
                Info
              </Typography>
            </Grid>

            <Grid item container justifyContent={"space-between"}>
              <Typography
                fontFamily={fontPretendard}
                fontWeight={400}
                fontSize={"12px"}
                lineHeight={"14.32px"}
                color={basicTone200}
              >
                Stat 3
              </Typography>

              <Typography
                fontFamily={fontPretendard}
                fontWeight={400}
                fontSize={"12px"}
                lineHeight={"14.32px"}
                color="secondary"
              >
                Info
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Card> */}
    </Stack>
  );
};

export default LeftSideContent;
