import { Stack, Grid, Typography, Avatar } from "@mui/material";
import { useContext } from "react";
import BigNumber from "bignumber.js";
import { TCoin } from "../../types";
import {
  basicTone100,
  basicTone500,
} from "../../../../shared/styles/constants";
import { ThemeModeContext } from "../../../../shared/providers/theme-mode-provider";
import { getChainNameById } from "../../../../shared/utils";
import { MarketplaceContext } from "../../../../shared/providers/marketplace-provider";

export const TokensList = ({
  tokensList,
  onSelectToken,
}: {
  tokensList: TCoin[];
  onSelectToken: (value: TCoin) => void;
}) => {
  const { isLightMode } = useContext(ThemeModeContext);
  const { selectedToken } = useContext(MarketplaceContext);

  const formatAvailableTokenForSale = (val: string) =>
    new BigNumber(val).decimalPlaces(2, 1).toString();

  return (
    <Stack>
      {tokensList
        .sort(
          (a, b) =>
            parseInt(b.availableTokensForSale) -
            parseInt(a.availableTokensForSale)
        )
        .map((token, index) => (
          <Grid
            key={`${token.internalId}${index}`}
            container
            flexWrap={"nowrap"}
            onClick={() => onSelectToken(token)}
            sx={{
              padding: "8px 8px 8px 0",
              height: "53px",
              alignItems: "center",
              justifyContent: "space-between",
              alignSelf: "stretch",
              cursor: "pointer",
              backgroundColor:
                selectedToken?.internalId === token.internalId
                  ? isLightMode
                    ? basicTone100
                    : basicTone500
                  : undefined,
              "&:hover": {
                backgroundColor: isLightMode ? basicTone100 : basicTone500,
              },
            }}
          >
            <Grid container gap={2} flexWrap={"nowrap"} alignItems={"center"}>
              <Avatar
                src={token.logoUrl}
                variant="circular"
                sx={{ width: "24px", height: "24px", marginLeft: "12px" }}
              />
              <Grid container flexDirection={"column"} gap={"2px"}>
                <Typography variant="body1" color="secondary" fontWeight={600}>
                  {token.symbol}
                </Typography>
                <Typography variant="body2" color={"#C16CA9"}>
                  {getChainNameById(token.chainId)}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="body2" color="secondary">
                {token.availableTokensForSale
                  ? `${formatAvailableTokenForSale(
                      token.availableTokensForSale
                    )}`
                  : "-"}
              </Typography>
            </Grid>
          </Grid>
        ))}
    </Stack>
  );
};

export default TokensList;
