import { Button, Card, Grid, Typography } from "@mui/material";

export const Upcoming = ({ projectTitle }: any) => {
  const renderCardContent = (
    <Typography variant="body1" fontSize={"14px"} fontWeight={500}>
      This project is currently in the 'Upcoming' stage. Whitelisting has not
      started yet. Stay tuned for updates and opportunities to get involved.
    </Typography>
  );
  return (
    <Grid
      container
      justifyContent={"flex-start"}
      gap={2}
      paddingTop="36px"
      paddingBottom={"36px"}
    >
      <Typography
        variant="h5"
        color="secondary"
        fontWeight={800}
        fontSize="16px"
      >
        Interested in supporting {projectTitle} project?
      </Typography>

      {renderCardContent}

      <Button
        fullWidth
        color="primary"
        variant="contained"
        disabled={true}
        sx={{ height: "48px", borderRadius: "8px" }}
      >
        Upcoming
      </Button>
    </Grid>
  );
};

export default Upcoming;
