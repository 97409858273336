import { useContext } from "react";
import { Stack } from "@mui/material";

import { TSupportedTokensList } from "../types";

import { AccountContext } from "../../../shared/providers/account-provider";
import SupportedTokensByChain from "./SupportedTokensByChain";
import SupportedTokensDifferentChain from "./SupportedTokensDifferentChain";

export const AssetsSection = ({
  supportedTokens,
}: {
  supportedTokens: TSupportedTokensList[];
}) => {
  const { userMetamaskChainId } = useContext(AccountContext);

  return (
    <Stack>
      {supportedTokens.map((tokensByChainList) =>
        userMetamaskChainId === tokensByChainList.chainId ? (
          <SupportedTokensByChain
            key={tokensByChainList.chainId}
            chainId={tokensByChainList.chainId}
            supportedTokens={tokensByChainList.tokens}
          />
        ) : (
          <SupportedTokensDifferentChain
            key={tokensByChainList.chainId}
            chainId={tokensByChainList.chainId}
            supportedTokens={tokensByChainList.tokens}
          />
        )
      )}
    </Stack>
  );
};

export default AssetsSection;
