import { Stack, Typography, Grid, Divider, Box, Skeleton } from "@mui/material";
import { ThemeModeContext } from "../../../../shared/providers/theme-mode-provider";
import { useContext } from "react";
import {
  basicTone0,
  basicTone100,
  basicTone200,
  basicTone50,
  basicTone500,
  basicTone700,
  linearBlackBG,
  secondaryBorderRadius,
} from "../../../../shared/styles/constants";
import BidOrdersList from "./BidOrdersList";
import AskOrdersList from "./AskOrdersList";
import { MarketplaceContext } from "../../../../shared/providers/marketplace-provider";

export const RightSidebar = () => {
  const { isLightMode } = useContext(ThemeModeContext);
  const { isTokenListLoading } = useContext(MarketplaceContext);

  return (
    <Grid
      item
      xs={12}
      md={2.5}
      container
      flexDirection={"column"}
      sx={{ backgroundColor: isLightMode ? basicTone0 : basicTone700 }}
    >
      {!isTokenListLoading ? (
        <>
          <Typography color={"secondary"} fontWeight={"800"} sx={{ p: 1 }}>
            Order Book
          </Typography>
          {/* header */}
          <Grid
            container
            sx={{
              p: 1,
              borderTop: `1px solid ${basicTone500}`,
              borderBottom: `1px solid ${basicTone500}`,
            }}
          >
            <Grid item xs={4}>
              <Typography
                variant="body2"
                color={isLightMode ? basicTone200 : basicTone100}
                fontSize={"10px"}
                fontWeight={700}
              >
                PRICE PER TOKEN
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant="body2"
                color={isLightMode ? basicTone200 : basicTone100}
                fontSize={"10px"}
                fontWeight={700}
              >
                QUANTITY
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant="body2"
                color={isLightMode ? basicTone200 : basicTone100}
                fontSize={"10px"}
                fontWeight={700}
              >
                FULL PRICE
              </Typography>
            </Grid>
          </Grid>
          <Stack gap={4}>
            <BidOrdersList />
            <Box sx={{ px: 2 }}>
              <Divider />
            </Box>
            <AskOrdersList />
          </Stack>
        </>
      ) : (
        <Stack gap={2} sx={{ p: 2 }}>
          <Box>
            <Skeleton
              variant="rounded"
              width={"30%"}
              sx={{
                background: !isLightMode ? basicTone50 : linearBlackBG,
                borderRadius: secondaryBorderRadius,
              }}
            />
          </Box>
          <Box>
            <Skeleton
              variant="rounded"
              width={"100%"}
              sx={{
                background: !isLightMode ? basicTone50 : linearBlackBG,
                borderRadius: secondaryBorderRadius,
              }}
            />
          </Box>
          <Box>
            <Skeleton
              variant="rounded"
              width={"100%"}
              sx={{
                background: !isLightMode ? basicTone50 : linearBlackBG,
                borderRadius: secondaryBorderRadius,
              }}
            />
          </Box>
          <Box>
            <Skeleton
              variant="rounded"
              width={"100%"}
              sx={{
                background: !isLightMode ? basicTone50 : linearBlackBG,
                borderRadius: secondaryBorderRadius,
              }}
            />
          </Box>
          <Box>
            <Skeleton
              variant="rounded"
              width={"100%"}
              sx={{
                background: !isLightMode ? basicTone50 : linearBlackBG,
                borderRadius: secondaryBorderRadius,
              }}
            />
          </Box>
          <Divider sx={{ marginTop: "30px", marginBottom: "30px" }} />
          <Box>
            <Skeleton
              variant="rounded"
              width={"100%"}
              sx={{
                background: !isLightMode ? basicTone50 : linearBlackBG,
                borderRadius: secondaryBorderRadius,
              }}
            />
          </Box>
          <Box>
            <Skeleton
              variant="rounded"
              width={"100%"}
              sx={{
                background: !isLightMode ? basicTone50 : linearBlackBG,
                borderRadius: secondaryBorderRadius,
              }}
            />
          </Box>
          <Box>
            <Skeleton
              variant="rounded"
              width={"100%"}
              sx={{
                background: !isLightMode ? basicTone50 : linearBlackBG,
                borderRadius: secondaryBorderRadius,
              }}
            />
          </Box>
        </Stack>
      )}
    </Grid>
  );
};

export default RightSidebar;
