export const InactiveRoundIconSVG = () => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9.5" cy="9" r="8.5" stroke="#515154" />
  </svg>
);

export const InactiveStepIconSVG = () => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9" cy="10" r="8.5" stroke="#515154" />
  </svg>
);
