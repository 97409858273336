import * as env from "env-var";

export const config = {
  BASE_URL: env.get("REACT_APP_BASE_URL").required().asString(),
  LANDING_PAGE_URL: env.get("REACT_APP_LANDING_URL").required().asString(),
  DEV_MODE: env.get("REACT_APP_SHOW_TESTNET").required().asString() === "true",
  ENV_URL: env.get("REACT_APP_ENV_URL").required().asString(),
  CLIENT_ID: env.get("REACT_APP_CLIENT_ID").required().asString(),
};

// paybableTokenConfig.ts

interface PayableTokenInfo {
  symbol: string;
  address: string;
  decimals: number;
}

interface ChainPayableTokenInfo {
  [key: number]: { [key: string]: PayableTokenInfo }; // Maps chainID to a map of token addresses to TokenInfo
}

export const payableTokensConfig: ChainPayableTokenInfo = {
  1: {
    // Ethereum Mainnet
    "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48": {
      symbol: "USDC",
      address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
      decimals: 6,
    },
    // Add other tokens
  },
  137: {
    // Polygon Mainnet
    "0x6F4C286A0A5a738ee198Cc2605Cc3Ac9f21b6423": {
      symbol: "FUSDC2",
      address: "0x6F4C286A0A5a738ee198Cc2605Cc3Ac9f21b6423",
      decimals: 6,
    },
    "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174": {
      symbol: "USDC",
      address: "0x6F4C286A0A5a738ee198Cc2605Cc3Ac9f21b6423",
      decimals: 6,
    },
    "0xe844E29cB9E0009cA78706EE66583a8a6fb8069F": {
      symbol: "TCOMPY3",
      address: "0xe844E29cB9E0009cA78706EE66583a8a6fb8069F",
      decimals: 18,
    },
  },
  56: {
    // Binance Smart Chain Mainnet
    "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d": {
      symbol: "USDC",
      address: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
      decimals: 18,
    },
    // Additional tokens for Binance Smart Chain
  },
  97: {
    // Binance Smart Chain Testnet
    "0x875e0fe234d8CCd52786a5F4098D84294d045aE5": {
      symbol: "FUSDC2",
      address: "0x875e0fe234d8CCd52786a5F4098D84294d045aE5",
      decimals: 18,
    },
    // Additional tokens for BSC Testnet
  },
  8453: {
    // Base
    "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913": {
      symbol: "USDC",
      address: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
      decimals: 6,
    },
    // Additional tokens for Base
  },
  168587773: {
    // Blast Testnet
    "0x9E45CadE49334231Dc13bB2d4a2fC4B7F942aCA6": {
      symbol: "FUSDC2",
      address: "0x9E45CadE49334231Dc13bB2d4a2fC4B7F942aCA6",
      decimals: 6,
    },
    // Additional tokens for Blast
  },
  42161: {
    // Arbitrum One
    "0x6EB95582cCae9634DD33998402B85923A6E427D7": {
      symbol: "FUSDC2",
      address: "0x6EB95582cCae9634DD33998402B85923A6E427D7",
      decimals: 6,
    },
    "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8": {
      symbol: "USDC",
      address: "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
      decimals: 6,
    },
  },
};
