import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DashboardProvider } from "../../shared/providers/dashboard-provider";
import RightSideContent from "./rightSideContent/RightSideContent";

import UserInfoCard, {
  LeftSideContent,
} from "./leftSideContent/LeftSideContent";
import { WalletProvider } from "../../shared/providers/wallet-provider";

export const DashboardPage = () => {
  const navigate = useNavigate();

  const account = localStorage.getItem("accountAddress");

  useEffect(() => {
    if (!account) {
      navigate("/");
    }
  }, []);

  return (
    <DashboardProvider>
      <WalletProvider>
        <div className="ProjectContent">
          <Grid
            container
            gap={1.5}
            sx={{ padding: { xs: "10px 8px 60px", md: "40px 0" } }}
            flexWrap={{ md: "nowrap" }}
            justifyContent={"center"}
          >
            {/* Left side */}
            <Grid item xs={12} md={4}>
              <LeftSideContent />
            </Grid>
            {/* Right side */}
            <Grid item xs={12} md={8} sx={{ height: "100%" }}>
              <RightSideContent />
            </Grid>
          </Grid>
        </div>
      </WalletProvider>
    </DashboardProvider>
  );
};
export default DashboardPage;
