import { Grid, Typography } from "@mui/material";
import { IFeaturedProject } from "../../../../models/project.model";
import { basicTone200 } from "../../../../shared/styles/constants";
import { formatDate, formatNumber } from "../../../../shared/utils";
import Divider from "@mui/material/Divider";
import { fontPretendard } from "../../../../theme";

const ValueWithLabel = ({
  label,
  value = "-",
  valPrefix = "",
  valSufix = "",
}: {
  label: string;
  value: string | number | undefined | null;
  valPrefix?: string;
  valSufix?: string;
}) => (
  <Grid container item xs={12} sm={12} justifyContent="space-between">
    <Grid item xs={6}>
      <Typography
        fontFamily={fontPretendard}
        fontWeight={400}
        fontSize={"16px"}
        lineHeight={"19.09px"}
        color={basicTone200}
      >
        {label}
      </Typography>
    </Grid>
    <Grid item xs={6} style={{ textAlign: "right" }}>
      <Typography
        fontFamily={fontPretendard}
        color="secondary"
        fontWeight={700}
        fontSize={"16px"}
        lineHeight={"19.09px"}
      >
        {`${valPrefix}${value}${valSufix}`}
      </Typography>
    </Grid>
  </Grid>
);

export const TokenomicsContent = (projectData: IFeaturedProject) => {
  const {
    name,
    targetAmount,
    dollarTargetAmount,
    pricePerToken,
    minAllocation,
    maxAllocation,
    signUpDeadline,
    signUpStartDate,
    vestingDuration,
    vestingStartDate,
    initialMarketCap,
    initialTokenSupply,
    startRedeemPercentage,
  } = projectData.currentFundingRound;
  const { coinSymbol } = projectData;

  return (
    <Grid container gap={2} paddingTop={3}>
      <Grid item container alignItems="center" gap={1.5} paddingTop={3}>
        <Typography
          fontFamily={fontPretendard}
          color="secondary"
          fontWeight={700}
          fontSize="32px"
          lineHeight={"38.19px"}
          letterSpacing={-0.02}
        >
          Tokenomics
        </Typography>
      </Grid>
      <Grid item container gap={3}>
        <Divider />
        <ValueWithLabel label="Round name:" value={name} />
        <Divider />
        <ValueWithLabel
          label="Target amount:"
          value={formatNumber(targetAmount)}
          valSufix={" " + coinSymbol}
        />
        <Divider />
        <ValueWithLabel
          label="Dollar target amount:"
          value={formatNumber(dollarTargetAmount)}
          valSufix=" $"
        />
        <Divider />

        <ValueWithLabel
          label="Price per token:"
          value={formatNumber(pricePerToken)}
          valSufix=" $"
        />
        <Divider />
        <ValueWithLabel
          label="Min allocation:"
          value={formatNumber(minAllocation)}
          valSufix=" $"
        />
        <Divider />
        <ValueWithLabel
          label="Max allocation:"
          value={formatNumber(maxAllocation)}
          valSufix=" $"
        />
        <Divider />

        <ValueWithLabel
          label="Sign up start date:"
          value={formatDate(signUpStartDate)}
        />
        <Divider />
        <ValueWithLabel
          label="Sign up deadline:"
          value={formatDate(signUpDeadline)}
        />
        <Divider />

        <ValueWithLabel
          label="Vesting start date:"
          value={formatDate(vestingStartDate)}
        />
        <Divider />
        <ValueWithLabel
          label="Vesting duration:"
          value={vestingDuration}
          valSufix={vestingDuration === "1" ? " Day" : " Days"}
        />
        <Divider />
        <ValueWithLabel
          label="Start redeem percentage:"
          value={startRedeemPercentage}
          valSufix="%"
        />
        <Divider />

        <ValueWithLabel
          label="Initial market cap:"
          value={formatNumber(initialMarketCap)}
          valSufix=" $"
        />
        <Divider />
        <ValueWithLabel
          label="Initial token supply:"
          value={formatNumber(initialTokenSupply)}
        />
      </Grid>
    </Grid>
  );
};
export default TokenomicsContent;
