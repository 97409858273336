import { Avatar, Box, Grid, Skeleton, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ThemeModeContext } from "../../../../shared/providers/theme-mode-provider";
import { MarketplaceContext } from "../../../../shared/providers/marketplace-provider";
import {
  basicTone0,
  basicTone200,
  basicTone50,
  basicTone800,
  linearBlackBG,
  secondaryBorderRadius,
} from "../../../../shared/styles/constants";
import {
  getChainNameById,
  formatBigNumbersStrings,
} from "../../../../shared/utils";
import ActivityChart from "./ActivityChart";
import { useCall, useEthers } from "@usedapp/core";
import { useContracts } from "../../../../hooks";

import { TCoin, TOrder } from "../../types";
import { mapOrdersListResponse } from "./ordersModals/ordersModals.utils";
import { fontPretendard } from "../../../../theme";

export const GraphContainer = ({
  selectedToken,
}: {
  selectedToken: TCoin | undefined;
}) => {
  const { isLightMode } = useContext(ThemeModeContext);
  const {
    tokenAddress,
    isTokenListLoading,
    tokensList,
    getMarketplaceAddressByChain,
  } = useContext(MarketplaceContext);
  const [activityList, setActivityList] = useState<TOrder[]>([]);
  const [isOrdersHistoryLoading, setIsOrdersHistoryLoading] = useState(true);

  const { marketplaceContract } = useContracts({
    marketplaceContractAddress: getMarketplaceAddressByChain(
      selectedToken?.chainId
    ),
  });

  const { value: ordersHistoryResult, error: orderHistoryError } =
    useCall(
      selectedToken && {
        contract: marketplaceContract,
        method: "listOrderHistoryPerToken",
        args: [tokenAddress],
      },
      { chainId: selectedToken?.chainId }
    ) ?? {};

  useEffect(() => {
    if (ordersHistoryResult && selectedToken?.chainId) {
      setActivityList(
        mapOrdersListResponse(ordersHistoryResult[0], selectedToken.chainId)
      );

      setIsOrdersHistoryLoading(false);
    } else if (!selectedToken && tokensList.length === 0) {
      setIsOrdersHistoryLoading(false);
    }
  }, [ordersHistoryResult, orderHistoryError, selectedToken]);

  let newestItem: any = null;
  let maxTimestamp = 0;

  activityList.forEach((item: TOrder) => {
    const timestamp = parseInt(item.fulfilledAt!);
    if (timestamp > maxTimestamp) {
      maxTimestamp = timestamp;
      newestItem = item;
    }
  });

  // Get the price from the newest item
  const newestPrice = newestItem ? newestItem.pricePerToken : 0;

  // Calculate the total volume by summing up the quantity of each item
  const totalVolume = activityList.reduce(
    (acc: any, item: TOrder) => acc + parseInt(item.quantity),
    0
  );

  return isTokenListLoading || isOrdersHistoryLoading ? (
    <Stack gap={2} sx={{ p: 2 }}>
      <Box>
        <Skeleton
          variant="rounded"
          width={"40%"}
          height={50}
          sx={{
            background: !isLightMode ? basicTone50 : linearBlackBG,
            borderRadius: secondaryBorderRadius,
          }}
        />
      </Box>
      <Box>
        <Skeleton
          variant="rounded"
          width={"100%"}
          height={350}
          sx={{
            background: !isLightMode ? basicTone50 : linearBlackBG,
            borderRadius: secondaryBorderRadius,
          }}
        />
      </Box>
    </Stack>
  ) : (
    <Stack
      sx={{
        width: "100%",
        backgroundColor: isLightMode ? basicTone0 : basicTone800,
        padding: "12px 12px 20px 16px",
      }}
      gap={2}
    >
      <Grid container flexWrap={"nowrap"}>
        <Grid
          container
          item
          md={4}
          gap={2}
          flexWrap={"nowrap"}
          alignItems={"center"}
        >
          <Avatar
            src={selectedToken?.logoUrl}
            variant="circular"
            sx={{ width: "28px", height: "28px" }}
          />
          <Grid container flexDirection={"column"} gap={"2px"}>
            <Typography
              variant="h6"
              color="secondary"
              fontWeight={700}
              lineHeight={"22px"}
            >
              {selectedToken?.symbol}
            </Typography>
            <Typography variant="body2" color={"#C16CA9"}>
              {getChainNameById(selectedToken?.chainId)}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          item
          gap={4}
          flexDirection={"row"}
          justifySelf={"flex-start"}
        >
          <Stack flexDirection={"column"}>
            <Typography
              fontFamily={fontPretendard}
              fontSize={"10px"}
              color={basicTone200}
              fontWeight={600}
              lineHeight={"22px"}
            >
              PRICE:
            </Typography>
            <Typography
              fontFamily={fontPretendard}
              fontWeight={"700"}
              fontSize={"12px"}
              color="secondary"
            >
              {newestPrice}
            </Typography>
          </Stack>
          <Stack>
            <Typography
              fontFamily={fontPretendard}
              fontSize={"10px"}
              color={basicTone200}
              fontWeight={600}
              lineHeight={"22px"}
            >
              VOLUME:
            </Typography>
            <Typography
              fontFamily={fontPretendard}
              fontWeight={"700"}
              fontSize={"12px"}
              color="secondary"
            >
              {formatBigNumbersStrings(`${totalVolume}`)}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Grid container>
        <ActivityChart activityList={activityList} />
      </Grid>
    </Stack>
  );
};

export default GraphContainer;
