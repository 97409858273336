import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../config/config";
import { ICreateProject, IFeaturedProject } from "../models/project.model";
import { SocialMediaCounter } from "../pages/FeaturedProjectPage/sections/IncreaseChancesOnSignUpModal";

//Set a return type for result from backend(object)
interface ResultFromBackend {
  result: string;
  data: [] | {};
  status: boolean;
}

export const featuredProjectsApi: any = createApi({
  reducerPath: "projectsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: config.BASE_URL,
  }),
  endpoints: (builder) => ({
    addProject: builder.mutation<void, ICreateProject>({
      query: (project) => ({
        url: "/projects",
        method: "POST",
        body: project,
      }),
    }),
    getFeaturedProjects: builder.query<ResultFromBackend, string>({
      query: () => {
        return `/featured-projects/list-visible-featured-projects`;
      },
    }),
    getFeaturedProjectById: builder.query<ResultFromBackend, string>({
      query: (id) => {
        return `/featured-projects/get-featured-project/${id}`;
      },
    }),
    getFeaturedProjectByUrlName: builder.query<ResultFromBackend, string>({
      query: (urlName) => {
        return `/featured-projects/featured-project/${urlName}`;
      },
    }),
    userRoundSignUp: builder.mutation<
      void,
      {
        fundingRound: { fundingRoundId: string };
        token: string;
        socialMediaCounter: SocialMediaCounter;
        telegramUser: string;
        handleDiscord: string;
        handleTwitter: string;
      }
    >({
      query: (payload) => ({
        url: "/featured-projects/round-user-signup",
        method: "POST",
        body: {
          ...payload?.fundingRound,
          socialMediaCounter: payload?.socialMediaCounter,
          telegramUser: payload?.telegramUser,
          handleDiscord: payload?.handleDiscord,
          handleTwitter: payload?.handleTwitter,
        },
        headers: {
          Authorization: `Bearer ${payload.token}`,
        },
      }),
    }),
    getUserIsSignedUpToCurrentRound: builder.mutation<
      void,
      { currentFundingRoundId: number; token: string }
    >({
      query: (payload) => ({
        url: `/featured-projects/get-user-signUp-status/`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${payload.token}`,
        },
        body: { fundingRoundId: payload.currentFundingRoundId },
      }),
    }),
    getFeaturedProjectsPerUser: builder.query<ResultFromBackend, string>({
      query: (token) => ({
        url: `/web/get-featured-projects-for-user/`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    getFundingRoundByFundingTermsAndSelectedTokenAddresses: builder.query<
      ResultFromBackend,
      { vestingTermsAddress: string; selectedTokenAddress: string }
    >({
      query: (payload) => ({
        url: `/web/get-funding-round-by-funding-terms-selected-token-addresses/`,
        method: "POST",
        body: {
          vestingTermsAddress: payload.vestingTermsAddress,
          selectedTokenAddress: payload.selectedTokenAddress,
        },
      }),
    }),
    getMarketAndCoinAddresses: builder.query<ResultFromBackend, string>({
      query: () => {
        return `/web/addresses/`;
      },
    }),
  }),
});

export const {
  useAddProjectMutation,
  useGetProjectsByTypeQuery,
  useGetFeaturedProjectsQuery,
  useGetFeaturedProjectByIdQuery,
  useGetFeaturedProjectByUrlNameQuery,
  useUserRoundSignUpMutation,
  useGetUserIsSignedUpToCurrentRoundMutation,
  useGetFeaturedProjectsPerUserQuery,
  useGetFundingRoundByFundingTermsAndSelectedTokenAddressesQuery,
  useGetMarketAndCoinAddressesQuery,
} = featuredProjectsApi;
