import React, { useState, useContext } from "react";
import { Card, Typography, Tabs, Tab, SvgIcon, Grid } from "@mui/material";

import ApplicationsList from "./ApplicationsList";
import { basicTone0, basicTone700 } from "../../../shared/styles/constants";
import { WidgetsColorIconSVG } from "../../../assets/icons/WidgetsColorIconSVG";
import { TropheyColorIconSVG } from "../../../assets/icons/TropheyColorIconSVG";
import { ClockColorIconSVG } from "../../../assets/icons/ClockColorIconSVG";
import { DistributingColorIconSVG } from "../../../assets/icons/DistributingColorIconSVG";
import { ThemeModeContext } from "../../../shared/providers/theme-mode-provider";
import { DashboardContext } from "../../../shared/providers/dashboard-provider";
import { fontPretendard } from "../../../theme";

export const UserApplicationsCard = () => {
  const { applications } = useContext(DashboardContext);
  const {
    signUpFeaturedProjects,
    fundingFeaturedProjects,
    waitingFeaturedProjects,
    distributingFeaturedProjects,
  } = applications;
  const { isLightMode } = useContext(ThemeModeContext);
  const [selectedTab, setSelectedTab] = useState(0);
  const handleChangeTab = (_: any, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Card
      sx={{
        background: isLightMode ? undefined : basicTone700,
      }}
    >
      <Grid
        sx={{
          borderBottom: "2px solid #A256C7",
          padding: "20px 40px 20px 40px",
        }}
      >
        <Typography
          fontFamily={fontPretendard}
          fontSize={"16px"}
          lineHeight={"40px"}
          fontWeight={700}
          color="secondary"
        >
          Your applications
        </Typography>
      </Grid>
      <Grid
        container
        justifyContent="center"
        sx={{
          marginTop: "16px",
          width: "100%",
        }}
      >
        <Tabs
          onChange={handleChangeTab}
          aria-label="ProjectInfoTabs"
          value={selectedTab}
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            "& .MuiTabs-scrollButtons": {
              color: isLightMode ? basicTone700 : basicTone0,
            },
          }}
          textColor="primary"
        >
          <Tab
            icon={
              <SvgIcon>
                <WidgetsColorIconSVG />
              </SvgIcon>
            }
            iconPosition="start"
            label={`Applied`}
          />
          <Tab
            icon={
              <SvgIcon>
                <TropheyColorIconSVG />
              </SvgIcon>
            }
            iconPosition="start"
            label={`Won`}
          />
          <Tab
            icon={
              <SvgIcon>
                <ClockColorIconSVG />
              </SvgIcon>
            }
            iconPosition="start"
            label={`Waiting`}
          />
          <Tab
            icon={
              <SvgIcon>
                <DistributingColorIconSVG />
              </SvgIcon>
            }
            iconPosition="start"
            label={`Distributing`}
          />
        </Tabs>
      </Grid>
      {selectedTab === 0 && <ApplicationsList apps={signUpFeaturedProjects} />}
      {selectedTab === 1 && <ApplicationsList apps={fundingFeaturedProjects} />}
      {selectedTab === 2 && <ApplicationsList apps={waitingFeaturedProjects} />}
      {selectedTab === 3 && (
        <ApplicationsList apps={distributingFeaturedProjects} />
      )}
    </Card>
  );
};
export default UserApplicationsCard;
