import { Card, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import RoundsStepper from "./RoundsStepper";
import { basicTone200 } from "../../../shared/styles/constants";
import { mapRoundsList } from "./utils";
import { IFeaturedProject } from "../../../models/project.model";
import { useCall } from "@usedapp/core";
import { useContracts } from "../../../hooks";
import TargetAchievement from "./TargetAchievement";

export const RoundSmallCard = (projectData: IFeaturedProject) => {
  return (
    <Grid container gap={{ xs: 4, md: 0 }} marginTop={"5px"}>
      <RoundsStepper
        rounds={mapRoundsList(
          projectData.currentFundingRoundId,
          projectData.roundsList
        )}
      />
    </Grid>
  );
};

export default RoundSmallCard;
