import { Button, Card, Grid, Typography } from "@mui/material";

import { useEffect, useState, useContext } from "react";
import { useUserRoundSignUpMutation } from "../../../services/featuredProjectsApi";
import { AccountContext } from "../../../shared/providers/account-provider";
import IncreaseChancesOnSignUpModal, {
  SocialMediaCounter,
} from "./IncreaseChancesOnSignUpModal";
import { useNavigate } from "react-router-dom";

export type SignUpProps = {
  projectTitle: string;
  isAlreadySignedUp: boolean;
  fundingRoundId?: string;
  socialMedia: {
    twitterUrl: string | undefined;
    discordUrl: string | undefined;
    telegramUrl: string | undefined;
  };
};

export const SignUp = ({
  fundingRoundId,
  isAlreadySignedUp,
  projectTitle,
  socialMedia,
}: SignUpProps) => {
  const navigate = useNavigate();
  const { account } = useContext(AccountContext);
  const [signUp] = useUserRoundSignUpMutation();
  const [isSignedUp, setIsSignedUp] = useState(isAlreadySignedUp);

  const emailIsValidated = !!localStorage.getItem("emailValidated");
  const [increaseChancesModalOpen, setIncreaseChancesModalOpen] =
    useState(false);

  useEffect(() => {
    setIsSignedUp(isAlreadySignedUp);
  }, [isAlreadySignedUp]);

  const handleSignUp = (
    socialMediaCounter: SocialMediaCounter,
    telegramUser: string,
    handleDiscord: string,
    handleTwitter: string
  ) => {
    const token = localStorage.getItem("token");
    if (token && fundingRoundId) {
      signUp({
        fundingRound: { fundingRoundId },
        token,
        socialMediaCounter,
        telegramUser,
        handleDiscord,
        handleTwitter,
      })
        .unwrap()
        .then((data: any) => {
          if (data.result === "success" || isAlreadySignedUp) {
            setIsSignedUp(true);
          }
        });
    }
  };

  const renderCardContent =
    emailIsValidated || !account ? (
      <Typography variant="body1" fontSize={"14px"} fontWeight={500}>
        To get involved in project staking please make sure you have completed
        the following requirements.
      </Typography>
    ) : (
      <Typography variant="body1" fontWeight={500}>
        {`You can't sign up to this project because you haven't validated your e-mail yet. Please go to your account and validate your e-mail.`}
      </Typography>
    );

  return (
    <Grid
      container
      justifyContent={"flex-start"}
      gap={2}
      paddingTop={"36px"}
      paddingBottom={"36px"}
    >
      <Typography
        variant="h5"
        color="secondary"
        fontWeight={800}
        fontSize="16px"
      >
        Interested in supporting {projectTitle} project?
      </Typography>
      {renderCardContent}
      {isSignedUp ? (
        <Button
          fullWidth
          color="primary"
          variant="contained"
          disabled={true}
          sx={{ height: "48px", borderRadius: "8px" }}
        >
          You are already sign up to this round
        </Button>
      ) : (
        <>
          {account ? (
            <>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                onClick={() => setIncreaseChancesModalOpen(true)} //{handleSignUp}
                sx={{ height: "48px", borderRadius: "8px" }}
                disabled={!emailIsValidated}
              >
                Sign Up
              </Button>
              <IncreaseChancesOnSignUpModal
                open={increaseChancesModalOpen}
                handleClose={() => setIncreaseChancesModalOpen(false)}
                socialMedia={socialMedia}
                projectTitle={projectTitle}
                handleSignUp={handleSignUp}
              />
            </>
          ) : (
            <Grid
              item
              xs={12}
              display="flex"
              flexDirection={"column"}
              justifyContent="center"
              gap={1}
            >
              <Typography
                variant="body1"
                color="secondary"
                fontSize={"14px"}
                textAlign="center"
              >
                You need to be authenticated before signing up.
              </Typography>
              <>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    navigate("/register");
                  }}
                  sx={{ height: "48px", borderRadius: "8px" }}
                >
                  Sign In
                </Button>
              </>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default SignUp;
