import { Button, ListItem, Grid, Stack, Typography } from "@mui/material";
import BigNumber from "bignumber.js";

import { Polygon, useCall } from "@usedapp/core";
import React, { useContext, useState, useEffect } from "react";
import { ThemeModeContext } from "../../shared/providers/theme-mode-provider";
import {
  basicTone0,
  basicTone100,
  basicTone50,
  basicTone500,
  basicTone600,
  dropShadowSM,
  mainBorderRadius,
} from "../../shared/styles/constants";
import { useContracts } from "../../hooks";

import { AccountContext } from "../../shared/providers/account-provider";
import { TBidOrder } from "./types";
import { mapBidOrdersListData } from "./ordersModals/ordersModals.utils";
import SellTokensModal from "./sellBuyModals/SellTokensModal";
import { STABLE_COIN_ADDRESS } from "../../config/CoinAbi";
import { MarketplaceContext } from "../../shared/providers/marketplace-provider";

export const BidOrdersList = ({ tokenAddress }: { tokenAddress: string }) => {
  const { isLightMode } = useContext(ThemeModeContext);
  const { marketplaceAddresses } = useContext(MarketplaceContext);
  const [bidOrders, setBidOrders] = useState<TBidOrder[]>([]);
  const [selectedBid, setSelectedBid] = useState<TBidOrder | undefined>(
    undefined
  );
  const { userMetamaskChainId } = useContext(AccountContext);

  const { marketplaceContract } = useContracts({
    marketplaceContractAddress:
      userMetamaskChainId === Polygon.chainId
        ? marketplaceAddresses?.marketplaceMaticAddress
        : marketplaceAddresses?.marketplaceBSCTestnetAddress,
  });

  const {
    value: listBidOrdersPerTokenResult,
    error: listBidOrdersPerTokenError,
  } =
    useCall({
      contract: marketplaceContract,
      method: "listBidOrdersPerToken",
      args: [tokenAddress],
    }) ?? {};

  useEffect(() => {
    if (listBidOrdersPerTokenResult) {
      setBidOrders(mapBidOrdersListData(listBidOrdersPerTokenResult[0]));
    }
  }, [listBidOrdersPerTokenResult]);

  return (
    <Grid item xs={12} md={6}>
      <Stack gap={2}>
        <Typography variant="h3" color="secondary" textAlign={"center"}>
          Bids
        </Typography>
        <Stack gap={0.5}>
          <ListItem sx={{ padding: "8px 12px 0" }}>
            <Grid container alignItems={"center"}>
              <Grid item xs={3} gap={1} container alignItems={"center"}>
                <Typography variant="h6">{`Quantity`}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h6">{`Price per token`}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h6">{`Full price`}</Typography>
              </Grid>
            </Grid>
          </ListItem>
          {bidOrders.map((item) => (
            <ListItem
              key={item.id.toString()}
              sx={{
                border: `1px solid ${
                  isLightMode ? basicTone100 : basicTone500
                }`,
                filter: isLightMode ? "none" : `drop-shadow(${dropShadowSM})`,
                borderRadius: mainBorderRadius,
                padding: "2px 12px",
                background: isLightMode ? basicTone0 : basicTone600,
                "&:hover": {
                  background: isLightMode ? basicTone50 : basicTone500,
                },
              }}
            >
              <Grid container alignItems={"center"}>
                <Grid item xs={3} gap={1} container alignItems={"center"}>
                  <Typography
                    variant="body1"
                    fontWeight={700}
                    color="secondary"
                  >
                    {`${item.quantity.toString()}`}
                  </Typography>
                </Grid>
                <Grid item xs={4} justifyContent={"center"}>
                  <Typography
                    variant="body1"
                    fontWeight={700}
                    color={"secondary"}
                  >
                    {`$${item.pricePerToken}`}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="body1"
                    fontWeight={700}
                    color={"secondary"}
                  >
                    {`$${item.fullPrice}`}
                  </Typography>
                </Grid>
                <Grid item xs={1} justifyContent="flex-end" display={"flex"}>
                  <Button
                    variant="text"
                    color="success"
                    onClick={() => setSelectedBid(item)}
                    sx={{
                      padding: "2px",
                      fontSize: "14px",
                    }}
                  >
                    Sell
                  </Button>
                  {selectedBid ? (
                    <SellTokensModal
                      open={!!selectedBid}
                      onClose={() => setSelectedBid(undefined)}
                      bidId={selectedBid?.id}
                      payToken={STABLE_COIN_ADDRESS}
                      fullPrice={new BigNumber(
                        selectedBid?.fullPrice.toString()
                      )
                        .multipliedBy(1e6)
                        .toString()}
                    />
                  ) : null}
                </Grid>
              </Grid>
            </ListItem>
          ))}
        </Stack>
      </Stack>
    </Grid>
  );
};

export default BidOrdersList;
