import { Contract, utils } from "ethers";
import { STABLE_COIN_ABI } from "../config/CoinAbi";
import { FUNDING_CONTRACT_ABI } from "../config/FundingContractAbi";
import { MARKETPLACE_ABI } from "../config/MarketPlaceAbi";
import { VESTING_TERMS_ABI } from "../config/VestingTermsAbi";
import { Web3Provider } from "@ethersproject/providers";

type ContractsHookProps = {
  stableCoinAddress?: string;
  fundingContractAddress?: string;
  marketplaceContractAddress?: string;
  vestingContractAddress?: string;
  payableTokenAddress?: string;
};
type UseContractsHookOutput = {
  fundingContract: any;
  stableCoinContract: any;
  marketplaceContract: any;
  vestingContract: any;
  payableTokenContract: any;
};

export const useContracts = (
  props: ContractsHookProps
): UseContractsHookOutput => {
  const {
    stableCoinAddress,
    fundingContractAddress,
    marketplaceContractAddress,
    vestingContractAddress,
    payableTokenAddress,
  } = props;

  const stableCoinInterface = new utils.Interface(STABLE_COIN_ABI);
  const stableCoinContract = stableCoinAddress
    ? (new Contract(stableCoinAddress, stableCoinInterface) as any)
    : undefined;

  const fundingContractInterface = new utils.Interface(FUNDING_CONTRACT_ABI);
  const fundingContract = fundingContractAddress
    ? new Contract(fundingContractAddress, fundingContractInterface)
    : undefined;

  const vestingContractInterface = new utils.Interface(VESTING_TERMS_ABI);
  const vestingContract = vestingContractAddress
    ? new Contract(vestingContractAddress, vestingContractInterface)
    : undefined;

  const marketplaceContractInterface = new utils.Interface(MARKETPLACE_ABI);
  const marketplaceContract = marketplaceContractAddress
    ? new Contract(marketplaceContractAddress, marketplaceContractInterface)
    : undefined;

  // using the erc20 interface of stable coin
  const payableTokenInterface = new utils.Interface(STABLE_COIN_ABI);
  const payableTokenContract = payableTokenAddress
    ? (new Contract(payableTokenAddress, payableTokenInterface) as any)
    : undefined;

  return {
    stableCoinContract,
    fundingContract,
    marketplaceContract,
    vestingContract,
    payableTokenContract,
  };
};
