import { Card, Grid, Typography } from "@mui/material";
import { useCall } from "@usedapp/core";
import BigNumber from "bignumber.js";

import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import React, { useState, useContext, useEffect } from "react";
import { useContracts } from "../../../hooks";
import { AccountContext } from "../../../shared/providers/account-provider";
import { ThemeModeContext } from "../../../shared/providers/theme-mode-provider";
import {
  basicTone100,
  basicTone200,
  basicTone450,
  basicTone50,
  basicTone500,
  basicTone600,
  dropShadowSM,
  whiteTone,
} from "../../../shared/styles/constants";

import IconButton from "@mui/material/IconButton";

import { useNavigate } from "react-router-dom";
import { StableCoinType } from "../types";
import { getDividedByChainId } from "../../FeaturedProjectPage/sections/utils";
import { fontPretendard } from "../../../theme";

export const BalanceSection = ({
  usdcInfoByChain,
}: {
  usdcInfoByChain: StableCoinType;
}) => {
  const { isLightMode } = useContext(ThemeModeContext);
  const { account, userMetamaskChainId } = useContext(AccountContext);

  const navigate = useNavigate();

  const [balance, setBallance] = useState<string>("0");

  const { stableCoinContract } = useContracts({
    stableCoinAddress: usdcInfoByChain.address,
  });

  const { value: balanceOf } =
    useCall({
      contract: stableCoinContract,
      method: "balanceOf",
      args: [account],
    }) ?? {};

  useEffect(() => {
    if (balanceOf && userMetamaskChainId) {
      const dividedBy = getDividedByChainId(userMetamaskChainId);

      const val = new BigNumber(balanceOf.toString())
        .dividedBy(dividedBy)
        .toString();
      setBallance(val);
    }
  }, [balanceOf, userMetamaskChainId]);

  return (
    <Grid
      sx={{
        padding: "30px 22px",
        display: "flex",
        alignItems: "flex-start",
        gap: 1,
        backgroundColor: isLightMode ? basicTone100 : basicTone500,
        flexDirection: "column",
        borderBottom: "2px solid #A256C7",
      }}
    >
      <Typography
        fontFamily={fontPretendard}
        fontSize={"14px"}
        lineHeight={"16.71px"}
        color="secondary"
        fontWeight={600}
      >
        Balance
      </Typography>
      <Grid container sx={{ display: "flex" }} gap={1}>
        <Typography
          fontFamily={fontPretendard}
          fontSize={"28px"}
          color="secondary"
          fontWeight={400}
          lineHeight="0.9"
        >
          {balance}
        </Typography>
        <Typography
          fontFamily={fontPretendard}
          fontSize={"28px"}
          fontWeight={400}
          color={basicTone200}
          lineHeight="0.9"
        >
          {usdcInfoByChain?.symbol}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default BalanceSection;
