import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";

import { useCall } from "@usedapp/core";
import { useState, useContext, useEffect } from "react";
import { useContracts } from "../../../hooks";
import { AccountContext } from "../../../shared/providers/account-provider";
import BigNumber from "bignumber.js";

import { NotificationsContext } from "../../../shared/providers/notifications-provider";
import { IChain } from "../../../models/project.model";
import { fontFamily } from "../../../theme";
import { basicTone200 } from "../../../shared/styles/constants";
import { useNavigate } from "react-router-dom";
import { getDividedByChainId } from "./utils";
import { MarketplaceContext } from "../../../shared/providers/marketplace-provider";

type WinnerCardProps = {
  maxAllocation?: string;
  minAllocation?: string;
  stableCoinAddress?: string;
  fundingContractAddress?: string;
  coinSymbol?: string;
  chainId: number;
  isAddedToMarketplace?: boolean;
  vestingContractAddress?: string;
};

const ValueWithLabel = ({
  label,
  value = "-",
  valPrefix = "",
  valSufix = "",
}: {
  label: string;
  value: string | number | undefined | null;
  valPrefix?: string;
  valSufix?: string;
}) => (
  <Grid container item xs={12} sm={12} justifyContent="space-between">
    <Grid item xs={6}>
      <Typography
        fontFamily={fontFamily}
        fontWeight={400}
        fontSize={"14px"}
        lineHeight={"19.1px"}
        color={basicTone200}
      >
        {label}
      </Typography>
    </Grid>
    <Grid item xs={6} style={{ textAlign: "right" }}>
      <Typography
        fontFamily={fontFamily}
        color="secondary"
        fontWeight={700}
        fontSize={"12px"}
        lineHeight={"16.37px"}
      >
        {`${valPrefix}${value}${valSufix}`}
      </Typography>
    </Grid>
  </Grid>
);

export const WinnerCard = ({
  stableCoinAddress,
  maxAllocation = "0",
  fundingContractAddress,
  coinSymbol,
  minAllocation = "0",
  chainId,
  isAddedToMarketplace,
  vestingContractAddress,
}: WinnerCardProps) => {
  const navigate = useNavigate();
  const { stableCoinContract, fundingContract } = useContracts({
    stableCoinAddress,
    fundingContractAddress,
  });

  const [totalAmountPaid, setTotalAmountPaid] = useState<number>(0);

  const [balance, setBallance] = useState<string>("0");

  const { account } = useContext(AccountContext);

  const { value: balanceOf, error: balanceOfError } =
    useCall({
      contract: stableCoinContract,
      method: "balanceOf",
      args: [account],
    }) ?? {};

  const { value: allowance, error: allowanceError } =
    useCall({
      contract: stableCoinContract,
      method: "allowance",
      args: [account, fundingContractAddress],
    }) ?? {};

  const { value: userBalances, error: userBalancesError } =
    useCall({
      contract: fundingContract,
      method: "userBalances",
      args: [account],
    }) ?? {};

  useEffect(() => {
    if (userBalances) {
      setTotalAmountPaid(userBalances.totalAmountPaid.toNumber());
    }
  }, [userBalances]);

  useEffect(() => {
    if (balanceOf) {
      const divideBy = getDividedByChainId(chainId);

      const val = new BigNumber(balanceOf.toString())
        .dividedBy(divideBy)
        .decimalPlaces(5)
        .toString();

      setBallance(val);
    }
  }, [balanceOf]);

  return (
    <Grid
      container
      item
      xs={12}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      gap={1}
    >
      <Grid container gap={2}>
        <Divider />
        <ValueWithLabel label="Token:" value={coinSymbol} />
        <ValueWithLabel label="Bought amount:" value={totalAmountPaid} />
        <ValueWithLabel
          label="Min-max amount:"
          value={`${minAllocation}-${maxAllocation}`}
        />
        <ValueWithLabel label="Balance:" value={`${balance || 0}`} />
        <Divider />
      </Grid>
      <Button
        fullWidth
        color="primary"
        variant="contained"
        onClick={() => {
          if (vestingContractAddress) {
            navigate("/marketplace", {
              state: { selectedRoundVestingAdress: vestingContractAddress },
            });
          } else {
            navigate("/marketplace");
          }
        }}
        sx={{ height: "48px", borderRadius: "8px", marginTop: "5px" }}
        disabled={isAddedToMarketplace === false ? true : false}
      >
        Visit Marketplace
      </Button>
    </Grid>
  );
};

type WaitingCardProps = {
  projectChain: IChain;
  maxAllocation?: string;
  minAllocation?: string;
  stableCoinAddress?: string;
  fundingContractAddress?: string;
  coinSymbol?: string;
  isAddedToMarketplace?: boolean;
  vestingContractAddress?: string;
};

export const WaitingCard = ({
  maxAllocation,
  stableCoinAddress,
  fundingContractAddress,
  coinSymbol,
  minAllocation,
  projectChain,
  isAddedToMarketplace,
  vestingContractAddress,
}: WaitingCardProps) => {
  const { fundingContract } = useContracts({
    fundingContractAddress,
  });
  const navigate = useNavigate();
  const [loadingIsUserWinner, setLoadingIsUserWinner] = useState(true);
  const [isWinner, setIsWinner] = useState<boolean>();
  const { setNotification } = useContext(NotificationsContext);

  const { account } = useContext(AccountContext);

  const { value: isUserWinner, error: isUserWinnerError } =
    useCall({
      contract: fundingContract,
      method: "winnerAddresses",
      args: [account],
    }) ?? {};

  // **** Use this to mock your user for being a winner ***
  useEffect(() => {
    if (isUserWinner !== undefined) {
      setIsWinner(isUserWinner[0]);
      setLoadingIsUserWinner(false);
      // **** Use this to mock your user for being a winner ***
      // setIsWinner(!isUserWinner[0]); // TO DO  remove !
    }
  }, [isUserWinner]);

  useEffect(() => {
    if (isUserWinnerError !== undefined && loadingIsUserWinner) {
      setIsWinner(false);
      setLoadingIsUserWinner(false);
      setNotification({
        message: "An error occured. Please refresh the page!",
        type: "error",
      });
      // **** Use this to mock your user for being a winner ***
      // setIsWinner(!isUserWinner[0]); // TO DO  remove !
    }
  }, [isUserWinnerError]);

  const renderCardContent = (
    <Typography variant="body1" fontSize={"14px"} fontWeight={500}>
      To get involved in project staking please make sure you have completed the
      following requirements.
    </Typography>
  );

  return (
    <Grid
      container
      justifyContent={"flex-start"}
      gap={2}
      paddingTop={"30px"}
      paddingBottom={"20px"}
    >
      <Typography
        variant="h5"
        color="secondary"
        fontWeight={800}
        fontSize="16px"
      >
        Waiting status
      </Typography>

      {isWinner ? (
        <WinnerCard
          maxAllocation={maxAllocation}
          stableCoinAddress={stableCoinAddress}
          fundingContractAddress={fundingContractAddress}
          coinSymbol={coinSymbol}
          minAllocation={minAllocation}
          chainId={projectChain.chainId}
          isAddedToMarketplace={isAddedToMarketplace}
          vestingContractAddress={vestingContractAddress}
        />
      ) : (
        <>
          {renderCardContent}
          <Grid container justifyContent={"center"}>
            {loadingIsUserWinner ? (
              <CircularProgress color="secondary" />
            ) : (
              <Button
                fullWidth
                color="primary"
                variant="contained"
                onClick={() => {
                  if (vestingContractAddress) {
                    navigate("/marketplace", {
                      state: {
                        selectedRoundVestingAdress: vestingContractAddress,
                      },
                    });
                  } else {
                    navigate("/marketplace");
                  }
                }}
                sx={{ height: "48px", borderRadius: "8px", marginTop: "5px" }}
                disabled={isAddedToMarketplace === false ? true : false}
              >
                Visit Marketplace
              </Button>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};
export default WaitingCard;
