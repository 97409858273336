export const DotIconSVG = () => (
  <svg
    width="25"
    height="2"
    viewBox="0 0 25 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.75 1H24.75" stroke="#515154" strokeDasharray="2 2" />
  </svg>
);
