export const WhitepaperIconSVG = ({ color }: any) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.6291 11.6035H12.7741C12.6045 11.6034 12.4392 11.6572 12.3021 11.757C12.1649 11.8568 12.063 11.9976 12.011 12.159C11.8746 12.585 11.6063 12.9566 11.2448 13.2199C10.8833 13.4833 10.4474 13.6249 10.0001 13.6241C9.55287 13.6249 9.11694 13.4834 8.75544 13.22C8.39394 12.9566 8.12563 12.585 7.98933 12.159C7.93722 11.9976 7.83523 11.8568 7.69804 11.757C7.56085 11.6572 7.39554 11.6034 7.22588 11.6035H4.37087C4.26551 11.6035 4.16118 11.6242 4.06383 11.6645C3.96649 11.7049 3.87804 11.7639 3.80354 11.8384C3.72903 11.9129 3.66994 12.0014 3.62963 12.0987C3.58932 12.1961 3.56858 12.3004 3.5686 12.4058V16.9813C3.56873 17.194 3.65331 17.398 3.80375 17.5483C3.95418 17.6987 4.15817 17.7832 4.37087 17.7832H15.6291C15.7344 17.7833 15.8387 17.7625 15.9361 17.7222C16.0334 17.682 16.1218 17.6229 16.1963 17.5484C16.2708 17.474 16.3299 17.3855 16.3702 17.2882C16.4106 17.1909 16.4313 17.0866 16.4314 16.9813V12.4058C16.4314 11.9623 16.0726 11.6035 15.6291 11.6035Z"
      fill={color}
    />
    <path
      d="M9.73197 11.9772C9.79606 12.0669 9.90087 12.1206 10.0111 12.1206H10.0119C10.123 12.1206 10.2262 12.0669 10.2911 11.9759L13.8207 7.01176C13.857 6.96028 13.8785 6.89984 13.8829 6.837C13.8873 6.77416 13.8744 6.71131 13.8456 6.65528C13.8167 6.59918 13.773 6.55214 13.7191 6.51936C13.6652 6.48657 13.6033 6.46932 13.5402 6.46951H11.88V2.01005C11.88 1.81935 11.7261 1.66602 11.5367 1.66602H8.46325C8.27333 1.66602 8.12 1.81935 8.12 2.01005V6.46951H6.45847C6.39549 6.4696 6.33376 6.48702 6.28002 6.51985C6.22629 6.55268 6.18262 6.59966 6.15379 6.65565C6.12497 6.71163 6.1121 6.77447 6.1166 6.83728C6.1211 6.90009 6.14279 6.96045 6.17929 7.01176L9.73197 11.9772Z"
      fill={color}
    />
  </svg>
);
