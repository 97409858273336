export const CompletedRoundIconSVG = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {/* Circle */}
    <circle cx="11" cy="11" r="10.5" stroke="#5DB03E" />
    {/* Check mark */}
    <path
      d="M6 11l3 3 7-7"
      stroke="#5DB03E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </svg>
);

export const CompletedStepIconSVG = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9" cy="9" r="8.5" stroke="#5DB03E" />
    <path
      d="M12 7L8 11L6 9"
      stroke="#5DB03E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
