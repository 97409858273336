import {
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
} from "@mui/material";
import { useContractFunction } from "@usedapp/core";
import React, { useContext, useEffect } from "react";

import {
  basicTone0,
  basicTone500,
  mainColor2,
} from "../../../shared/styles/constants";

import { ThemeModeContext } from "../../../shared/providers/theme-mode-provider";
import { useContracts } from "../../../hooks";
import { NotificationsContext } from "../../../shared/providers/notifications-provider";

import { useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { MarketplaceContext } from "../../../shared/providers/marketplace-provider";

type SellTokensModalProps = {
  open: boolean;
  onClose: () => void;
  bidId: string | undefined;
  payToken: string;
  fullPrice: string | undefined;
};

export const SellTokensModal = ({
  open,
  onClose,
  bidId,
  fullPrice,
  payToken,
}: SellTokensModalProps) => {
  const { isLightMode } = useContext(ThemeModeContext);
  const { setNotification } = useContext(NotificationsContext);
  const { marketplaceAddresses } = useContext(MarketplaceContext);

  const { fundingTermsAddress } = useParams();

  const { marketplaceContract } = useContracts({
    marketplaceContractAddress: marketplaceAddresses?.marketplaceMaticAddress,
  });

  const { state: sellState, send: sell } = useContractFunction(
    marketplaceContract,
    "sell",
    {
      transactionName: "Sell ",
    }
  );

  useEffect(() => {
    if (sellState?.status === "Success") {
      setNotification({
        message: "You successfully sold tokens!",
        type: "success",
      });
      onClose();
    }
  }, [sellState]);

  const handleSellTokens = () => {
    sell(fundingTermsAddress, bidId, payToken, fullPrice);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        backgroundColor: "initial",
        "& .MuiDialog-paper": {
          backgroundColor: isLightMode ? basicTone0 : basicTone500,
          padding: "24px",
          border: `1px solid ${mainColor2}`,
        },
      }}
      aria-labelledby="modal-sell-tokens"
      aria-describedby="modal-sell-tokens"
    >
      <DialogTitle sx={{ fontSize: "28px", fontWeight: 700 }}>
        Sell tokens
      </DialogTitle>

      <DialogContent>
        <Typography variant="h6">BidID: {bidId}</Typography>
        <Typography variant="h6">PayToken: {payToken}</Typography>
        <Typography variant="h6">fullPrice: {fullPrice?.toString()}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text">
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          onClick={() => handleSellTokens()}
          loading={
            sellState.status === "PendingSignature" ||
            sellState.status === "Mining"
          }
        >
          <span>Sell</span>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default SellTokensModal;
