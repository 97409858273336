import {
  IFeaturedProject,
  IShowOffFeaturedProject,
} from "../../../../models/project.model";

export type TAllProjects = {
  fundingFeaturedProjects: IFeaturedProject[];
  signUpFeaturedProjects: IFeaturedProject[];
  pendingFeaturedProjects: IFeaturedProject[];
  waitingFeaturedProjects: IFeaturedProject[];
  distributingFeaturedProjects: IFeaturedProject[];
  fundingReadyFeaturedProjects?: IFeaturedProject[];
  signUpEndedFeaturedProjects: IFeaturedProject[];
  fundingEndedFeaturedProjects?: IFeaturedProject[];
  finishedFeaturedProjects: IFeaturedProject[];
  showOffFeaturedProjects: IShowOffFeaturedProject[];
};

export type TStates = {
  funding: string;
  signUp: string;
  waiting: string;
  distributing: string;
  pending: string;
  signUpEnded: string;
  fundingReady: string;
  fundingEnded: string;
  finished: string;
  showOff?: string;
};
export const states: TStates = {
  funding: "Funding",
  signUp: "Whitelisting open",
  waiting: "Waiting",
  distributing: "Distributing",
  pending: "Upcoming",
  signUpEnded: "Whitelisting closed",
  fundingReady: "Whitelisting closed",
  fundingEnded: "Waiting",
  showOff: "Spotlight",
  finished: "Finished",
};

export const getMinutesDiff = (startDate: string, endDate: string) => {
  const msInMinute = 60 * 1000;

  const start = new Date(startDate).getTime();
  const end = new Date(endDate).getTime();

  return Math.round(Math.abs(end - start) / msInMinute);
};

export const mergeSignUpEndedWithFundingReadyProjects = (
  response: TAllProjects
): TAllProjects => {
  return {
    pendingFeaturedProjects: response.pendingFeaturedProjects,
    signUpFeaturedProjects: response.signUpFeaturedProjects,
    signUpEndedFeaturedProjects: [
      ...response.signUpEndedFeaturedProjects,
      ...(response.fundingReadyFeaturedProjects || []),
    ],
    fundingFeaturedProjects: response.fundingFeaturedProjects,
    distributingFeaturedProjects: response.distributingFeaturedProjects,
    waitingFeaturedProjects: [
      ...response.waitingFeaturedProjects,
      ...(response.fundingEndedFeaturedProjects || []),
    ],
    finishedFeaturedProjects: response.finishedFeaturedProjects,
    showOffFeaturedProjects: response.showOffFeaturedProjects,
  };
};
