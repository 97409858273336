import { Grid } from "@mui/material";
import TokensContainer from "../../TokensContainer";
import { basicTone0, basicTone700 } from "../../../../shared/styles/constants";
import { ThemeModeContext } from "../../../../shared/providers/theme-mode-provider";
import { useContext } from "react";
import { MarketplaceContext } from "../../../../shared/providers/marketplace-provider";

export const LeftSidebar = () => {
  const { isLightMode } = useContext(ThemeModeContext);
  const { onChangeSelectedToken } = useContext(MarketplaceContext);

  return (
    <Grid
      item
      xs={12}
      md={2.5}
      container
      sx={{ backgroundColor: isLightMode ? basicTone0 : basicTone700 }}
    >
      <TokensContainer onSelectToken={onChangeSelectedToken} />
    </Grid>
  );
};

export default LeftSidebar;
