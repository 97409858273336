import { Grid, Typography } from "@mui/material";
import { IFeaturedProject } from "../../../../models/project.model";
import StopRoundedIcon from "@mui/icons-material/StopRounded";
import {
  basicTone0,
  basicTone50,
  basicTone500,
  secondaryColor1,
} from "../../../../shared/styles/constants";
import Divider from "@mui/material/Divider";
import { useContext } from "react";
import { ThemeModeContext } from "../../../../shared/providers/theme-mode-provider";
import { fontPretendard } from "../../../../theme";

export const Highlights = (projectData: IFeaturedProject) => {
  const { isLightMode } = useContext(ThemeModeContext);
  const { overview } = projectData.currentFundingRound;
  return (
    <Grid container gap={2} paddingTop={3}>
      <Grid item container alignItems="center" paddingTop={3} paddingBottom={2}>
        <Typography
          fontFamily={fontPretendard}
          color="secondary"
          fontWeight={700}
          fontSize="32px"
          lineHeight={"38.19px"}
          letterSpacing={-0.02}
        >
          Highlights
        </Typography>
      </Grid>
      <Typography
        fontFamily={fontPretendard}
        fontSize={"16px"}
        lineHeight={"24px"}
        color={isLightMode ? basicTone500 : basicTone50}
        fontWeight={400}
      >
        {overview.highlights}
      </Typography>
    </Grid>
  );
};
export default Highlights;
