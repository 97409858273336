import { Button, Card, Grid, Typography } from "@mui/material";

export const SignUpEnded = () => {
  const renderCardContent = (
    <Typography variant="body1" fontSize={"14px"} fontWeight={500}>
      To get involved in project staking please make sure you have completed the
      following requirements.
    </Typography>
  );
  return (
    <Grid
      container
      justifyContent={"flex-start"}
      gap={2}
      paddingTop="36px"
      paddingBottom={"36px"}
    >
      <Typography
        variant="h5"
        color="secondary"
        fontWeight={800}
        fontSize="16px"
      >
        Whitelisting Closed
      </Typography>

      {renderCardContent}

      <Button
        fullWidth
        color="primary"
        variant="contained"
        disabled={true}
        sx={{ height: "48px", borderRadius: "8px" }}
      >
        Whitelisting Closed
      </Button>
    </Grid>
  );
};

export default SignUpEnded;
