import {
  Dialog,
  Typography,
  Stack,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  OutlinedInput,
  FormControl,
  InputAdornment,
  FormHelperText,
  FormLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useContractFunction } from "@usedapp/core";
import { Formik, Form } from "formik";
import React, { useContext, useEffect } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import {
  basicTone0,
  basicTone100,
  basicTone200,
  basicTone500,
  basicTone800,
  grayWhtTone,
  mainColor2,
} from "../../../shared/styles/constants";
import { createAskOrderValidationSchema } from "./ordersModals.utils";
import { ThemeModeContext } from "../../../shared/providers/theme-mode-provider";
import { useContracts } from "../../../hooks";
import { STABLE_COIN_ADDRESS } from "../../../config/CoinAbi";
import { NotificationsContext } from "../../../shared/providers/notifications-provider";

import { useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { MarketplaceContext } from "../../../shared/providers/marketplace-provider";

type CreateAskModalProps = {
  open: boolean;
  onClose: () => void;
};

type CreateAskValues = {
  quantity: number;
  pricePerToken: number;
  fullPrice: number;
  payToken: any;
};

const createAskInitialValues: CreateAskValues = {
  quantity: 0,
  pricePerToken: 0,
  fullPrice: 0,
  payToken: STABLE_COIN_ADDRESS,
};

const mockPayTokens = [
  {
    address: STABLE_COIN_ADDRESS,
    name: "FUSDC",
  },
];

export const CreateAskOrderModal = ({ open, onClose }: CreateAskModalProps) => {
  const { isLightMode } = useContext(ThemeModeContext);
  const { setNotification } = useContext(NotificationsContext);
  const { marketplaceAddresses } = useContext(MarketplaceContext);

  const { tokenAddress, fundingTermsAddress } = useParams();

  const { marketplaceContract, stableCoinContract } = useContracts({
    marketplaceContractAddress: marketplaceAddresses?.marketplaceMaticAddress,
    stableCoinAddress: STABLE_COIN_ADDRESS,
  });

  const { state: createAskState, send: createAsk } = useContractFunction(
    marketplaceContract,
    "createAsk",
    {
      transactionName: "CreateAsk ",
    }
  );

  useEffect(() => {
    if (createAskState?.status === "Success") {
      setNotification({
        message: "You successfully created an ask order!",
        type: "success",
      });
      onClose();
    }
  }, [createAskState]);

  const handleCreateAskOrder = (values: CreateAskValues) => {
    createAsk(
      fundingTermsAddress,
      (values.quantity * 1e18).toString(),
      (values.fullPrice * 1e6).toString()
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        backgroundColor: "initial",
        "& .MuiDialog-paper": {
          backgroundColor: isLightMode ? basicTone0 : basicTone500,
          padding: "24px",
          border: `1px solid ${mainColor2}`,
        },
      }}
      aria-labelledby="modal-create-ask-order"
      aria-describedby="modal-create-ask-order"
    >
      <DialogTitle sx={{ fontSize: "28px", fontWeight: 700 }}>
        Create ask order
      </DialogTitle>

      <Formik
        initialValues={createAskInitialValues}
        onSubmit={handleCreateAskOrder}
        validationSchema={createAskOrderValidationSchema}
        validateOnBlur
      >
        {({
          values,
          handleChange,
          errors,
          handleSubmit,
          handleBlur,
          setFieldValue,
          touched,
          dirty,
        }) => {
          return (
            <>
              {" "}
              <DialogContent>
                <Form
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    width: "100%",
                  }}
                >
                  {/* QUANTITY */}
                  <Stack gap={1}>
                    <FormLabel
                      sx={{
                        color: basicTone200,
                        fontSize: "14px",
                        paddingLeft: "2px",
                      }}
                    >
                      Quantity:
                    </FormLabel>
                    <FormControl variant="outlined" fullWidth>
                      <OutlinedInput
                        fullWidth
                        type="number"
                        inputProps={{ min: 0 }}
                        color="primary"
                        name="quantity"
                        onChange={(ev) => {
                          const quantity = parseFloat(ev.target.value) || 0;
                          setFieldValue("quantity", quantity);

                          if (values.pricePerToken) {
                            const newFullPrice =
                              quantity * values.pricePerToken;
                            setFieldValue("fullPrice", newFullPrice);
                          }
                        }}
                        onBlur={handleBlur}
                        error={!!errors?.quantity && touched?.quantity}
                        required
                        placeholder="Quantity"
                      />
                      {errors?.quantity && touched?.quantity ? (
                        <FormHelperText error>
                          *{errors.quantity}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  </Stack>
                  <Stack direction="row" gap={2}>
                    {/* PRICE PER TOKEN */}
                    <Stack gap={1}>
                      <FormLabel
                        sx={{
                          color: basicTone200,
                          fontSize: "14px",
                          paddingLeft: "2px",
                        }}
                      >
                        Price per token:
                      </FormLabel>
                      <FormControl variant="outlined" fullWidth>
                        <OutlinedInput
                          fullWidth
                          color="primary"
                          name="pricePerToken"
                          value={values.pricePerToken}
                          onChange={(ev) => {
                            const pricePerToken =
                              parseFloat(ev.target.value) || 0;
                            setFieldValue("pricePerToken", pricePerToken);

                            if (values.quantity) {
                              const newFullPrice =
                                values.quantity * pricePerToken;
                              setFieldValue("fullPrice", newFullPrice);
                            }
                          }}
                          onBlur={handleBlur}
                          type="number"
                          inputProps={{ min: 0 }}
                          error={
                            !!errors?.pricePerToken && touched?.pricePerToken
                          }
                          required
                          placeholder="Price per token"
                          startAdornment={
                            <InputAdornment
                              position="start"
                              sx={{
                                "& .MuiTypography-root": { color: grayWhtTone },
                              }}
                            >
                              $
                            </InputAdornment>
                          }
                        />
                        {errors?.pricePerToken && touched?.pricePerToken ? (
                          <FormHelperText error>
                            *{errors.pricePerToken}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </Stack>
                    {/* FULL PRICE */}
                    <Stack gap={1}>
                      <FormLabel
                        sx={{
                          color: basicTone200,
                          fontSize: "14px",
                          paddingLeft: "2px",
                        }}
                      >
                        Full price:
                      </FormLabel>
                      <FormControl variant="outlined" fullWidth>
                        <OutlinedInput
                          fullWidth
                          color="primary"
                          type="number"
                          inputProps={{ min: 0 }}
                          name="fullPrice"
                          value={values.fullPrice}
                          onChange={(ev) => {
                            const fullPrice = parseFloat(ev.target.value) || 0;
                            setFieldValue("fullPrice", fullPrice);

                            if (values.quantity) {
                              const newPricePerToken =
                                fullPrice / values.quantity;
                              setFieldValue("pricePerToken", newPricePerToken);
                            }
                          }}
                          onBlur={handleBlur}
                          error={!!errors?.fullPrice && touched?.fullPrice}
                          required
                          placeholder="Full price"
                          startAdornment={
                            <InputAdornment
                              position="start"
                              sx={{
                                "& .MuiTypography-root": { color: grayWhtTone },
                              }}
                            >
                              $
                            </InputAdornment>
                          }
                        />
                        {errors?.fullPrice && touched?.fullPrice ? (
                          <FormHelperText error>
                            *{errors.fullPrice}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </Stack>
                  </Stack>
                  {/* COIN DROPDOWN */}
                  <Stack gap={1}>
                    <FormLabel
                      sx={{
                        color: basicTone200,
                        fontSize: "14px",
                        paddingLeft: "2px",
                      }}
                    >
                      Pay token:
                    </FormLabel>
                    <Select
                      fullWidth
                      value={values.payToken}
                      onChange={(ev) => {
                        setFieldValue("payToken", ev.target.value);
                      }}
                      IconComponent={() => (
                        <ArrowDropDownIcon color="secondary" />
                      )}
                      sx={{
                        backgroundColor: isLightMode
                          ? basicTone0
                          : basicTone800,
                        paddingRight: "8px",
                        "& .MuiSelect-select": {
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          padding: "16px 8px 16px 16px",
                        },
                      }}
                    >
                      {mockPayTokens.map((option) => (
                        <MenuItem
                          key={option.address}
                          value={option.address}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            padding: "16px 16px",
                            gap: 1,

                            backgroundColor: isLightMode
                              ? basicTone0
                              : basicTone800,
                            borderBottom: `1px solid ${
                              isLightMode ? basicTone100 : basicTone500
                            }`,
                            "&.Mui-selected": {
                              backgroundColor: isLightMode
                                ? basicTone0
                                : basicTone800,
                            },
                          }}
                        >
                          <Typography variant={"body2"} color="secondary">
                            {option.name}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>
                </Form>
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose} variant="text">
                  Cancel
                </Button>
                <LoadingButton
                  type="submit"
                  color="primary"
                  variant="contained"
                  onClick={() => handleSubmit()}
                  loading={
                    createAskState.status === "PendingSignature" ||
                    createAskState.status === "Mining"
                  }
                >
                  <span>Create order</span>
                </LoadingButton>
              </DialogActions>
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default CreateAskOrderModal;
