import {
  Card,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Theme,
  useMediaQuery,
} from "@mui/material";
import { useContext, useState } from "react";
import { IFeaturedProject } from "../../../models/project.model";

import { ProjectOverviewContent } from "./tabs-content/ProjectOverview";
import { TokenomicsContent } from "./tabs-content/Tokenomics";
import { Highlights } from "./tabs-content/Highlights";
import {
  basicTone0,
  basicTone100,
  basicTone200,
  basicTone500,
  borderBasicTone450,
  mainLinearGradient,
} from "../../../shared/styles/constants";
import { Height } from "@mui/icons-material";
import { GitBookIconSVG } from "../../../assets/icons/IconGitBook";
import { TwitterIconSVG } from "../../../assets/icons/Twitter icontwitterIcon";
import { DiscordIconSVG } from "../../../assets/icons/ic_baseline-discorddiscordIcon";
import { YoutubeIconSVG } from "../../../assets/icons/youtubeIcon";
import TeamContent from "./tabs-content/Team";
import { ThemeModeContext } from "../../../shared/providers/theme-mode-provider";

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export const ProjectInfoTabs = (projectData: IFeaturedProject) => {
  const { isLightMode } = useContext(ThemeModeContext);
  // const isMobile = useMediaQuery((theme: Theme) =>
  //   theme.breakpoints.down("sm")
  // );
  // const isTablet = useMediaQuery((theme: Theme) =>
  //   theme.breakpoints.between("sm", "md")
  // );
  const isSmallDesktop = useMediaQuery((theme: Theme) =>
    theme.breakpoints.between("sm", "md")
  );
  const isBelow1044px = useMediaQuery("(max-width:1044px)");
  const isAbove1044px = useMediaQuery("(min-width:1045px)");
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  const [selectedTab, setSelectedTab] = useState(0);
  const { twitterUrl, discordUrl, youtubeUrl } = projectData;
  const handleChangeTab = (_: any, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Card
      sx={{
        borderRadius: "12px",
        // border: `1px solid ${borderBasicTone450}`,
      }}
    >
      <Grid
        container
        height={"40px"}
        display={"flex"}
        flexDirection={"row"}
        // justifyContent={"space-between"}
      >
        <Grid
          item
          xs={12}
          md={7.5}
          sm={9.5}
          display={"flex"}
          justifyContent="flex-start"
        >
          <Tabs
            onChange={handleChangeTab}
            aria-label="ProjectInfoTabs"
            variant={!isDesktop || isBelow1044px ? "scrollable" : "standard"}
            scrollButtons={!isDesktop || isBelow1044px ? "auto" : false}
            value={selectedTab}
            textColor="secondary"
            sx={{
              width: "100%",
            }}
          >
            <Tab
              sx={{
                width: "157px",
                height: "40px",
                // border: `1px solid ${borderBasicTone450}`,
                padding: "10px",
                borderRadius: "0",
                margin: "0",
                "&.Mui-selected": {
                  background: basicTone500,
                  color: basicTone0,
                  borderBottom: "2px solid #A256C7",
                },
              }}
              label={`Highlights`}
              {...a11yProps(0)}
            />
            <Tab
              sx={{
                width: "157px",
                height: "40px",
                // border: `1px solid ${borderBasicTone450}`,
                padding: "10px",
                borderRadius: "0",
                margin: "0",
                "&.Mui-selected": {
                  background: basicTone500,
                  color: basicTone0,
                  borderBottom: "2px solid #A256C7",
                },
              }}
              label={`Overview`}
              {...a11yProps(1)}
            />
            <Tab
              sx={{
                width: "157px",
                height: "40px",
                // border: `1px solid ${borderBasicTone450}`,
                padding: "10px",
                borderRadius: "0",
                margin: "0",
                "&.Mui-selected": {
                  background: basicTone500,
                  color: basicTone0,
                  borderBottom: "2px solid #A256C7",
                },
              }}
              label={`Tokenomics`}
              {...a11yProps(2)}
            />
            <Tab
              sx={{
                width: "157px",
                height: "40px",
                // border: `1px solid ${borderBasicTone450}`,
                padding: "10px",
                borderRadius: "0",
                margin: "0",
                "&.Mui-selected": {
                  background: basicTone500,
                  color: basicTone0,
                  borderBottom: "2px solid #A256C7",
                },
              }}
              label={`Team`}
              {...a11yProps(3)}
            />
          </Tabs>
        </Grid>
        {isDesktop && (
          <Grid
            item
            md={4.5}
            height={"40px"}
            border={`1px solid ${
              isLightMode ? basicTone100 : borderBasicTone450
            }`}
            padding={"10px 24px 10px 8px"}
            display={"flex"}
            justifyContent={"flex-end"}
          >
            <Grid
              item
              width={"124px"}
              height={"20px"}
              gap={"10px"}
              display={"flex"}
            >
              <IconButton
                component="a"
                href={twitterUrl}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  color: basicTone200,
                }}
              >
                <TwitterIconSVG />
              </IconButton>
              <IconButton
                component="a"
                href={discordUrl}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  color: basicTone200,
                }}
              >
                <DiscordIconSVG />
              </IconButton>
              <IconButton
                component="a"
                href={youtubeUrl}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  color: basicTone200,
                }}
              >
                <YoutubeIconSVG />
              </IconButton>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid
        container
        sx={{ padding: { xs: "5px 20px 50px 20px", md: "5px 50px 70px 50px" } }}
      >
        {selectedTab === 0 && <Highlights {...projectData} />}
        {selectedTab === 1 && <ProjectOverviewContent {...projectData} />}
        {selectedTab === 2 && <TokenomicsContent {...projectData} />}
        {selectedTab === 3 && <TeamContent {...projectData} />}
      </Grid>
      {!isDesktop && (
        <Grid
          item
          md={4.5}
          height={"40px"}
          border={`1px solid ${
            isLightMode ? basicTone100 : borderBasicTone450
          }`}
          padding={"10px 24px 10px 8px"}
          display={"flex"}
          justifyContent={"center"}
        >
          <Grid
            item
            width={"124px"}
            height={"20px"}
            gap={"10px"}
            display={"flex"}
          >
            <IconButton
              component="a"
              href={twitterUrl}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: basicTone200,
              }}
            >
              <TwitterIconSVG />
            </IconButton>
            <IconButton
              component="a"
              href={discordUrl}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: basicTone200,
              }}
            >
              <DiscordIconSVG />
            </IconButton>
            <IconButton
              component="a"
              href={youtubeUrl}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: basicTone200,
              }}
            >
              <YoutubeIconSVG />
            </IconButton>
          </Grid>
        </Grid>
      )}
    </Card>
  );
};

export default ProjectInfoTabs;
